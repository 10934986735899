import React from 'react'
import ReactModal from 'react-modal'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import Head from '../../components/Head'
import Spinner from '../../components/Spinner'
import Session from '../../session'
import { generatePdf } from '../print.controller'
import { formatNumber } from '../../lib/functions'

const initialState = {
    articleId: '',
    // for edit
    editArticleLibelle: '',
    editArticleId: '',
    editClientLibelle: '',
    editClientId: '',
    //===========
    reference: '',
    designation: '',
    pachat: '',
    pvente: '',
    quantite: '',

    quantite: '', unite: '', articleReference: '', articleDesignation: '', articlePu: '', articlePuVent: '', artcileQuantite: '', produitQuantiteReste: '',
    action: '',
    currentId: '',

}

const initialStateClient = {
    nomClient: '',
    telephoneClient: '',
    emailClient: '',
    adresseClient: '',
    societeClient: '',
    modalClient: false,
}

const initialStateModal = { popLoad: false, modal: false, modalDelete: false }


const paragraphe = { marginLeft: 8, background: '#fff', zIndex: 1, position: 'relative', display: 'inline-block', padding: '0px 8px', fontSize: 11 }
const row = { margin: 0, border: '1px solid #ddd', paddingTop: 24, borderRadius: 8, top: -24, position: 'relative' }

export default class Add extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            articleOptions: [],
            clientOptions: [],
            storeOptions: [],
            clientSelected: {},
            clientId: '0',
            date: '',
            total: 0,
            tempTotal: 0,
            ntva: 18,
            avance: 0,
            reste: 0,
            tva: false,
            editTva: false,
            clt: false,
            load: true,
            ...initialState,
            ...initialStateClient,
            ...initialStateModal
        }
        this.handleSubmitSave = this.handleSubmitSave.bind(this)
        this.handleSubmitSaveClient = this.handleSubmitSaveClient.bind(this)
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this)
        this.handleSubmitAddElement = this.handleSubmitAddElement.bind(this)
    }

    calculMontant(data) {
        let sum = 0
        for (let i = 0; i < data.length; i++) { sum = sum + (data[i]['pvente'] * data[i]['quantite']) }
        return sum
    }

    handleChange(e, name) {
        if (name === 'articleId') {
            const values = e.target.value.split('|')
            const id = values[0]
            const pu = values[1]
            const article = this.state.articleOptions.find(a => (a.article_id == id && a.pu == pu))
            if (typeof (article) !== 'undefined') {
                this.setState({
                    articleId: e.target.value,
                    articleReference: article.reference,
                    articleDesignation: article.designation,
                    unite: article.unite,
                    articleQuantite: article.quantite,
                    produitQuantiteReste: article.quantite,
                    articlePu: article.pu,
                    articlePuVent: article.pu_vente,
                    pvente: article.pu_vente,
                })
            }
        } else {
            if (name === 'clientId') {
                const client = this.state.clientOptions.find(a => a.id == e.target.value)
                if (typeof (client) !== 'undefined') { this.setState({ [name]: e.target.value, clientSelected: client, clt: true }) }
            } else { this.setState({ [name]: e.target.value }) }
        }
    }

    handleSetTva(tva) {
        const { total, tempTotal, ntva } = this.state
        if (tva) {
            const t = total + (total * (ntva / 100))
            this.setState({ total: t, tva })
        } else {
            this.setState({ total: tempTotal, tva })
        }
    }

    changeTva() {
        const { tempTotal, ntva, tva } = this.state
        if (tva) {
            const t = tempTotal + (tempTotal * (ntva / 100))
            this.setState({ total: t, tva, editTva: false })
        } else {
            this.setState({ total: tempTotal, tva, editTva: false })
        }
    }

    handleSubmitSaveClient(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { nomClient, telephoneClient, emailClient, adresseClient, societeClient } = this.state
        const data = { nom: nomClient, telephone: telephoneClient, email: emailClient, adresse: adresseClient, societe: societeClient }

        this.props.clientController.save(data)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    data.id = res.id
                    this.setState({ ...initialStateClient, clientOptions: [data, ...this.state.clientOptions], popLoad: false })
                    toast.success(res.message)
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
    }

    handleSubmitAddElement(e) {
        e.preventDefault();
        const { articleId, articleReference, unite, articleDesignation, articlePu, pvente, quantite, data, tva, ntva, storeId } = this.state
        const values = articleId
        const id = values[0]
        const article = {
            id: data.length + 1,
            articleId: id,
            storeId,
            reference: articleReference,
            unite,
            designation: articleDesignation,
            pachat: articlePu,
            pvente,
            quantite
        }
        const datas = [...data, article]
        const t = this.calculMontant(datas)
        const total = tva ? t + (t * (ntva / 100)) : t
        this.setState({ data: datas, total, tempTotal: t, ...initialState })
    }

    save(action) {
        const { clientId, total, avance, date, data, tva, ntva } = this.state
        if (data.length > 0) {
            if (Number(total) >= Number(avance)) {
                const payload = {
                    vente: { client_id: clientId, montant: total, date, tva: tva ? ntva : '0' },
                    details: data
                }

                this.props.controller.save(payload)
                    .then(res => {
                        if (!res.error && typeof (res.pror) === 'undefined') {
                            if (action === 'save') {
                                this.setState({ ...initialState, client_id: '', data: [], total: 0, tempTotal: 0, avance: 0, date: '', clientSelected: {}, clt: false, tva: false, popLoad: false  })
                                toast.success(res.message)
                            } else {
                                if (action === 'print') {
                                    this.handlePrint(payload, res.id, false)
                                    toast.success(res.message)
                                } else {
                                    if (action === 'send') {
                                        this.handlePrint(payload, res.id, true)
                                        toast.success(res.message + ' Patientez votre application de messagerie sera lancée.')
                                    }
                                }
                            }
                        }
                        else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
                    })
                    .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
            } else { toast.error("Le montant avancé n'est pas être superieur a celui de la vente."); this.setState({ popLoad: false }) }
        } else { toast.error("La liste d'articles à ne doit pas être vide."); this.setState({ popLoad: false }) }
    }

    handleSubmitSave(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        this.save(this.state.action)
    }

    handleSubmitUpdate(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { currentId, article_id, quantite, client, date } = this.state
        const data = { id: currentId.toString(), article_id, quantite: parseInt(quantite), client, date, type: 'in' }
        if (currentId) {
            this.props.controller.save(data)
                .then(res => {
                    if (!res.error && typeof (res.pror) === 'undefined') {
                        this.setState({ ...initialState, modal: false })
                        toast.success(res.message)
                        this.componentDidMount()
                    }
                    else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
                })
                .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
        }
    }

    handleStoreChange(e) {
        const storeId = e.target.value
        this.setState({ storeId, articleOptions: [], displayArticleOptions: false })
        this.fetchArticlesByStore(storeId)
    }
    fetchArticlesByStore(storeId) {
        this.props.magasinController.fetchByStoreId(storeId)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ articleOptions: res })
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
                this.setState({ displayArticleOptions: true })
            })
            .catch(() => toast.error("Une erreur inattendue s'est produite."))
    }

    fetchClientList() {
        this.props.clientController.fetch()
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ clientOptions: res })
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
            })
            .catch(() => toast.error("Une erreur inattendue s'est produite."))
    }

    fetchStoreList() {
        this.props.magasinController.fetchAll()
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ storeOptions: res })
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
            })
            .catch(() => toast.error("Une erreur inattendue s'est produite."))
    }

    gen(data, send) {
        generatePdf(data, 'sorties')
            .then(html => {
                var temp = document.createElement('div');
                temp.innerHTML = html;
                if (!send) {
                    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
                    pri.document.open();
                    pri.document.write(temp.innerHTML);
                    pri.print();
                    this.setState({ ...initialState, client_id: '', data: [], total: 0, tempTotal: 0, date: '', clientSelected: {}, clt: false, tva: false })
                    pri.document.close();
                } else {
                    window.location.href = `mailto:${this.state.clientSelected.email}?subject=Facture`
                    this.setState({ ...initialState, client_id: '', data: [], total: 0, tempTotal: 0, date: '', clientSelected: {}, clt: false, tva: false })
                }
            }).catch(err => console.log(err))
    }

    handlePrint(data, id, send) {
        this.setState({ popLoad: true })
        const store = Session.get('$sf_structure')
        this.gen({ commande: { ...data.vente, id }, details: data.details, store }, send)
    }

    componentDidMount() {
        this.fetchClientList()
        this.fetchStoreList()
    }

    render() {
        const { pageName, devise } = this.props
        const {
            data, clientOptions, modalClient, clientSelected, clt,
            editTva, tva, ntva, total, avance, storeId,
            displayArticleOptions, storeOptions, articleReference, articleQuantite, articlePu, articlePuVent, produitQuantiteReste,
            articleOptions, modal, articleId,
            reference, unite, pachat, pvente, quantite, reste,
            nomClient, telephoneClient, emailClient, adresseClient, societeClient,
            editArticleId, editArticleLibelle, load, popLoad } = this.state
        return (
            <>
                <Head name={pageName} />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <ToastContainer />
                            <div className="col-12">
                                <div className="card nx-detail-card">
                                    <div className="nx-card-header">
                                        <button className="btn btn-link" onClick={() => this.props.history.goBack()}>
                                            <i className="fa fa-arrow-left"></i>
                                        </button>
                                        <h3 className="nx-card-title">Nouveau dévis/Facture proforma</h3>
                                    </div>
                                    <div className="card-body">
                                        <form method="POST" onSubmit={this.handleSubmitSave}>

                                            <div className="row">
                                                <div className="col-sm-12 col-md-12 col-lg-6">
                                                    <p style={paragraphe}>Information sur le client et la date de le dévis/Facture proforma</p>
                                                    <div className="row" style={{ ...row, marginRight: 8 }}>
                                                        <div className="col-sm-12 col-lg-6" style={{ display: 'flex' }}>
                                                            <div style={{ flex: 1 }}>
                                                                <label className='nx-input-label'>Séléctionner le client</label>
                                                                <select className="form-control form-control-sm" onChange={(e) => this.handleChange(e, 'clientId')} >
                                                                    <option value="0">--Client--</option>
                                                                    {clientOptions.length > 0 &&
                                                                        clientOptions.map((a, i) => <option value={a.id} key={i}>{`${a.nom} ; Tel: ${a.telephone}`}</option>)
                                                                    }
                                                                </select>
                                                            </div>
                                                            <div>
                                                                <label>    &nbsp; </label>
                                                                <button onClick={() => this.setState({ modalClient: true })} style={{ display: 'block', marginLeft: 4, height: 31, padding: '0 6px' }} type="button" className="btn btn-primary">
                                                                    <i className="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-lg-6">
                                                            <div className="form-group">
                                                                <label className='nx-input-label'>Date de la vente *</label>
                                                                <input
                                                                    onChange={(e) => this.handleChange(e, 'date')}
                                                                    type="date"
                                                                    className="form-control form-control-sm"
                                                                    placeholder="Date"
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        {clt && <>
                                                            <div className="col-sm-3">
                                                                <h6 style={{ fontSize: 12, marginBottom: 0 }}>Société</h6>
                                                                <p style={{ fontSize: 10 }}>{clientSelected.societe}</p>
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <h6 style={{ fontSize: 12, marginBottom: 0 }}>Téléphone</h6>
                                                                <p style={{ fontSize: 10 }}>{clientSelected.telephone}</p>
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <h6 style={{ fontSize: 12, marginBottom: 0 }}>Email</h6>
                                                                <p style={{ fontSize: 10 }}>{clientSelected.email}</p>
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <h6 style={{ fontSize: 12, marginBottom: 0 }}>Adresse</h6>
                                                                <p style={{ fontSize: 10 }}>{clientSelected.adresse}</p>
                                                            </div>
                                                        </>}
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-12 col-lg-6">
                                                    <p style={paragraphe}>Information sur le montant de le dévis/Facture proforma </p>
                                                    <div className="row" style={row}>
                                                        <div className="col-12">
                                                            <label className='nx-input-label'>Montant de le dévis/Facture proforma : <strong>{`${formatNumber(total)} ${devise}`}</strong></label>
                                                        </div>
                                                        <div className="col-12" style={{ marginBottom: 16 }}>
                                                            {!editTva &&
                                                                <>
                                                                    <label className="form-check-label" style={{ marginRight: 8 }} >{`Appliquer la TVA (${ntva}%) `}</label>
                                                                    <button onClick={() => this.setState({ editTva: true })} style={{ marginRight: 38, padding: '0 4px', fontSize: 10 }} type="button" className="btn btn-primary">
                                                                        <i className="fa fa-pen"></i>
                                                                    </button>
                                                                    <input className="form-check-input" type="checkbox" onChange={(e) => this.handleSetTva(e.target.checked)} checked={tva} />
                                                                </>}
                                                            {editTva &&
                                                                <div className="form-group" style={{ display: 'flex' }}>
                                                                    <input onChange={(e) => this.handleChange(e, 'ntva')} min="0" value={ntva} type="number" className="form-control form-control-sm" placeholder="TVA" />
                                                                    <button onClick={() => this.changeTva()} style={{ height: 31, marginLeft: 4 }} type="button" className="btn btn-primary">Valider</button>
                                                                </div>}
                                                        </div>
                                                        {/* <div className="col-sm-12" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div className="form-group">
                                                                <label className='nx-input-label'>Saisir montant avancé (Avance)</label>
                                                                <input
                                                                    onChange={(e) => this.handleChange(e, 'avance')}
                                                                    value={avance}
                                                                    type="number"
                                                                    className="form-control form-control-sm"
                                                                    placeholder="Avance"
                                                                    required
                                                                />
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>

                                            </div>

                                            <p style={paragraphe}>Information sur le dévis/Facture proforma et les produits</p>
                                            <div className="row" style={row}>
                                                <div className="col-12 table-responsive">
                                                    <table id="example1" className="table table-bordered table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Référence</th>
                                                                <th>Désignation</th>
                                                                <th>PU</th>
                                                                <th>Quantité</th>
                                                                <th>Montant</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.length > 0 &&
                                                                data.map((d, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td>{i + 1}</td>
                                                                            <td>{d.reference}</td>
                                                                            <td>{d.designation}</td>
                                                                            <td>{`${formatNumber(d.pvente)} ${devise}`}</td>
                                                                            <td>{`${formatNumber(d.quantite)} ${d.unite}`}</td>
                                                                            <td>{`${formatNumber(d.pvente * d.quantite)} ${devise}`}</td>
                                                                            <td>{`${formatNumber(d.pvente * d.quantite)} ${devise}`}</td>
                                                                            <td>
                                                                                <button className="btn btn-link" onClick={() => {
                                                                                    const datas = data.filter(f => f.id !== d.id)
                                                                                    const t = this.calculMontant(datas)
                                                                                    const total = tva ? t + (t * (ntva / 100)) : t
                                                                                    this.setState({ data: datas, total, tempTotal: t, ...initialState })
                                                                                }}>
                                                                                    <i className="fa fa-trash"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <button type="button" style={{ marginBottom: 12 }} className="btn btn-default" onClick={() => this.setState({ modal: true, action: 'save' })} >
                                                        Ajouter
                                                    </button>
                                                </div>
                                            </div>

                                            <div className='nx-page-actions'>
                                                <Link to="sorties" type="button" className="btn btn-secondary">Annuler</Link>
                                                <button type="submit" onClick={() => this.setState({ action: "save" })} style={{ marginLeft: 8 }} className="btn btn-primary">Enregistrer</button>
                                                <button type="submit" onClick={() => this.setState({ action: "print" })} style={{ marginLeft: 8 }} className="btn btn-primary">Enregistrer & Imprimer</button>
                                                {/* <button type="submit" onClick={()=>this.setState({ action: "send" }) } style={{ marginLeft: 8 }} className="btn btn-primary">Enregistrer & Envoyer</button> */}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">

                                <ReactModal isOpen={modal} ariaHideApp={false}>
                                    <h5>Nouveau arcticle.</h5>

                                    <form method="POST" onSubmit={this.handleSubmitAddElement}>

                                        <div className="row" style={{ marginBottom: 12 }} >
                                            <div className="col-12">
                                                <label className='nx-input-label'>Magasin des produits finis *</label>
                                                <select className="form-control form-control-sm" onChange={(e) => this.handleStoreChange(e)} value={storeId} required>
                                                    <option selected>--Magasin--</option>
                                                    {storeOptions.map((a, i) => <option value={a.id} key={i}>{a.nom}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                        {displayArticleOptions && <>
                                            <div className="row" style={{ marginBottom: 12 }}>
                                                <div className="col-12">
                                                    <label className='nx-input-label'>Séléctionner un article *</label>
                                                    <select className="form-control form-control-sm" onChange={(e) => this.handleChange(e, 'articleId')} value={articleId} required>
                                                        <option selected value="">--Choisir--</option>
                                                        {articleOptions.length > 0 &&
                                                            articleOptions.map((a, i) => <option value={`${a.article_id}|${a.pu}`} key={i}>{`${a.designation} - Ref: ${a.reference} - PU: ${a.pu}`}</option>)
                                                        }
                                                    </select>
                                                    {articleReference !== '' && <p style={{ fontSize: 11, marginBottom: 0, marginTop: 4 }}><strong>Référence: </strong> {articleReference}</p>}
                                                    {articlePu !== '' && <p style={{ fontSize: 11, marginBottom: 0, marginTop: 4 }}><strong>Prix unitaire d'achat: </strong> {articlePu} {devise}</p>}
                                                    {articlePuVent !== '' && <p style={{ fontSize: 11, marginBottom: 0, marginTop: 4 }}><strong>Prix unitaire previsionnel de vente: </strong> {articlePuVent} {devise}</p>}
                                                    {produitQuantiteReste !== '' && <p style={{ fontSize: 11, marginBottom: 0, marginTop: 4 }}><strong>Quantité disponible en stock:</strong> {produitQuantiteReste} {unite}</p>}
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginBottom: 12 }}>
                                                <div className="col-sm-12 col-lg-6">
                                                    <label className='nx-input-label'>Quantité *</label>
                                                    <input type="number"
                                                        className="form-control form-control-sm"
                                                        min="0.0001"
                                                        // max={articleQuantite}
                                                        step="0.0001"
                                                        value={quantite}
                                                        onChange={(e) => this.handleChange(e, 'quantite')}
                                                        placeholder="Quantité"
                                                        required
                                                    />
                                                </div>
                                                <div className="col-sm-12 col-lg-6">
                                                    <div className="form-group">
                                                        <label className='nx-input-label'>Prix unitaire de vente</label>
                                                        <input type="number"
                                                            value={pvente ? pvente : articlePuVent}
                                                            onChange={(e) => this.handleChange(e, 'pvente')}
                                                            className="form-control form-control-sm"
                                                            placeholder="Prix unitaire d'achat"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                        <fieldset className="form-group" style={{ textAlign: 'end' }}>
                                            <button onClick={() => this.setState({ ...initialState, ...initialStateModal })} type="button" className="btn btn-secondary">Fermer</button>
                                            <button type="submit" name="valider" style={{ marginLeft: 8 }} className="btn btn-primary">Ajouter</button>
                                        </fieldset>
                                    </form>
                                </ReactModal>

                                <ReactModal isOpen={modalClient} ariaHideApp={false}>
                                    <h5>Ajout d'un nouveau client</h5>
                                    <form method="POST" onSubmit={this.handleSubmitSaveClient}>
                                        <div className="row">
                                            <div className="col-sm-12 col-lg-6">
                                                <div className="form-group">
                                                    <label className='nx-input-label'>Nom complet *</label>
                                                    <input value={nomClient} onChange={(e) => this.handleChange(e, 'nomClient')} type="text" className="form-control form-control-sm" placeholder="Nom complet *" required />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-lg-6">
                                                <div className="form-group">
                                                    <label className='nx-input-label'>Société</label>
                                                    <input value={societeClient} onChange={(e) => this.handleChange(e, 'societeClient')} type="text" className="form-control form-control-sm" placeholder="Société" />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-lg-6">
                                                <div className="form-group">
                                                    <label className='nx-input-label'>Email</label>
                                                    <input value={emailClient} onChange={(e) => this.handleChange(e, 'emailClient')} type="email" className="form-control form-control-sm" placeholder="Email" />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-lg-6">
                                                <div className="form-group">
                                                    <label className='nx-input-label'>Téléphone</label>
                                                    <input value={telephoneClient} onChange={(e) => this.handleChange(e, 'telephoneClient')} type="text" className="form-control form-control-sm" placeholder="Téléphone" />
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label className='nx-input-label'>Adresse</label>
                                                    <input value={adresseClient} onChange={(e) => this.handleChange(e, 'adresseClient')} type="text" className="form-control form-control-sm" placeholder="Adresse" />
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ textAlign: 'end' }}>
                                            <button onClick={() => this.setState({ ...initialStateClient })} type="button" className="btn btn-secondary">Annuler</button>
                                            <button type="submit" style={{ marginLeft: 8 }} className="btn btn-primary">Enregistrer</button>
                                        </div>
                                    </form>
                                </ReactModal>

                                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                                    <h5>Traitement ...</h5>
                                    <Spinner />
                                    <span className="breadcrumb-item active" style={{ display: 'block', textAlign: 'center' }}>
                                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                                    </span>
                                </ReactModal>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}