export const file = 
    `<html>
        <head>
            <title>::.Gestock</title>
            <style type="text/css">
            @page 
                {
                    size: auto;   /* auto is the initial value */
                    margin: 0mm;  /* this affects the margin in the printer settings */
                }
                .content {
                    width: 100%;
                    /*  height: 3480px;*/
                    margin: auto;
                }

                th {
                    font-style: normal;
                    font-size: 14px;
                    text-decoration: none;
                    text-transform: uppercase;
                    border: 1px #ddd solid;
                    text-align: center;

                }

                table {

                    width: 100%;
                    border: 2px #ddd solid;
                    border-collapse: collapse;
                }

                th {
                    text-transform: Capitalize
                }

                td {

                    border: 1px #ddd solid;
                    text-align: center;
                    font-size: 12px;
                }

                #lgo,
                #ifo {
                    display: inline-block;
                }
            </style>

        </head>

        <body style="margin: 36px">

            <div
                style="border: 4px solid #000; display: flex; flex-direction: row; justify-content: center; margin-left: 24px; margin-right: 24px; border-radius: 24px;">
                <p id="lgo" style="text-align: left;"><img src="/masdem/{{store.logoPath}}" width="100" alt="logo" /></br> </p>

                <p style="text-align: center" id="ifo">
                    <span
                        style="font-size: 25pt;font-family: 'Times New Roman', Times, serif; color: #1C1570; text-transform: uppercase; font-weight: bold;">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {{store.nom}}<br>
                    </span>
                    <span style="font-weight: bold; margin-top: 8px">
                        Tél: {{store.telephone}}</br>
                        E-mail: {{store.email}}</br>
                        {{store.adresse}}
                    </span>
                </p>
            </div>
            <p style="text-align: right; margin-right: 24px; font-weight: 400; font-size: 12px">Imprimé le : {{imprimerDate}}</p>

            <p style="font-size: 20px; font-weight: bold; margin-left: 24px;">La liste des articles reperotiés </p>
            <div class="card-body" style="margin-right: 24px; margin-left: 24px;">
                <div class="table-responsive">
                    <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Référence</th>
                                <th>Désignation</th>
                                <th>Unité</th>
                                <th>Prix d'achat</th>
                                <th>Prix de vente</th>
                            </tr>
                        </thead>
                        <tbody>
                            {{#each articles}}
                            <tr>
                                <td>{{inc @index}}</td>
                                <td>{{reference}}</td>
                                <td>{{libelle}}</td>
                                <td>{{unite}}</td>
                                <td>{{numberFormater pachat}} F CFA</td>
                                <td>{{numberFormater pvente}} F CFA</td>
                            </tr>
                            {{/each}}

                        </tbody>
                    </table>
                </div>
            </div>
        </body>
    </html>`