import React from 'react'
const NotFound = ()=>{
    return(
        <div className="content">
            <div className="container-fluid">
                <div className="row">
                    {/* <ToastContainer /> */}
                    <div className="col-12">
                        <div className="card" style={{marginTop: 64}}>
                            <div className="nx-card-header">
                                <h4 className="nx-card-title">Page non trouvée</h4>
                            </div>
                            <div className="card-content collapse show">
                                <div className="card-body">
                                    <div className="card-text" style={{display: 'flex', justifyContent: 'space-around' }}>
                                        <div>
                                            <h5>Page que vous essayez d'acceder est introuvable !</h5>
                                            <p>Veuillez contacter votre administrateur pour plus d'informations.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default NotFound