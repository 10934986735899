import React from 'react'
import ReactModal from 'react-modal'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import Head from '../../components/Head'
import Spinner from '../../components/Spinner'
import Session from '../../session'
import { generatePdf } from '../print.controller'

const initialState = {
    article_id: '',
    // for edit
    editArticleLibelle: '',
    editArticleId: '',
    editClientLibelle: '',
    editClientId: '',
    //===========
    reference: '',
    designation: '',
    pachat: '',
    pvente: '',
    quantite: '',
    action: '',
    currentId: '',
    popLoad: false,
    modal: false,
    modalDelete: false,
}

const initialStateClient = {
    nomClient: '',
    telephoneClient: '',
    emailClient: '',
    adresseClient: '',
    societeClient: '',
    modalClient: false,
}

const paragraphe = {marginLeft: 8, background: '#fff', zIndex: 1, position: 'relative', display: 'inline-block', padding: '0px 8px', fontSize: 11}
const row = {margin: 0, border: '1px solid #ddd',paddingTop: 24, borderRadius: 8, top: -24, position: 'relative'}

export default class Add extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            articleOptions: [],
            clientOptions: [],
            clientSelected: {},
            client_id: '',
            date: '',
            total: 0,
            tempTotal: 0,
            ntva: 18,
            tva: false,
            editTva: false,
            clt: false,
            load: true,
            ...initialState,
            ...initialStateClient
        }
        this.handleSubmitSave = this.handleSubmitSave.bind(this)
        this.handleSubmitSaveClient = this.handleSubmitSaveClient.bind(this)
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this)
        this.handleSubmitAddElement = this.handleSubmitAddElement.bind(this)
    }

    calculMontant(data){
        let sum = 0
        for(let i=0; i<data.length; i++){ sum = sum + (data[i]['pvente'] * data[i]['quantite']) }
        return sum
    }

    handleChange(e, name) {
        if (name === 'article_id') {
            const article = this.state.articleOptions.find(a => a.id == e.target.value)
            if (typeof (article) !== 'undefined') {
                this.setState({ [name]: e.target.value, reference: article.reference, designation: article.designation, pachat: article.pachat, pvente: article.pvente })
            }
        } else {
            if(name === 'client_id'){
                const client = this.state.clientOptions.find(a => a.id == e.target.value)
                if (typeof (client) !== 'undefined') { this.setState({ [name]: e.target.value, clientSelected: client, clt: true }) }
            }else{ this.setState({ [name]: e.target.value }) }
        }
    }

    handleSetTva(tva){
        const { total, tempTotal, ntva } = this.state
        if(tva){
            const t = total + (total*(ntva/100))
            this.setState({ total: t, tva })
        }else{
            this.setState({ total: tempTotal, tva })
        }
    }

    changeTva(){
        const { tempTotal, ntva, tva } = this.state
        if(tva){
            const t = tempTotal + (tempTotal*(ntva/100))
            this.setState({ total: t, tva, editTva: false })
        }else{
            this.setState({ total: tempTotal, tva, editTva: false  })
        }
    }

    handleEditOpen(id) {
        this.setState({ popLoad: true })
        this.props.controller.findById(id)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ ...res[0], editArticleId: res[0].article_id, modal: true, action: 'update', currentId: id, popLoad: false })
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
    }

    handleDeleteOpen(id) {
        if (id) { this.setState({ modalDelete: true, currentId: id }) }
        else { toast.error("Une erreur inattendue s'est produite.") }
    }

    handleSubmitSaveClient(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { nomClient, telephoneClient, emailClient, adresseClient, societeClient } = this.state
        const data = { nom: nomClient, telephone: telephoneClient, email: emailClient, adresse: adresseClient, societe: societeClient }

        this.props.clientController.save(data)
        .then(res => {
            if (!res.error && typeof (res.pror) === 'undefined') {
                data.id = res.id
                this.setState({ ...initialStateClient, clientOptions: [data, ...this.state.clientOptions], popLoad: false })
                toast.success(res.message)
            }
            else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
        })
        .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
    }

    handleSubmitAddElement(e) {
        e.preventDefault();
        const { article_id, reference, designation, pachat, pvente, quantite, data, tva, ntva } = this.state
        const article = { id: data.length +1, article_id, reference, designation, pachat, pvente, quantite }
        const datas = [...data, article]
        const t = this.calculMontant(datas)
        const total = tva ? t+(t*(ntva/100)) : t
        this.setState({ data: datas, total, tempTotal: t, ...initialState })
    }

    save(action){
        const { client_id, total, date, data, tva, ntva } = this.state
        if(data.length > 0){
            const datas = { vente: {client_id, montant: total, paye: total, date, devis: 1, tva: tva ? ntva : '0'}, details: data }

            this.props.controller.save(datas)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    if(action === 'save'){
                        this.setState({ ...initialState, client_id: '', data: [], total: 0, tempTotal: 0, date: '', clientSelected: {}, clt: false, tva: false })
                        toast.success(res.message)
                    }else{
                        if(action === 'print'){
                            this.handlePrint(datas, res.id, false)
                            toast.success(res.message)
                        }else{
                            if(action === 'send'){
                                this.handlePrint(datas, res.id, true)
                                toast.success(res.message+' Patientez votre application de messagerie sera lancée.')
                            }
                        }
                    }
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
        }else{ toast.error("La liste de produits à ne doit pas être vide."); this.setState({ popLoad: false }) }
    }

    handleSubmitSave(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        this.save(this.state.action)
        // window.location.href = `mailto:dembelemassire34@gmail.com?subject=Devis&body=test`
    }

    handleSubmitUpdate(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { currentId, article_id, quantite, client, date } = this.state
        const data = { id: currentId.toString(), article_id, quantite: parseInt(quantite), client, date, type: 'in' }
        if (currentId) {
            this.props.controller.save(data)
                .then(res => {
                    if (!res.error && typeof (res.pror) === 'undefined') {
                        this.setState({ ...initialState, modal: false })
                        toast.success(res.message)
                        this.componentDidMount()
                    }
                    else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
                })
                .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
        }
    }

    handleDelete() {
        this.setState({ popLoad: true })
        const { currentId } = this.state
        if (currentId) {
            this.props.controller.remove(currentId)
                .then(res => {
                    if (!res.error && typeof (res.pror) === 'undefined') {
                        this.setState({ ...initialState })
                        toast.success(res.message)
                        this.componentDidMount()
                    }
                    else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState }) }
                })
                .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState }) })
        }
    }

    getArticleList() {
        this.props.articleController.fetch()
        .then(res => {
            if (!res.error && typeof (res.pror) === 'undefined') {
                this.setState({ articleOptions: res })
            }
            else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
        })
        .catch(() => toast.error("Une erreur inattendue s'est produite."))
    }

    getClientList() {
        this.props.clientController.fetch()
        .then(res => {
            if (!res.error && typeof (res.pror) === 'undefined') {
                this.setState({ clientOptions: res })
            }
            else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
        })
        .catch(() => toast.error("Une erreur inattendue s'est produite."))
    }

    gen(data, send){
        generatePdf(data, 'devis')
        .then(html=>{
            var temp = document.createElement('div');
            temp.innerHTML = html;
            if(!send){
                var pri = document.getElementById("ifmcontentstoprint").contentWindow;
                pri.document.open();
                pri.document.write(temp.innerHTML);
                pri.print();
                this.setState({ ...initialState, client_id: '', data: [], total: 0, tempTotal: 0, date: '', clientSelected: {}, clt: false, tva: false })
                pri.document.close();
            }else{
                window.location.href = `mailto:${this.state.clientSelected.email}?subject=Devis`
                this.setState({ ...initialState, client_id: '', data: [], total: 0, tempTotal: 0, date: '', clientSelected: {}, clt: false, tva: false })
            }
        }).catch(err=>console.log(err))
    }

    handlePrint(data, id, send){
        this.setState({popLoad: true})
        const store = Session.get('$sf_structure')
        this.gen({commande: {...data.vente, id}, details: data.details, store}, send)
    }

    componentDidMount() {
        this.getArticleList()
        this.getClientList()
    }

    render() {
        const { pageName } = this.props
        const { data, articleOptions, clientOptions, clientSelected, clt, modal, action, 
            reference, pachat, pvente, quantite, total, tva, editTva, ntva,
            nomClient, telephoneClient, emailClient, adresseClient, societeClient, modalClient,
            editArticleId, editArticleLibelle, load, popLoad } = this.state
        return (
            <>
                <Head name={pageName} />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <ToastContainer />
                            <div className="col-12">
                                <div className="card nx-detail-card">
                                    <div className="nx-card-header">
                                        <button className="btn btn-link" style={{float: 'inline-start'}} onClick={()=>this.props.history.goBack()}>
                                            <i className="fa fa-arrow-left"></i>
                                        </button>
                                        <h3 className="nx-card-title">Nouveau dévis/Facture proforma </h3>
                                    </div>
                                    <div className="card-body">
                                        <form method="POST" onSubmit={this.handleSubmitSave}>
                                            <div className="row">
                                                <div className="col-md-6 col-sm-12">
                                                    <p style={paragraphe}>Information sur le client et la date de le dévis/Facture proforma</p>
                                                    <div className="row" style={{...row, marginRight: 8}}>
                                                        <div className="col-md-6 col-sm-12" style={{display: 'flex'}}>
                                                            <div style={{flex: 1}}>
                                                                <label className='nx-input-label'>Séléctionner le client *</label>
                                                                <select className="form-control form-control-sm" onChange={(e) => this.handleChange(e, 'client_id')} required >
                                                                    <option value="">--Client--</option>
                                                                    {clientOptions.length > 0 &&
                                                                        clientOptions.map((a, i) => <option value={a.id} key={i}>{`${a.nom} ; Tel: ${a.telephone}`}</option>)
                                                                    }
                                                                </select>
                                                            </div>
                                                            <div>
                                                                <label>    &nbsp; </label>
                                                                <button onClick={()=>this.setState({ modalClient: true })} style={{display: 'block', marginLeft: 4, height: 31, padding: '0 6px'}} type="button" className="btn btn-primary">
                                                                    <i className="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-sm-12">
                                                            <div className="form-group">
                                                                <label className='nx-input-label'>Date d'enregistrement *</label>
                                                                <input onChange={(e) => this.handleChange(e, 'date')} type="date" className="form-control form-control-sm" placeholder="Date" required />
                                                            </div>
                                                        </div>
                                                        {clt && <>
                                                            <div className="col-sm-3">
                                                                <h6 style={{fontSize: 12, marginBottom: 0}}>Société</h6>
                                                                <p style={{fontSize: 10}}>{clientSelected.societe}</p>
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <h6 style={{fontSize: 12, marginBottom: 0}}>Téléphone</h6>
                                                                <p style={{fontSize: 10}}>{clientSelected.telephone}</p>
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <h6 style={{fontSize: 12, marginBottom: 0}}>Email</h6>
                                                                <p style={{fontSize: 10}}>{clientSelected.email}</p>
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <h6 style={{fontSize: 12, marginBottom: 0}}>Adresse</h6>
                                                                <p style={{fontSize: 10}}>{clientSelected.adresse}</p>
                                                            </div>
                                                        </>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <p style={paragraphe}>Information sur le montant de le dévis/Facture proforma </p>
                                                    <div className="row" style={row}>
                                                        <div className="col-12">
                                                            <p>Montant de le dévis/Facture proforma : <strong>{`${total} Fcfa`}</strong></p>
                                                        </div>
                                                        <div className="col-12" style={{marginBottom: 16}}>
                                                            {!editTva &&
                                                            <>
                                                                <label className="form-check-label" style={{marginRight: 8}} >{`Appliquer la TVA (${ntva}%) `}</label>
                                                                <button onClick={()=>this.setState({editTva: true})} style={{marginRight: 38, padding: '0 4px', fontSize: 10}} type="button" className="btn btn-primary">
                                                                    <i className="fa fa-pen"></i>
                                                                </button>
                                                                <input className="form-check-input" type="checkbox" onChange={(e)=>this.handleSetTva(e.target.checked)} checked={tva} />
                                                            </>}
                                                            {editTva &&
                                                            <div className="form-group" style={{display: 'flex'}}>
                                                                <input onChange={(e) => this.handleChange(e, 'ntva')} min="0" value={ntva} type="number" className="form-control form-control-sm" placeholder="TVA" />
                                                                <button onClick={()=>this.changeTva()} style={{height: 31, marginLeft: 4}} type="button" className="btn btn-primary">Valider</button>
                                                            </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <p style={paragraphe}>Information sur le dévis/Facture proforma et les produits </p>
                                            <div className="row" style={row}>
                                                <div className="col-12 table-responsive">
                                                    <table  className="table table-bordered table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Référence</th>
                                                                <th>Désignation</th>
                                                                <th>Prix unitaire</th>
                                                                <th>Quantité</th>
                                                                <th>Montant</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.length > 0 &&
                                                                data.map((d, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td>{i + 1}</td>
                                                                            <td>{d.reference}</td>
                                                                            <td>{d.designation}</td>
                                                                            <td>{d.pvente}</td>
                                                                            <td>{d.quantite}</td>
                                                                            <td>{d.pvente * d.quantite}</td>
                                                                            <td>
                                                                                {/* <button className="btn btn-link" onClick={()=>this.handleEditOpen(d.id)} style={{padding: 0}}>
                                                                                    <i className="fas fa-pencil-alt"></i>
                                                                                </button> */}
                                                                                <button className="btn btn-link" onClick={()=>{
                                                                                    const datas = data.filter(f=>f.id !== d.id)
                                                                                    const t = this.calculMontant(datas)
                                                                                    const total = tva ? t+(t*(ntva/100)) : t
                                                                                    this.setState({ data: datas, total, tempTotal: t, ...initialState })
                                                                                }}>
                                                                                    <i className="fa fa-trash"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <button type="button" style={{marginBottom: 12}} className="btn btn-default"  onClick={() => this.setState({ modal: true, action: 'save' })} >
                                                        Ajouter
                                                    </button>
                                                </div>
                                            </div>

                                            <div className='nx-page-actions'>
                                                <Link to="devis" type="button" className="btn btn-secondary">Annuler</Link>
                                                <button type="submit" onClick={()=>this.setState({ action: "save" }) } className="btn btn-primary">Enregistrer</button>
                                                <button type="submit" onClick={()=>this.setState({ action: "print" }) } className="btn btn-primary">Enregistrer & Imprimer</button>
                                                {/* <button type="submit" onClick={()=>this.setState({ action: "send" }) } className="btn btn-primary">Enregistrer & Envoyer</button> */}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-12">
                                <ReactModal isOpen={modal} ariaHideApp={false}>
                                    <h5>{action === 'save' ? "Nouveau dévis/Facture proforma" : "Modification du dévis/Facture proforma"}</h5>
                                    <form method="POST" onSubmit={action === 'save' ? this.handleSubmitAddElement : this.handleSubmitUpdate}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <select className="form-control form-control-sm" onChange={(e) => this.handleChange(e, 'article_id')} required >
                                                            {action === 'save' && <option value="">--Choisir--</option>}
                                                            {action !== 'save' && <option value={editArticleId}>{editArticleLibelle}</option>}
                                                            {articleOptions.length > 0 &&
                                                                articleOptions.map((a, i) => <option value={a.id} key={i}>{a.designation}</option>)
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <input value={reference} className="form-control form-control-sm" type="text" placeholder="Référence" disabled />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <input value={pachat} className="form-control form-control-sm" placeholder="Prix d'achat" disabled />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <input value={pvente} className="form-control form-control-sm" placeholder="Prix de vente" disabled />
                                                    </div>
                                                </div>

                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <input value={quantite} onChange={(e) => this.handleChange(e, 'quantite')} type="number" min="1" className="form-control form-control-sm" placeholder="Quantité" required />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ textAlign: 'end' }}>
                                            <button onClick={() => this.setState({ ...initialState })} type="button" className="btn btn-secondary">Annuler</button>
                                            <button type="submit" style={{ marginLeft: 8 }} className="btn btn-primary">{action === 'save' ? 'Ajouter' : 'Modifier'}</button>
                                        </div>
                                    </form>
                                </ReactModal>

                                <ReactModal isOpen={modalClient} ariaHideApp={false}>
                                    <h5>Ajout d'un nouveau client</h5>
                                    <form method="POST" onSubmit={this.handleSubmitSaveClient}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <input value={nomClient}  onChange={(e)=>this.handleChange(e, 'nomClient')} type="text" className="form-control form-control-sm" placeholder="Nom complet *" required />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <input value={societeClient}  onChange={(e)=>this.handleChange(e, 'societeClient')} type="text" className="form-control form-control-sm" placeholder="Société" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <input value={emailClient}  onChange={(e)=>this.handleChange(e, 'emailClient')} type="email" className="form-control form-control-sm" placeholder="Email" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <input value={telephoneClient}  onChange={(e)=>this.handleChange(e, 'telephoneClient')} type="text" className="form-control form-control-sm" placeholder="Téléphone" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <input value={adresseClient}  onChange={(e)=>this.handleChange(e, 'adresseClient')} type="text" className="form-control form-control-sm" placeholder="Adresse" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{textAlign: 'end' }}>
                                            <button onClick={()=>this.setState({ ...initialStateClient })} type="button" className="btn btn-secondary">Annuler</button>
                                            <button type="submit" style={{marginLeft: 8}} className="btn btn-primary">Enregistrer</button>
                                        </div>
                                    </form>
                                </ReactModal>

                                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                                    <h5>Traitement ...</h5>
                                    <Spinner />
                                    <span className="breadcrumb-item active" style={{ display: 'block', textAlign: 'center' }}>
                                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                                    </span>
                                </ReactModal>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}