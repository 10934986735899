import React from 'react'
import { Link } from 'react-router-dom'
import ReactModal from 'react-modal'
import { ToastContainer, toast } from 'react-toastify'
import Head from '../../components/Head'
import Spinner from '../../components/Spinner'
import Session from '../../session'
import { generatePdf } from '../print.controller'
import Tab from '../../components/Tab'

const initialState = {
    currentId: '',
    popLoad: false,
}

const paragraphe = {marginLeft: 8, background: '#fff', zIndex: 1, position: 'relative', display: 'inline-block', padding: '0px 8px', fontSize: 11}
const row = {margin: 0, border: '1px solid #ddd',paddingTop: 24, borderRadius: 8, top: -24, position: 'relative'}

export default class DetailFournisseur extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            data: [],
            fournisseur: {},
            load: true,
            ...initialState
        }
    }

    gen(data){
        generatePdf(data, 'sorties')
        .then(html=>{
            var temp = document.createElement('div');
            temp.innerHTML = html;
            var pri = document.getElementById("ifmcontentstoprint").contentWindow;
            pri.document.open();
            pri.document.write(temp.innerHTML);
            pri.print();
            pri.document.close();
        }).catch(err=>console.log(err))
    }

    handlePrintOne(id){
        this.setState({popLoad: true})
        const store = Session.get('$sf_structure')
        
        this.props.controller.detail(id)
        .then(res=>{
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ ...initialState })
                this.gen({...res, store})
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState }) }
        })
        .catch(()=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState }) })
    }

    componentDidMount() {
        const id = this.props.match.params.id
        this.props.controller.findByFournisseur(id)
        .then(data => {
            if(!data.error && typeof(data.pror) === 'undefined'){
                this.setState({data: data.operations, fournisseur: data.fournisseur, load: false }) 
            } else{ toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite."); this.setState({ load: false }) }
        })
        .catch(()=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ load: false }) })
    }

    render(){
        const { pageName } = this.props
        const { data, load, popLoad, fournisseur } = this.state
        return(
            <>
                <Head name={pageName}  />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12"><Tab name="fournisseurs" /></div>
                            <ToastContainer />
                            <div className="col-12">
                                <div className="card nx-detail-card">
                                    <div className="nx-card-header">
                                        <button className="btn btn-link" style={{float: 'inline-start'}} onClick={()=>this.props.history.goBack()}>
                                            <i className="fa fa-arrow-left"></i>
                                        </button>
                                        <h3 className="nx-card-title">La liste des opérations du fournisseur</h3>
                                    </div>
                                    
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <p style={paragraphe}>Les informations du fournisseur</p>
                                                <div className="row" style={{...row, marginRight: 8}}>
                                                    <div className="col-sm-12">
                                                        <p style={{marginBottom: 4}}><strong>Nom : </strong>{fournisseur.nom}</p>
                                                        <p style={{marginBottom: 4}}><strong>Société : </strong>{fournisseur.societe}</p>
                                                        <p style={{marginBottom: 4}}><strong>Téléphone : </strong>{fournisseur.telephone}</p>
                                                        <p style={{marginBottom: 4}}><strong>Email : </strong>{fournisseur.email}</p>
                                                        <p style={{marginBottom: 4}}><strong>Adresse : </strong>{fournisseur.adresse}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {load && <Spinner />}
                                            <div className="col-sm-12">
                                             <p style={paragraphe}>Les opérations du fournisseur</p>
                                                <div className="row" style={{...row, marginRight: 8}}>
                                                    <div className="col-sm-12 table-responsive">
                                                        <table className="table table-bordered table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>commande</th>
                                                                    <th>Montant</th>
                                                                    <th>Date</th>
                                                                    <th>Type d'opération</th>
                                                                    <th>Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data.length > 0 &&
                                                                data.map((d,i)=>{
                                                                    return(
                                                                        <tr key={i}>
                                                                            <td>{i+1}</td>
                                                                            <td>{d.id}</td>
                                                                            <td>{d.montant}</td>
                                                                            <td>{d.date}</td>
                                                                            <td><span className="badge bg-success">ACHAT</span></td>
                                                                            <td>
                                                                                <Link className="btn btn-link" to={{pathname: '../entrees/'+d._id}} style={{padding: 0}}>
                                                                                    <i className="fas fa-eye"></i>
                                                                                </Link>
                                                                                <button className="btn btn-link" onClick={()=>this.handlePrintOne(d.id)}>
                                                                                    <i className="fa fa-print"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })

                                                                }
                                                            </tbody>
                                                        </table>
                                                        {typeof(data.length) === 'undefined' && !load &&
                                                        <span className="breadcrumb-item active" style={{display: 'block', textAlign: 'center', marginTop: 8}}>
                                                            {`Aucune information trouvée !`}
                                                        </span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                                    <h5>Traitement ...</h5>
                                    <Spinner />
                                    <span className="breadcrumb-item active" style={{display: 'block', textAlign: 'center'}}>
                                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                                    </span>
                                </ReactModal>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}