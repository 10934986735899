import React from 'react'
import ReactModal from 'react-modal'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import Head from '../../components/Head'
import SearchInput from '../../components/Search'
import Spinner from '../../components/Spinner'
import Table from '../../components/Table'
import { formatDate } from '../../lib/functions'
import Session from '../../session'
import { generatePdf } from '../print.controller'

const initialState = {
    article_id: '',
    // for edit
    libelle: '',
    editArticleId: '',
    //===========
    reference: '',
    pachat: '',
    pvente: '',
    quantite: '',
    client: '',
    date: '',
    action: '',
    currentId: '',
    popLoad: false,
    modal: false,
    modalDelete: false
}
const initialPaiementState = { montantPaiement: 0, currentPaiementReliquat: 0, modalPaiement: false, btnLoad: false }

export default class Commande extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            tempData: [],
            search: '',
            articleOptions: [],
            load: true,
            ...initialState
        }
        this.handleSubmitSave = this.handleSubmitSave.bind(this)
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this)
        this.handlePaiementSubmitSave = this.handlePaiementSubmitSave.bind(this)
    }

    handleChange(e, name) {
        if (name === 'article_id') {
            const article = this.state.articleOptions.find(a => a.id == e.target.value)
            if (typeof (article) !== 'undefined') {
                this.setState({ [name]: e.target.value, reference: article.reference, pachat: article.pachat, pvente: article.pvente })
            }
        } else {
            this.setState({ [name]: e.target.value })
        }
    }

    handleEditOpen(id) {
        this.setState({ popLoad: true })
        this.props.controller.findById(id)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ ...res[0], editArticleId: res[0].article_id, modal: true, action: 'update', currentId: id, popLoad: false })
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
    }
    handlePaiementOpen(id) {
        const current = this.state.data.find(d => d.id === id || d._id === id)
        if (current) {
            if (parseInt(current.reliquat) > 0) this.setState({ modalPaiement: true, currentId: current.id, currentPaiementReliquat: current.reliquat })
            else toast.info("Cette entrée a déjà été réglé !")
        }
        else toast.error("Une erreur inattendue s'est produite, veuillez reesayer!")
    }
    handleDeleteOpen(id) {
        if (id) { this.setState({ modalDelete: true, currentId: id }) }
        else { toast.error("Une erreur inattendue s'est produite.") }
    }

    handleSubmitSave(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { article_id, quantite, client, date } = this.state
        const data = { article_id, quantite: parseInt(quantite), client, date, type: 'in' }

        this.props.controller.save(data)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ ...initialState })
                    toast.success(res.message)
                    this.componentDidMount()
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
    }

    handleSubmitUpdate(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { currentId, article_id, quantite, client, date } = this.state
        const data = { id: currentId.toString(), article_id, quantite: parseInt(quantite), client, date, type: 'in' }
        if (currentId) {
            this.props.controller.save(data)
                .then(res => {
                    if (!res.error && typeof (res.pror) === 'undefined') {
                        this.setState({ ...initialState, modal: false })
                        toast.success(res.message)
                        this.componentDidMount()
                    }
                    else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
                })
                .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
        }
    }

    handleDelete() {
        this.setState({ popLoad: true })
        const { currentId } = this.state
        if (currentId) {
            this.props.controller.remove(currentId)
                .then(res => {
                    if (!res.error && typeof (res.pror) === 'undefined') {
                        this.setState({ ...initialState })
                        toast.success(res.message)
                        this.componentDidMount()
                    }
                    else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState }) }
                })
                .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState }) })
        }
    }

    handlePaiementSubmitSave(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { currentId, montantPaiement } = this.state
        const data = { montant: montantPaiement, achat_id: currentId }

        this.props.controller.savePaiement(data)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ ...initialPaiementState, popLoad: false })
                    toast.success(res.message)
                    this.fetch()
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
            })
            .catch((e) => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }); console.log(e) })
    }

    gen(data) {
        generatePdf(data, 'entrees')
            .then(html => {
                var temp = document.createElement('div');
                temp.innerHTML = html;
                var pri = document.getElementById("ifmcontentstoprint").contentWindow;
                pri.document.open();
                pri.document.write(temp.innerHTML);
                pri.print();
                pri.document.close();
            }).catch(err => console.log(err))
    }

    handlePrintOne(id) {
        this.setState({ popLoad: true })
        const store = Session.get('$sf_structure')

        this.props.controller.detail(id)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ ...initialState })
                    this.gen({ ...res, store })
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState }) }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState }) })
    }

    fetch = () => {
        this.props.controller.commandes()
            .then(data => {
                if (!data.error && typeof (data.pror) === 'undefined') {
                    this.setState({ data, tempData: data, load: false })
                } else { toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite."); this.setState({ load: false }) }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ load: false }) })
    }
    componentDidMount = () => this.fetch()

    handleTableSearch = value => {
        const data = this.state.tempData.filter(td => {
            return (
                td?.id.toString().toLowerCase().includes(value.toLowerCase()) ||
                td?.montant.toString().toLowerCase().includes(value.toLowerCase()) ||
                td?.tva.toLowerCase().includes(value.toLowerCase()) ||
                formatDate(td?.date).toLowerCase().includes(value.toLowerCase()) ||
                td?.nom.toLowerCase().includes(value.toLowerCase()) ||
                td?.telephone.toLowerCase().includes(value.toLowerCase()) ||
                td?.email.toLowerCase().includes(value.toLowerCase()) ||
                td?.etat.toLowerCase().includes(value.toLowerCase()) ||
                td?.livraison.toLowerCase().includes(value.toLowerCase())
            )
        })
        this.setState({ data, search: value })
    }

    render() {
        const { pageName, history, columns } = this.props
        const { data, modalDelete, load, popLoad, montantPaiement, currentPaiementReliquat, modalPaiement } = this.state
        return (
            <>
                <Head name={pageName} />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <ToastContainer />
                            <div className="col-12">
                                <div className="card">
                                    <div className="nx-card-header">
                                        <h3 className="nx-card-title">La liste des commandes</h3>
                                        <div className="btn-group btn-group-sm" role="group" aria-label="" style={{ float: 'right' }}>
                                            <Link type="button" className="btn btn-success" to="commandes-new">Ajouter</Link>
                                            {/* <button  type="button" className="btn btn-secondary" onClick={()=>this.handlePrintAll()}>Imprimer</button> */}
                                        </div>
                                    </div>

                                    <div className="nx-card-body card-body">
                                        <SearchInput value={this.state.search} onChange={(val) => this.handleTableSearch(val)} />
                                        <Table
                                            columns={columns}
                                            data={data}
                                            load={load}
                                            editable={false}
                                            countable={false}
                                            hasCustomItem={true}
                                            customItemIcon='fas fa-money'
                                            customItemLabel='Paiement'
                                            onCustomItemAction={(id) => this.handlePaiementOpen(id)}
                                            onView={(id) => history.push(`commandes/${id}`)}
                                            onPrint={(id) => this.handlePrintOne(id)}
                                            onDelete={(id) => this.handleDeleteOpen(id)}
                                            onEdit={(id) => this.handleEditOpen(id)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <ReactModal isOpen={modalPaiement} ariaHideApp={false}>
                                    <h5>Nouveau paiement</h5>
                                    <form method="POST" onSubmit={this.handlePaiementSubmitSave}>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>Montant *</label>
                                                    <input type="number" min={5} value={montantPaiement} onChange={(e) => this.handleChange(e, 'montantPaiement')} className="form-control" placeholder="Montant *" required />
                                                </div>
                                                <p>Reliquat: {currentPaiementReliquat} Fcfa</p>
                                            </div>
                                        </div>

                                        <div style={{ textAlign: 'end' }}>
                                            <button onClick={() => this.setState({ ...initialPaiementState })} type="button" className="btn btn-secondary">Annuler</button>
                                            {montantPaiement <= currentPaiementReliquat && <button type="submit" style={{ marginLeft: 8 }} className="btn btn-primary">Enregistrer</button>}
                                        </div>
                                    </form>
                                </ReactModal>

                                <ReactModal isOpen={modalDelete} ariaHideApp={false}>
                                    <h5>Suppresion de la commande</h5>

                                    <span className="breadcrumb-item active">
                                        {`Etes-vous sûr de vouloir supprimer cette commande, cette action est irreversible et entrainera la suppression de tous les produits issus de cette commande et de tous les paiements sur cette commande.`}
                                    </span>

                                    <div style={{ textAlign: 'end', marginTop: 16 }}>
                                        <button onClick={() => this.setState({ ...initialState })} type="button" className="btn btn-secondary">Annuler</button>
                                        <button onClick={() => this.handleDelete()} style={{ marginLeft: 8 }} className="btn btn-primary">Supprimer</button>
                                    </div>
                                </ReactModal>

                                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                                    <h5>Traitement ...</h5>
                                    <Spinner />
                                    <span className="breadcrumb-item active" style={{ display: 'block', textAlign: 'center' }}>
                                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                                    </span>
                                </ReactModal>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
