const Spinner = ({size=24, color='#b53da2', weight='3px'}) => {
  return (
    <div className="d-flex justify-content-center" style={{marginBottom: 16 }}>
      <div className="text-success spinner-border" role="status" style={{width: size, height: size, border: `${weight} solid ${color}` }}>
        <span className="sr-only">Chargement...</span>
      </div>
    </div>
  );
}

export default Spinner;
// spinner-border
// border: .2em solid #b53da2 !important;
//   border-right-color: rgb(181, 61, 162);
// border-right-color: transparent !important;
// width: 1.5rem !important;
// height: 1.5rem !important;