import Vente from './home'
import VenteNew from './new'
import VenteDetail from './view'
import { ArticlesServices, ClientsServices, EntreesServices, MagasinsServices, SortiesServices } from '../../services'
import NotFound from '../not-found'

const VentePage = (props) => {
    const { permission, session } = props
    const controller = new SortiesServices(session)

    const columns = [
        // { name: '_id', header: 'ID' },
        { name: 'id', header: 'ID', prefix: 'VTE_' },
        { name: 'montant', header: 'Montant', suffix: session.devise ? ` ${session.devise}` : ' F CFA' },
        { name: 'tva', header: 'TVA', suffix: ' %' },
        { name: 'date', header: 'Date', date: 'date-fr' },
        { name: 'nom', header: 'Nom Clt.' },
        { name: 'paye', header: 'Montant payé', suffix: session.devise ? ` ${session.devise}` : ' F CFA' },
        { name: 'reliquat', header: 'Reste à payer', suffix: session.devise ? ` ${session.devise}` : ' F CFA' },
        { name: 'type', header: 'Type', },
        // { name: 'statut', header: 'Statut', },
    ]

    if (permission === '1')
        return <Vente
            {...props}
            controller={controller}
            columns={columns}
            devise={session.devise ? session.devise : 'F CFA'}
        />
    else return <NotFound />
}

const NewVentePage = (props) => {
    const { permission, session } = props
    const controller = new SortiesServices(session)
    const clientController = new ClientsServices(session)
    const articleController = new ArticlesServices(session)
    const magasinController = new MagasinsServices(session)
    const achatController = new EntreesServices(session)

    if (permission === '1')
        return <VenteNew
            {...props}
            controller={controller}
            clientController={clientController}
            articleController={articleController}
            magasinController={magasinController}
            achatController={achatController}
            devise={session.devise ? session.devise : 'F CFA'}
        />
    else return <NotFound />
}

const VenteDetailPage = (props) => {
    const { permission, session } = props
    const controller = new SortiesServices(session)

    if (permission === '1')
        return <VenteDetail
            {...props}
            controller={controller}
            devise={session.devise ? session.devise : 'F CFA'}
        />
    else return <NotFound />
}


export { VentePage, NewVentePage, VenteDetailPage }