import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import md5 from 'md5'
import logo from '../../assets/img/logo.png'
import Session from '../../session'
import Spinner from '../../components/Spinner'
import './index.css'

const initialState = { password: '', login: '' }
export default class Login extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            load: false,
            forgotPassword: false,
            ...initialState,
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleForgotPassword = this.handleForgotPassword.bind(this)
    }

    handleChange(e, name){ this.setState({ [name]: e.target.value }) }
    forgotPassword = ()=> this.setState({ forgotPassword: !this.state.forgotPassword })
    handleSubmit = e =>{
        e.preventDefault()
        this.setState({ load: true })
        const { login, password } = this.state
        const data = { login, password: md5(password) }

        this.props.controller.save(data)
        .then(res => {
            if (!res.error && typeof (res.pror) === 'undefined') {
                if(res.id){
                    Session.login(res)
                    // Session.reset()
                    const dash = JSON.parse(res.permissions)['dashboard']
                    if(dash === '1') window.location = '/home'
                    else window.location = '/profil'
                }else{
                    toast.error("Une erreur inattendue s'est produit.")
                    this.setState({ load: false })
                }
            } else {
                this.setState({ load: false })
                toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produit.")
            }
        })
        .catch(() => { toast.error("Une erreur inattendue s'est produit."); this.setState({ load: false }) })
        
    }
    goToWorkspace = () => {
        Session.reset()
        window.location = '/'
    }

    handleForgotPassword = e =>{

    }

    render() {
        const { load, forgotPassword } = this.state
        return (
            <div className="" style={{ height: '100vh' }}>
                <div className="row" style={{ margin: 0, height: '100%' }}>
                    <ToastContainer />

                    <div className="col-ms-12 col-lg-5 ">
                        <div className='nx-login-form-container'>
                            <div className="nx-login-form-content card-body login-card-body">
                                <div className="login-logo">
                                    <img src={logo} alt="logo" width="150" />
                                </div>

                                <h1 className='nx-login-welcome'>BIENVENUE SUR L'ESPACE CODESIGN</h1>
                                <div className='nx-login-msg-container'>
                                    <div className='nx-mi-divier'></div>
                                    <span style={{ marginLeft: 8, marginRight: 8 }}>{!forgotPassword ? 'CONNEXION À VOTRE COMPTE' : 'MOT DE PASSE OUBLIÉ'}</span>
                                    <div className='nx-mi-divier'></div>
                                </div>
                                {!forgotPassword && 
                                    <form onSubmit={this.handleSubmit} method="POST">
                                        <label className='nx-input-label'>Nom d'utilisateur</label>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="Nom d'utilisateur" name="login" onChange={(e)=>this.handleChange(e, 'login')} required />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className="fas fa-user"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <label className='nx-input-label'>Mot de passe</label>
                                        <div className="input-group mb-3">
                                            <input type="password" className="form-control" placeholder="Mot de passe" name="password"  onChange={(e)=>this.handleChange(e, 'password')} required />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className="fas fa-lock"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                {load && <Spinner />}
                                                {!load && <button type="submit" className="btn btn-primary btn-block" style={{background: '#b53da2' }}>Se connecter</button>}
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop: 12}}>
                                            <div className="col-lg-6 col-ms-12">
                                                <button type="button" className="btn btn-link btn-block" style={{textAlign: 'left'}} onClick={()=>this.goToWorkspace()} >Changer l'espace de travail</button>
                                            </div>
                                            <div className="col-lg-6 col-ms-12">
                                                <button type="button" className="btn btn-link btn-block" style={{textAlign: 'left'}} onClick={()=>this.forgotPassword()} >Mot de passe oublier ?</button>
                                            </div>
                                        </div>
                                    </form>
                                }
                                {forgotPassword && 
                                    <form onSubmit={this.handleForgotPassword} method="POST">
                                        <label className='nx-input-label'>Adresse E-mail</label>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="Adresse E-mail" name="email" onChange={(e)=>this.handleChange(e, 'login')} required />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className="fas fa-envelope"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                {load && <Spinner />}
                                                {!load && <button type="submit" className="btn btn-primary btn-block" style={{background: '#b53da2' }}>Valider</button>}
                                            </div>
                                        </div>
                                        <div className="row" style={{marginTop: 12}}>
                                            <div className="col-lg-6 col-ms-12">
                                                <button type="button" className="btn btn-link btn-block" style={{textAlign: 'left'}} onClick={()=>this.forgotPassword()} >Se connecter</button>
                                            </div>
                                        </div>
                                    </form>
                                }
                            </div>

                        </div>
                    </div>
                    <div className="col-ms-12 col-lg-7 nx-login-description-content" >
                        <div className='nx-login-description-wraper'>
                            <button className='btn nx-login-policy-btn'>
                                <span className="fas fa-book" style={{marginRight: 8}}></span>Politique de confidentialité
                            </button>
                        </div>
                        <div className='nx-login-description'></div>
                    </div>
                </div>
            </div>
        )
    }
}