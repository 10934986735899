import Article from './home'
import { ArticlesServices, CategoriesServices, UnitesServices } from '../../services'
import NotFound from '../not-found'

const ArticlePage = (props) => {
    const { permission, session } = props
    const controller = new ArticlesServices(session)
    const categorieController = new CategoriesServices(session)
    const uniteController = new UnitesServices(session)

    const columns = [
        { name: 'id', header: 'id', prefix: 'ART_' },
        { name: 'designation', header: 'Désignation' },
        { name: 'unite', header: 'Unité' },
        { name: 'categorie', header: 'Categorie' }
    ]

    if (permission === '1')
        return <Article
            {...props}
            controller={controller}
            categorieController={categorieController}
            uniteController={uniteController}
            columns={columns}
            devise={session.devise ? session.devise : 'F CFA'}
        />
    else return <NotFound />
}


export { ArticlePage }