const Footer = () =>{
  return (
    <footer className="main-footer" style={{position: 'fixed', bottom: 0, height: 58, width: '100%'}}>
        <div className="float-right">
            Sani<strong>féré</strong>
        </div>
        Conçu par <a rel="noreferrer" href="https://codesign.ml" target="_blank"> CODESIGN</a> All rights reserved &copy; 2021.
    </footer>
  );
}

export default Footer;
