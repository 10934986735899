import React from 'react'
import ReactModal from 'react-modal'
import { ToastContainer, toast } from 'react-toastify'
import Session from '../../session'
import { generatePdf } from '../print.controller'
import Head from '../../components/Head'
import Spinner from '../../components/Spinner'
import Table from '../../components/Table'
import SearchInput from '../../components/Search'
import { formatEditDate } from '../../lib/functions'

// const columns = [
//     // { name: '_id', header: 'ID' },
//     { name: 'reference', header: 'Référence' },
//     { name: 'designation', header: 'Désignation' },
//     { name: 'pachat', header: 'Prix d\'achat', suffix: ' F cfa' },
//     { name: 'pvente', header: 'Prix de vente', suffix: ' F cfa' },
//     { name: 'quantite', header: 'Quantité' },
//     { name: 'unite', header: 'unité' }
// ]


const initialState = {
    modal: false,
    popLoad: false,
    modalDisplay: false
}
export default class Stock extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            tempData: [],
            storeOptions: [],
            displayType: 'stock',
            displayTypeTemp: 'stock',
            stock: {},
            search: '',
            load: true,
            ...initialState
        }
        this.apply = this.apply.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    gen(data) {
        generatePdf(data, 'stock')
            .then(html => {
                var temp = document.createElement('div');
                temp.innerHTML = html;
                var pri = document.getElementById("ifmcontentstoprint").contentWindow;
                pri.document.open();
                pri.document.write(temp.innerHTML);
                pri.print();
                this.setState({ popLoad: false })
                pri.document.close();
            }).catch(err => console.log(err))
    }

    handlePrintAll() {
        this.setState({ popLoad: true })
        const store = Session.get('$sf_structure')
        const data = { stock: this.state.data, store }
        this.gen(data)
    }

    handleChange = (e, name) => this.setState({ stock: { ...this.state.stock, [name]: e.target.value } })

    handleTableSearch = value => {
        const data = this.state.tempData.filter(td => {
            return (
                td?.designation.toLowerCase().includes(value.toLowerCase()) ||
                td?.reference.toLowerCase().includes(value.toLowerCase()) ||
                td?.quantite.toString().toLowerCase().includes(value.toLowerCase()) ||
                td?.pachat.toString().toLowerCase().includes(value.toLowerCase()) ||
                td?.pvente.toString().toLowerCase().includes(value.toLowerCase())
            )
        })
        this.setState({ data, search: value })
    }

    apply = (e) => {
        e.preventDefault()
        const { displayTypeTemp } = this.state
        this.setState({ popLoad: true })
        this.fetch(displayTypeTemp)
    }

    onEdit = (id) => {
        const stock = this.state.data.find(s => s.id === id)
        if (stock) this.setState({ stock: { ...stock, expire: formatEditDate(stock.expire) }, modal: true })
        else toast.error("Une erreur inattendue s'est produite, veuillez reesayer!")
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { stock } = this.state
        if (stock.id) {
            this.props.controller.save({...stock, id: stock.id.toString()})
                .then(res => {
                    if (!res.error && typeof (res.pror) === 'undefined') {
                        this.setState({ ...initialState, load: true, popLoad: false })
                        toast.success(res.message)
                        this.fetch(this.state.displayTypeTemp)
                    } else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
                })
                .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
        }
    }

    fetch = (type) => {
        this.props.controller.stock(type)
            .then(data => {
                if (!data.error && typeof (data.pror) === 'undefined') {
                    this.setState({
                        data,
                        tempData: data,
                        load: false,
                        displayType: type,
                        displayTypeTemp: type,
                        ...initialState,
                    })
                } else { toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite."); this.setState({ load: false }) }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ load: false }) })
    }

    fetchStores = () => {
        this.props.storeController.fetch()
            .then(data => {
                if (!data.error && typeof (data.pror) === 'undefined') this.setState({ storeOptions: data })
                else toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite.")
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ load: false }) })
    }

    componentDidMount = () => {
        this.fetch(this.state.displayType)
        this.fetchStores()
    }

    render() {
        const { pageName, columns, columnsAll } = this.props
        const { data, load, popLoad, displayType, displayTypeTemp, modal, modalDisplay, stock, storeOptions } = this.state
        return (
            <>
                <Head name={pageName} />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <ToastContainer />
                            <div className="col-12">
                                <div className="card">
                                    <div className="nx-card-header">
                                        <h3 className="nx-card-title">La liste des articles en stock</h3>
                                        <div className="btn-group btn-group-sm" role="group" aria-label="" style={{ float: 'right' }}>
                                            <button type="button" className="btn btn-primary" onClick={() => this.setState({ modalDisplay: true })}>Affichage</button>
                                            <button type="button" className="btn btn-secondary" onClick={() => this.handlePrintAll()}>Imprimer</button>
                                        </div>
                                    </div>

                                    <div className="nx-card-body card-body">
                                        <SearchInput value={this.state.search} onChange={(val) => this.handleTableSearch(val)} />
                                        <Table
                                            columns={displayType === 'stock' ? columns : columnsAll}
                                            crud={displayType === 'stock' ? false : true}
                                            data={data}
                                            load={load}
                                            detaillable={false}
                                            deletable={false}
                                            printable={false}
                                            onEdit={(id) => this.onEdit(id)}
                                        />
                                    </div>
                                </div>
                                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                                    <h5>Traitement ...</h5>
                                    <Spinner />
                                    <span className="breadcrumb-item active" style={{ display: 'block', textAlign: 'center' }}>
                                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                                    </span>
                                </ReactModal>

                                <ReactModal isOpen={modalDisplay} ariaHideApp={false}>
                                    <h5>Changer l'affichage du tableau</h5>
                                    <form method="POST" onSubmit={this.apply}>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>Type d'affichage *</label>
                                                    <select
                                                        className="form-control form-control-sm"
                                                        onChange={(e) => this.setState({ displayTypeTemp: e.target.value })}
                                                        value={displayTypeTemp} required
                                                    >
                                                        <option value="">--Type d'affichage--</option>
                                                        <option value="stock">Affichage par defaut</option>
                                                        <option value="stock-all">Affichage en détail</option>

                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ textAlign: 'end' }}>
                                            <button onClick={() => this.setState({ ...initialState })} type="button" className="btn btn-secondary">Annuler</button>
                                            <button type="submit" style={{ marginLeft: 8 }} className="btn btn-primary">Appliquer</button>
                                        </div>
                                    </form>
                                </ReactModal>

                                <ReactModal isOpen={modal} ariaHideApp={false}>
                                    <h5>Modification du stock des matières premières</h5>

                                    <form method="POST" onSubmit={this.handleSubmit}>
                                        <div className="row">
                                            <div className="col-sm-12 col-lg-6">
                                                <div className="form-group">
                                                    <label className='nx-input-label'>Libellé du produit fini</label>
                                                    <input
                                                        type="text"
                                                        value={stock.designation}
                                                        onChange={(e) => this.handleChange(e, 'designation')}
                                                        className="form-control form-control-sm"
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-lg-6">
                                                <div className="form-group">
                                                    <label className='nx-input-label'>Référence *</label>
                                                    <input
                                                        type="text"
                                                        value={stock.reference}
                                                        onChange={(e) => this.handleChange(e, 'reference')}
                                                        className="form-control form-control-sm"
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-lg-6">
                                                <div className="form-group">
                                                    <label className='nx-input-label'>Quantité achetée *</label>
                                                    <input
                                                        type="number"
                                                        value={stock.quantite}
                                                        onChange={(e) => this.handleChange(e, 'quantite')}
                                                        className="form-control form-control-sm"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-lg-6">
                                                <div className="form-group">
                                                    <label className='nx-input-label'>Quantité restante *</label>
                                                    <input
                                                        type="number"
                                                        max={stock.quantite}
                                                        value={stock.quantiteRestante}
                                                        onChange={(e) => this.handleChange(e, 'quantiteRestante')}
                                                        className="form-control form-control-sm"
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-lg-6">
                                                <div className="form-group">
                                                    <label className='nx-input-label'>Date de péremption *</label>
                                                    <input
                                                        type="date"
                                                        value={stock.expire}
                                                        onChange={(e) => this.handleChange(e, 'expire')}
                                                        className="form-control form-control-sm"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-lg-6">
                                                <div className="form-group">
                                                    <label className='nx-input-label'>Magasin de stockage</label>
                                                    <div style={{ display: 'flex' }}>
                                                        <select className="form-control form-control-sm" value={stock.storeId} onChange={(e) => this.handleChange(e, 'storeId')} >
                                                            <option value="0">--Magasin de stockage--</option>
                                                            {storeOptions.map((a, i) => <option value={a.id} key={i}>{a.nom}</option>)}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <fieldset className="form-group" style={{ textAlign: 'end' }}>
                                            <button onClick={() => this.setState({ ...initialState })} type="button" className="btn btn-secondary">Annuler</button>
                                            <button type="submit" name="valider" style={{ marginLeft: 8 }} className="btn btn-primary">Modifier</button>
                                        </fieldset>
                                    </form>
                                </ReactModal>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}