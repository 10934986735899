import Devis from './home'
import DevisNew from './new'
import DevisDetail from './view'
import { ArticlesServices, CategoriesServices, ClientsServices, DevisServices, EntreesServices, MagasinsServices, UnitesServices } from '../../services'
import NotFound from '../not-found'

const DevisPage = (props) => {
    const { permission, session } = props
    const controller = new DevisServices(session)
    const categorieController = new CategoriesServices(session)
    const uniteController = new UnitesServices(session)

    const columns = [
        { name: 'id', header: 'Id', prefix: 'DVS_' },
        { name: 'montant', header: 'Montant', suffix: session.devise ? ` ${session.devise}` : ' F CFA'  },
        { name: 'tva', header: 'TVA', suffix: ' %' },
        { name: 'date', header: 'Date', date: 'date-fr' },
        { name: 'nom', header: 'Nom Clt.' },
        { name: 'telephone', header: 'Téléphone Clt.' },
        { name: 'email', header: 'Email Clt.' },
    ]

    if (permission === '1')
        return <Devis
            {...props}
            controller={controller}
            categorieController={categorieController}
            uniteController={uniteController}
            columns={columns}
            devise={session.devise ? session.devise : 'F CFA'}
        />
    else return <NotFound />
}

const NewDevisPage = (props) => {
    const { permission, session } = props
    const controller = new DevisServices(session)
    const articleController = new ArticlesServices(session)
    const clientController = new ClientsServices(session)
    const magasinController = new MagasinsServices(session)
    const achatController = new EntreesServices(session)

    if (permission === '1')
        return <DevisNew
            {...props}
            controller={controller}
            clientController={clientController}
            articleController={articleController}
            magasinController={magasinController}
            achatController={achatController}
            devise={session.devise ? session.devise : 'F CFA'}
        />
    else return <NotFound />
}


const DevisDetailPage = (props) => {
    const { permission, session } = props
    const controller = new DevisServices(session)

    if (permission === '1')
        return <DevisDetail
            {...props}
            controller={controller}
            devise={session.devise ? session.devise : 'F CFA'}
        />
    else return <NotFound />
}


export { DevisPage, NewDevisPage, DevisDetailPage }