import React from "react";
import { Link } from "react-router-dom";
import session from '../session'
import logo from './../assets/img/logo.png'
import user from './../assets/img/user.png'
import './navbar.css'

export default class NavBar extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            active: window.location.pathname,
            cls: ''
        }
    }

    handleLogout(){
        session.remove('$sf_user')
        window.location = this.props.baseUrl
    }

    toggleNav(){
        let body = document.getElementById('holdbody')
        if(body.className === 'hold-transition sidebar-mini'){
            if(window.innerWidth > 991){
                body.className = 'sidebar-mini sidebar-collapse'
            }else{
                body.className = 'sidebar-mini sidebar-open'
            }
        }else{
            if(body.className === 'sidebar-mini sidebar-closed sidebar-collapse' || body.className === 'sidebar-mini sidebar-collapse'){
                body.className = 'sidebar-mini sidebar-open'
            }else{
                // if(body.className === 'sidebar-mini sidebar-closed sidebar-collapse')
                body.className = 'sidebar-mini sidebar-collapse'
            }
        }
        this.setState({cls: body.className })
    }

    handleNavItemClicked = (name) =>{
        this.setState({ active: name })
        if(window.innerWidth <= 991){
            this.toggleNav()
        }
    }

    updateDimensions = () => {
        let body = document.getElementById('holdbody')
        if(window.innerWidth <= 991){
            body.className = 'sidebar-mini sidebar-closed sidebar-collapse'
        }else{
            // body.className = 'sidebar-mini sidebar-collapse'
        }
        this.setState({cls: body.className })
    };

      
    componentDidMount() {
        this.updateDimensions()
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render(){
        const { active, cls } = this.state
        const { username, /*role,*/ permissions, expired, baseUrl } = this.props
        
        return (
            <>
                <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                    {cls !== 'sidebar-mini sidebar-open' &&
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link"  data-widget="pushmenu" to="#" role="button" onClick={()=>this.toggleNav()}>
                                <i className="fas fa-bars"></i>
                            </Link>
                        </li>
                    </ul>}

                    <ul className="navbar-nav ml-auto">

                        <li className="nav-item">
                            <Link className="nav-link" to="#" onClick={()=>this.handleLogout()}>
                                <i className="fa fa-power-off"></i> Déconnexion
                            </Link>
                        </li>
                    </ul>
                </nav>

                <aside className="main-sidebar sidebar-dark-primary elevation-4" style={{ position: 'fixed' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #ddd'}}>
                        <Link to={`${baseUrl}/home`} className="brand-link" style={{height: 56, border: 'none' }} onClick={()=>this.setState({ active: "/home"})}>
                            <img src={logo} alt="Logo" className="brand-image" style={{maxHeight: 42, marginTop: -6, float: "none !important"}} />
                            {/* <!-- <span className="brand-text font-weight-light"><b></b></span> --> */}
                        </Link>
                        {cls === 'sidebar-mini sidebar-open' &&
                            <Link className="nav-link"  data-widget="pushmenu" to="#" role="button" onClick={()=>this.toggleNav()}>
                                <i className="fas fa-chevron-left"></i>
                            </Link>
                        }
                    </div>

                    <div className="sidebar">
                        
                        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                            <div className="image">
                                <img src={user} alt="user" className="img-circle elevation-2" />
                            </div>
                            <div className="info">
                                {!expired && <Link to={`${baseUrl}/profil`} onClick={()=>this.handleNavItemClicked("/profil")}className="d-block">{username}</Link>}
                                {expired && <Link to="#" className="d-block">{username}</Link>}
                            </div>
                        </div>

                        <nav className="mt-2">
                            {!expired && 
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                {/* <!-- Add icons to the links using the .nav-icon className with font-awesome or any other icon font library --> */}
                                {permissions.dashboard === '1' &&
                                <li className="nav-item">
                                    <Link to={`${baseUrl}/home`} className={(active === "/home") ? 'nav-link active' : 'nav-link'} onClick={()=>this.handleNavItemClicked("/home")}>
                                        <i className="nav-icon fas fa-tachometer-alt"></i>
                                        <p> Tableau de board </p>
                                    </Link>
                                </li>}
                                {permissions.depenses === '1' &&
                                <li className="nav-item">
                                    <Link to={`${baseUrl}/depenses`} className={(active === "/depenses") ? 'nav-link active' : 'nav-link'} onClick={()=>this.handleNavItemClicked("/depenses")}>
                                        <i className="nav-icon fas fa-wallet"></i>
                                        <p> Charges fixes </p>
                                    </Link>
                                </li>}
                                {permissions.produits === '1' &&
                                <li className="nav-item">
                                    <Link to={`${baseUrl}/articles`} className={(active === "/articles") ? 'nav-link active' : 'nav-link'} onClick={()=>this.handleNavItemClicked("/articles")}>
                                        <i className="nav-icon fas fa-gifts"></i>
                                        <p> Articles </p>
                                    </Link>
                                </li>}
                                {permissions.commandes === '1' &&
                                <li className="nav-item">
                                    <Link to={`${baseUrl}/commandes`} className={(active === "/commandes") ? 'nav-link active' : 'nav-link'} onClick={()=>this.handleNavItemClicked("/commandes")}>
                                        <i className="nav-icon fas fa-cart-arrow-down"></i>
                                        <p> Commandes </p>
                                    </Link>
                                </li>}
                                {permissions.commandes === '1' &&
                                <li className="nav-item">
                                    <Link to={`${baseUrl}/entrees`} className={(active === "/entrees") ? 'nav-link active' : 'nav-link'} onClick={()=>this.handleNavItemClicked("/entrees")}>
                                        <i className="nav-icon fas fa-angle-double-down"></i>
                                        <p> Entrées en stock </p>
                                    </Link>
                                </li>}
                                {permissions.stock === '1' &&
                                <li className="nav-item">
                                    <Link to={`${baseUrl}/stocks`} className={(active === "/stocks") ? 'nav-link active' : 'nav-link'} onClick={()=>this.handleNavItemClicked("/stocks")}>
                                        <i className="nav-icon fas fa-boxes"></i>
                                        <p> Etat du stock </p>
                                    </Link>
                                </li>}
                                {/* {permissions.precommandes === '1' &&
                                <li className="nav-item">
                                    <Link to={`${baseUrl}/precommandes`} className={(active === "/precommandes") ? 'nav-link active' : 'nav-link'} onClick={()=>this.handleNavItemClicked("/precommandes")}>
                                        <i className="nav-icon fas fa-cart-arrow-down"></i>
                                        <p> Commandes XX </p>
                                    </Link>
                                </li>} */}
                                {permissions.ventes === '1' &&
                                <li className="nav-item">
                                    <Link to={`${baseUrl}/sorties`} className={(active === "/sorties") ? 'nav-link active' : 'nav-link'} onClick={()=>this.handleNavItemClicked("/sorties")}>
                                        <i className="nav-icon fas fa-angle-double-up"></i>
                                        <p> Ventes </p>
                                    </Link>
                                </li>}
                                {permissions.devis === '1' &&
                                <li className="nav-item">
                                    <Link to={`${baseUrl}/devis`} className={(active === "/devis") ? 'nav-link active' : 'nav-link'} onClick={()=>this.handleNavItemClicked("/devis")}>
                                        <i className="nav-icon fas fa-briefcase"></i>
                                        <p> Dévis/Facture proforma </p>
                                    </Link>
                                </li>}
                                {permissions.bilan === '1' &&
                                <li className="nav-item">
                                    <Link to={`${baseUrl}/bilan`} className={(active === "/bilan") ? 'nav-link active' : 'nav-link'} onClick={()=>this.handleNavItemClicked("/bilan")}>
                                        <i className="nav-icon fas fa-history"></i>
                                        <p> Résultats </p>
                                    </Link>
                                </li>}
                                
                                {permissions.traces === '1' &&
                                <li className="nav-item">
                                    <Link to={`${baseUrl}/traces`} className={(active === "/traces") ? 'nav-link active' : 'nav-link'} onClick={()=>this.handleNavItemClicked("/traces")}>
                                        <i className="nav-icon fas fa-shoe-prints"></i>
                                        <p> Traces </p>
                                    </Link>
                                </li>}
                                
                                {permissions.parametres === '1' &&
                                <li className="nav-item">
                                    <Link to={`${baseUrl}/parametres`} className={(active === "/parametres") ? 'nav-link active' : 'nav-link'} onClick={()=>this.handleNavItemClicked("/parametres")}>
                                        <i className="nav-icon fas fa-cogs"></i>
                                        <p> Paramètres </p>
                                    </Link>
                                </li>}
                            </ul>}
                        </nav>
                    </div>
                </aside>
            </>
      );
    }
}
