import React from 'react';
import NavBar from './components/NavBar';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Footer from './components/Footer';
import Precommande from './pages/Precommandes';
import Bilan from './pages/Bilan';
import Traces from './pages/Traces';
import Utilisateurs from './pages/Utilisateurs';
import Profil from './pages/Profil';
import Expired from './pages/Expired';
import Subscribe from './pages/Subscribe';

import {
  ArticlesServices, BilansServices, CategoriesServices, EntreesServices, SortiesServices, UtilisateursServices, DevisServices, ConnexionServices,
  LoginServices, RolesServices, StocksServices, TracesServices, StatsServices, ClientsServices, FournisseursServices, DepensesServices, StructuresServices, PrecommandesServices, UnitesServices, DevisesServices, MagasinsServices
} from './services'

import { CentralStoresServices } from './services/central'
import './App.css';
import 'react-toastify/dist/ReactToastify.css'
import Categories from './pages/categories';
import Roles from './pages/Roles';
import AddPrecommande from './pages/Precommandes/Add';
import DetailPrecommande from './pages/Precommandes/Detail';
import AddDevis from './pages/Devis/new-old';
import Clients from './pages/Clients';
import Fournisseurs from './pages/Fournisseurs';
import Devis from './pages/Devis/home';
import Structure from './pages/Structure';
import DetailClient from './pages/Clients/Detail';
import DetailFournisseur from './pages/Fournisseurs/Detail';
import NotFound from './pages/not-found';
import Unites from './pages/Unites';

import { AchatPage, NewAchatPage, NewCommandePage, AchatDetailPage, CommandePage, CommandeDetailPage } from './pages/achat-commande';
import { Devise, Magasin } from './pages/setting';
import { StockPage } from './pages/stock';
import { NewVentePage, VenteDetailPage, VentePage } from './pages/vente';
import { ChargeFixePage } from './pages/charge-fixe';
import { ArticlePage } from './pages/articles';
import { DevisDetailPage, DevisPage, NewDevisPage } from './pages/Devis';

const App = ({ session }) => {
  const services = {
    article: new ArticlesServices(session),
    categorie: new CategoriesServices(session),
    client: new ClientsServices(session),
    depense: new DepensesServices(session),
    devis: new DevisServices(session),
    devise: new DevisesServices(session),
    magasin: new MagasinsServices(session),
    entree: new EntreesServices(session),
    fournisseur: new FournisseursServices(session),
    stat: new StatsServices(session),
    sortie: new SortiesServices(session),
    precommande: new PrecommandesServices(session),
    user: new UtilisateursServices(session),
    unite: new UnitesServices(session),
    login: new LoginServices(session),
    stock: new StocksServices(session),
    bilan: new BilansServices(session),
    trace: new TracesServices(session),
    role: new RolesServices(session),
    parametres: new LoginServices(session),
    structure: new StructuresServices(session),
    connexion: new ConnexionServices(session),
    //
    customer: new CentralStoresServices(session)
  }

  const username = session.user ? session.user['name'] : ''
  const role = session.user ? session.user['role'] : ''
  const permissions = session.user ? session.user['permissions'] ? JSON.parse(session.user['permissions']) : {} : {}
  const baseUrl = session.baseUrl ? `/${session.baseUrl}` : ''

  const routesAdmin = [
    { name: 'Accueil', path: `/`, props: { controller: services.stat, permission: permissions.dashboard }, component: permissions.dashboard === '1' ? Home : Expired },
    { name: 'Accueil', path: `/home`, props: { controller: services.stat, permission: permissions.dashboard }, component: permissions.dashboard === '1' ? Home : NotFound },

    { name: "Articles", path: `/articles`, props: { session, permission: permissions.produits }, component: ArticlePage },

    { name: "Entrées", path: `/entrees`, props: { session, permission: permissions.commandes }, component: AchatPage },
    { name: "Entrées", path: `/entrees-new`, props: { session, permission: permissions.commandes }, component: NewAchatPage },
    { name: "Entrées", path: `/entrees/:id`, props: { session, permission: permissions.commandes }, component: AchatDetailPage },

    { name: "Commandes", path: `/commandes`, props: { session, permission: permissions.commandes }, component: CommandePage },
    { name: "Commandes", path: `/commandes-new`, props: { session, permission: permissions.commandes }, component: NewCommandePage },
    { name: "Commandes", path: `/commandes/:id`, props: { session, permission: permissions.commandes }, component: CommandeDetailPage },

    { name: "Ventes", path: `/sorties`, props: { session, permission: permissions.ventes }, component: VentePage },
    { name: "Ventes", path: `/sorties-new`, props: { session, permission: permissions.ventes }, component: NewVentePage },
    { name: "Ventes", path: `/sorties/:id`, props: { session, permission: permissions.ventes }, component: VenteDetailPage },

    { name: "Commandes", path: `/precommandes`, props: { controller: services.precommande, permission: permissions.precommandes }, component: permissions.precommandes === '1' ? Precommande : NotFound },
    { name: "Commandes", path: `/precommandes-new`, props: { controller: services.precommande, articleController: services.article, clientController: services.client, permission: permissions.precommandes }, component: permissions.precommandes === '1' ? AddPrecommande : NotFound },
    { name: "Commandes", path: `/precommandes/:id`, props: { controller: services.precommande, permission: permissions.precommandes }, component: permissions.precommandes === '1' ? DetailPrecommande : NotFound },

    { name: "Dévis/Facture proforma", path: `/devis`, props: { session, permission: permissions.devis }, component: DevisPage },
    { name: "Dévis/Facture proforma", path: `/devis-new`, props: { session, permission: permissions.devis }, component: NewDevisPage },
    { name: "Dévis/Facture proforma", path: `/devis/:id`, props: { session, permission: permissions.ventes }, component: DevisDetailPage },

    { name: "Stock", path: `/stocks`, props: { session, permission: permissions.stock }, component: StockPage },
    { name: "Charges fixes", path: `/depenses`, props: { session, permission: permissions.depenses }, component: ChargeFixePage },
    { name: "Bilan", path: `/bilan`, props: { controller: services.bilan, statController: services.stat, permission: permissions.bilan }, component: permissions.bilan === '1' ? Bilan : NotFound },
    { name: "Traces", path: `/traces`, props: { controller: services.trace, permission: permissions.traces }, component: permissions.traces === '1' ? Traces : NotFound },

    // {name: "Utilisateurs", path: `/parametres`, props: { controller: services.user }, component: permissions.dashboard === '1' ? Utilisateurs },
    { name: "Parametres", path: `/parametres`, props: { controller: services.user, roleController: services.role, permission: permissions.parametres }, component: permissions.parametres === '1' ? Utilisateurs : NotFound },
    { name: "Parametres", path: `/roles`, props: { controller: services.role, permission: permissions.parametres }, component: permissions.parametres === '1' ? Roles : NotFound },
    { name: "Parametres", path: `/categories`, props: { controller: services.categorie, permission: permissions.parametres }, component: permissions.parametres === '1' ? Categories : NotFound },
    { name: "Parametres", path: `/unites`, props: { controller: services.unite, permission: permissions.parametres }, component: permissions.parametres === '1' ? Unites : NotFound },
    { name: "Parametres", path: `/clients`, props: { controller: services.client, permission: permissions.parametres }, component: permissions.parametres === '1' ? Clients : NotFound },
    { name: "Parametres", path: `/clients/:id`, props: { controller: services.sortie, permission: permissions.parametres }, component: permissions.parametres === '1' ? DetailClient : NotFound },
    { name: "Parametres", path: `/fournisseurs`, props: { controller: services.fournisseur, permission: permissions.parametres }, component: permissions.parametres === '1' ? Fournisseurs : NotFound },
    { name: "Parametres", path: `/fournisseurs/:id`, props: { controller: services.entree, permission: permissions.parametres }, component: permissions.parametres === '1' ? DetailFournisseur : NotFound },
    { name: "Parametres", path: `/magasins`, props: { controller: services.magasin, permission: permissions.parametres }, component: permissions.parametres === '1' ? Magasin : NotFound },
    { name: "Parametres", path: `/devises`, props: { controller: services.devise, permission: permissions.parametres }, component: permissions.parametres === '1' ? Devise : NotFound },
    { name: "Parametres", path: `/structure`, props: { controller: services.structure, permission: permissions.parametres }, component: permissions.parametres === '1' ? Structure : NotFound },
    { name: "Profil", path: `/profil`, props: { controller: services.user }, component: Profil },
    { name: "", path: `/expire`, props: {}, component: Expired },
    { name: "", path: `/*`, props: {}, component: NotFound },
  ]

  // console.log("window.location.pathname.split('/')[1]", window.location.pathname.split('/')[1])
  // console.log("baseUrl", session)
  // console.log("permissions.dashboard", permissions.dashboard)

  if (session.org === null) {
    return <Subscribe controller={services.connexion} customerController={services.customer} />
  } else {
    if (`/${window.location.pathname.split('/')[1]}` !== baseUrl) {
      return window.location = permissions.dashboard === '0' ? `${baseUrl}/profil` : baseUrl
    } else {
      if (window.location.pathname === baseUrl && session.user === null) {
        return <Login controller={services.login} />
      } else {
        return (
          <div className="wrapper">
            <Router>
              <NavBar username={username} role={role} permissions={permissions} baseUrl={baseUrl} expired={new Date().getTime() > new Date(session.org.fin).getTime() ? true : false} />
              <div className="content-wrapper" style={{ marginBottom: 54 }}>
                <Switch>
                  <Route path="/home">
                    <Redirect to={`${baseUrl}/home`} />
                  </Route>
                  {routesAdmin.map((r, i) => {
                    return (
                      <Route exact path={`${baseUrl}${r.path}`} key={i} render={(props) => React.createElement(r.component, { ...r.props, ...props, pageName: r.name }, null)} />
                    )
                  })
                  }
                </Switch>
              </div>
              <Footer />
            </Router>
          </div>
        );
      }
    }
  }
}
export default App