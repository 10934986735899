import _classCallCheck from "@babel/runtime/helpers/classCallCheck";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import axios from 'axios';
//import get from 'lodash.get';

var HttpClient = function HttpClient(_props) {
  var _this = this;

  _classCallCheck(this, HttpClient);

  _defineProperty(this, "call", function (method, url, props) {
    var _ref = props || {},
        baseURL = _ref.baseURL,
        data = _ref.data,
        bearer = _ref.bearer,
        dbname = _ref.name,
        currentId = _ref.currentId,
        timeout = _ref.timeout,
        responseType = _ref.responseType;

    var headers = (props || {}).headers || {};

    if (bearer) { headers['Authorization'] = 'Bearer ' + bearer }
    if (dbname) { headers['name'] = dbname }
    if (currentId) { headers['x-nox-current'] = currentId }

    return axios({
      method: method,
      baseURL: baseURL,
      url: url,
      responseType: responseType, 
      data: data,
      timeout: timeout || 20000000, //33mn //5s
      headers: headers
    }).then(function (_ref2) {
      var data = _ref2.data;
      if(responseType === 'blob'){
        // var filename = _ref2.headers['content-disposition'].split(';')[1].split('="')[1]
        // filename = filename.substring(0, filename.length - 1)
        var filename = _ref2.headers['content-disposition'].split(';')[1].split('=')[1]
        return { data: _ref2.data, filename }
      }else{
        return data;
      }
    }).catch(function (err) {
      //var message = get(err, 'response.data.message') || get(err, 'response.message') || get(err, 'response.data') || get(err, 'message');
      throw new Error(err);
    });
  });

  _defineProperty(this, "post", function (url, props) {
    // console.log("props===> ", props)
    return _this.call('POST', url, props);
  });

  _defineProperty(this, "get", function (url, props) {
    // console.log("props===> ", props)
    // console.log("url===> ", url)
    return _this.call('GET', url, props);
  });

  _defineProperty(this, "delete", function (url, props) {
    return _this.call('DELETE', url, props);
  });

  _defineProperty(this, "upload", function (url, props) {
    var formData = new FormData();

    // console.log(props.body)

    Object.keys(props.body).map(function(key, index) {
      formData.append(key, props.body[key])
    })
    formData.append('logo',props.file)
    const config = {
        headers: {
          'content-type': 'multipart/form-data',
          'x-nox-current': props.currentId,
          'name': props.name
        }
    }
    return  axios.post(url, formData, config)
  });

  this.props = _props;
};

export { HttpClient as default };