const handlebars = require("handlebars");
const { formatNumber } = require("../lib/functions");

export const generatePdf = async (data, fileNom) => {
    try {
        const { file } = require(`../templates/${fileNom}`)
        const now = new Date()

        try {
            handlebars.registerHelper('numberFormater', formatNumber)
            handlebars.registerHelper('inc', function(nbre){ return nbre + 1 })
            handlebars.registerHelper('numberFormater2', function(qte, prx){ return formatNumber((qte*prx))})
            handlebars.registerHelper('multiple', function(first, second){ return first * second })
            handlebars.registerHelper('imprimerDate', function(){
                return `${("0" + now.getDate()).slice(-2)}/${("0" + (now.getMonth()+1)).slice(-2)}/${now.getFullYear()}`
            })
            handlebars.registerHelper('htaxeCommande', function(data){
                let sum = 0
                for(let i=0; i<data.length; i++){ sum = sum + (data[i]['pachat'] * data[i]['quantite']) }
                return formatNumber(sum)
            })
            handlebars.registerHelper('tva', function(first, data){ 
                let sum = 0
                for(let i=0; i<data.length; i++){ sum = sum + (data[i]['pachat'] * data[i]['quantite']) }
                return formatNumber((first-sum).toFixed(2))
            })
            handlebars.registerHelper('htaxeVente', function(data){
                let sum = 0
                for(let i=0; i<data.length; i++){ sum = sum + (data[i]['pvente'] * data[i]['quantite']) }
                return sum
            })
            handlebars.registerHelper('tvaVente', function(first, data){ 
                let sum = 0
                for(let i=0; i<data.length; i++){ sum = sum + (data[i]['pvente'] * data[i]['quantite']) }
                return formatNumber((first-sum).toFixed(2))
            })
            const template = handlebars.compile(file.toString())
            const html = template(data)

            return html

        } catch (error) {
            console.log("error generating Pdf", error);
        }
    } catch (error) {
        console.log("error generating Pdf", error);
    }
};