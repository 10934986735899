export default {

   login: (user) => {
      const store = {
         id: user['id'],
         name: user['nom'],
         username: user['login'],
         role: user['libelle'],
         permissions: user['permissions'],
         password: user['password'],
      }

      localStorage.setItem('$sf_user', JSON.stringify(store))
      localStorage.setItem('$sf_devise', user['devise'])
   },
   structure: (str) => {
      const store = {
         nom: str['nom'],
         email: str['email'],
         telephone: str['telephone'],
         logo: str['logo'],
         logoPath: str['logoPath'],
         adresse: str['adresse'],
      }

      localStorage.setItem('$sf_structure', JSON.stringify(store))
   },

   config: (org) => {
      const store = {
         id: org['id'],
         name: org['dbname'],
         productkey: org['productkey'],
         debut: org['debut'],
         fin: org['fin'],
      }
      localStorage.setItem('$sf_org', JSON.stringify(store))
      localStorage.setItem('$sf_baseUrl', org['_id'])
   },

   store: () => {
      const session = {
         user: JSON.parse(localStorage.getItem('$sf_user')),
         org: JSON.parse(localStorage.getItem('$sf_org')),
         devise: localStorage.getItem('$sf_devise'),
         baseUrl: localStorage.getItem('$sf_baseUrl')
      }
      return session
   },

   get: (item) => JSON.parse(localStorage.getItem(item)),
   getString: (item) => localStorage.getItem(item),
   remove: (item) => localStorage.removeItem(item),
   put: (item, value) => localStorage.setItem(item, value),
   reset: () => localStorage.clear(),
}