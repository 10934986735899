import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import Head from '../../components/Head'
import SearchInput from '../../components/Search'
import Table from '../../components/Table'
import { formatDateComplete } from '../../lib/functions'

const columns = [
    { name: 'action', header: 'Action' },
    { name: 'nom', header: 'Executeur' },
    { name: 'description', header: 'Description' },
    { name: 'date', header: 'Date', date: 'date-complete' },
]

export default class Articles extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            data: [],
            tempData: [],
            search: '',
            load: true
        }
    }

    handleTableSearch = value =>{
        const data = this.state.tempData.filter(td => {
            return (
                td?.action.toLowerCase().includes(value.toLowerCase()) ||
                td?.nom.toLowerCase().includes(value.toLowerCase()) ||
                td?.description.toLowerCase().includes(value.toLowerCase()) ||
                formatDateComplete(td?.date).toLowerCase().includes(value.toLowerCase())
            )
        })
        this.setState({ data, search: value })
    }

    componentDidMount(){
        this.props.controller.fetch()
        .then(data=>{ 
            if(!data.error && typeof(data.pror) === 'undefined'){
                this.setState({ data, tempData: data, load: false }) 
            } else{ toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite."); this.setState({ load: false }) }
        })
        .catch(()=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ load: false }) })
    }

    render(){
        const { pageName } = this.props
        const { data, load } = this.state
        return(
            <>
                <Head name={pageName}  />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <ToastContainer />
                            <div className="col-12">
                                <div className="card">
                                    <div className="nx-card-header">
                                        <h3 className="nx-card-title">La liste des articles</h3>
                                    </div>

                                    <div className="nx-card-body card-body">
                                        <SearchInput value={this.state.search} onChange={(val)=>this.handleTableSearch(val)} />
                                        <Table columns={columns} data={data} load={load}  permission='0' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}