import React from 'react'
import ReactModal from 'react-modal'
import { ToastContainer, toast } from 'react-toastify'
import Session from '../../session'
import { generatePdf } from '../print.controller'
import Head from '../../components/Head'
import Spinner from '../../components/Spinner'
import { formatDate, formatDateStrToDateComplete, formatNumber } from '../../lib/functions'

const initialState = {
    popLoad: false,
}

const paragraphe = { marginLeft: 8, background: '#fff', zIndex: 1, position: 'relative', display: 'inline-block', padding: '0px 8px', fontSize: 11 }
const row = { margin: 0, border: '1px solid #ddd', paddingTop: 24, borderRadius: 8, top: -24, position: 'relative' }
const initialPaiementState = {
    montantPaiement: 0,
    montantPaiementEdit: 0,
    currentPaiementId: null,
    modal: false,
    modalDelete: false,
    modalQuantity: false,
    modalShip: false,
    currentDetailId: '',
    currentDetailCommandeId: '',
    currentQte: 0,
    currentQteLivree: 0,
    currentQteDetail: 0,
    currentQteRestante: 0,
    action: ''
}

export default class CommandeDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            paiements: [],
            commande: {},
            totalSansTva: 0,
            ...initialState,
            ...initialPaiementState,
            load: true,
            currentPaiementReliquat: 0, montantPaye: 0,
        }
        this.handlePaiementSubmitSave = this.handlePaiementSubmitSave.bind(this)
        this.handlePaiementSubmitUpdate = this.handlePaiementSubmitUpdate.bind(this)
        this.handleQuantityShip = this.handleQuantityShip.bind(this)
    }

    calculMontant(data) {
        let sum = 0
        for (let i = 0; i < data.length; i++) { sum = sum + (data[i]['pu'] * data[i]['quantite']) }
        return sum
    }
    calculTotalPaye = (data) => {
        let total = 0;
        for (let i = 0; i < data.length; i++) { total += data[i]['montant'] }
        return total
    }
    handlePaiementDeleteOpen(id) {
        const current = this.state.paiements.find(p => p.id === id)
        if (current) this.setState({ modalDelete: true, currentPaiementId: id })
        else toast.error("Une erreur inattendue s'est produite, veuillez reesayer!")
    }
    handlePaiementEditOpen(id) {
        const current = this.state.paiements.find(p => p.id === id)
        if (current) this.setState({ montantPaiement: current.montant, montantPaiementEdit: current.montant, modal: true, action: 'update', currentPaiementId: id })
        else toast.error("Une erreur inattendue s'est produite, veuillez reesayer!")
    }

    gen(data) {
        generatePdf(data, 'entrees')
            .then(html => {
                var temp = document.createElement('div');
                temp.innerHTML = html;
                var pri = document.getElementById("ifmcontentstoprint").contentWindow;
                pri.document.open();
                pri.document.write(temp.innerHTML);
                pri.print();
                this.setState({ popLoad: false })
                pri.document.close();
            }).catch(err => console.log(err))
    }

    handlePrint() {
        this.setState({ popLoad: true })
        const store = Session.get('$sf_structure')
        this.gen({ commande: this.state.commande, details: this.state.data, store })
    }

    fetch = () => {
        const id = this.props.match.params.id
        this.props.controller.detail(id)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    const totalSansTva = this.calculMontant(res.details)
                    const montantPaye = this.calculTotalPaye(res.paiements)
                    const currentPaiementReliquat = res.commande?.montant - montantPaye
                    this.setState({ commande: res.commande, paiements: res.paiements, data: res.details, totalSansTva, currentPaiementReliquat, montantPaye })
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
            })
            .catch(() => toast.error("Une erreur inattendue s'est produite."))
    }

    componentDidMount = () => this.fetch()
    handleChange(e, name) { this.setState({ [name]: e.target.value }) }

    handlePaiementSubmitSave(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { commande, montantPaiement } = this.state
        const data = { montant: montantPaiement, achat_id: commande.id }

        this.props.controller.savePaiement(data)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ ...initialPaiementState, popLoad: false })
                    toast.success(res.message)
                    this.fetch()
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
    }
    handlePaiementSubmitUpdate(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        // montantPaiement: 0, currentPaiementId: null,
        const { montantPaiement, currentPaiementId } = this.state
        const data = { montant: montantPaiement }
        if (currentPaiementId) {
            this.props.controller.updatePaiement(currentPaiementId, data)
                .then(res => {
                    if (!res.error && typeof (res.pror) === 'undefined') {
                        this.setState({ ...initialPaiementState, popLoad: false })
                        toast.success(res.message)
                        this.fetch()
                    } else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
                })
                .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
        }
    }

    handlePaiementSubmitDelete() {
        this.setState({ popLoad: true })
        const { currentPaiementId } = this.state
        if (currentPaiementId) {
            this.props.controller.deletePaiement(currentPaiementId)
                .then(res => {
                    if (!res.error && typeof (res.pror) === 'undefined') {
                        this.setState({ ...initialPaiementState, popLoad: false })
                        toast.success(res.message)
                        this.fetch()
                    } else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
                })
                .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
        }
    }
    handleOpenShip = (id) => {
        const current = this.state.data.find(d => d.id === parseInt(id))
        if (current) this.setState({
            currentQteDetail: current.quantite,
            currentQteLivree: current.quantite_livree,
            currentQteRestante: current.quantite_restante,
            modalQuantity: true,
            currentDetailId: id,
            currentDetailCommandeId: current.achat_id
        })
        else toast.error("Une erreur inattendue s'est produite, veuillez reesayer!")
    }

    handleQuantityShip = (e) => {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { currentQte, currentDetailId, currentQteDetail, currentQteRestante, currentQteLivree, currentDetailCommandeId } = this.state
        const data = {
            id: currentDetailId,
            quantiteLivree: parseFloat(currentQteLivree) + parseFloat(currentQte),
            quantiteRestante: parseFloat(currentQteRestante) + parseFloat(currentQte),
            quantite: currentQteDetail,
            achatId: currentDetailCommandeId
        }

        this.props.controller.shipQuantity(data)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ ...initialPaiementState, popLoad: false })
                    toast.success(res.message)
                    this.fetch()
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
    }

    handleShip() {
        this.setState({ popLoad: true })
        const { commande } = this.state
        if (commande.id) {
            this.props.controller.ship({ id: commande.id })
                .then(res => {
                    if (!res.error && typeof (res.pror) === 'undefined') {
                        this.setState({ ...initialPaiementState, popLoad: false })
                        toast.success(res.message)
                        this.fetch()
                    } else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
                })
                .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
        }
    }

    render() {
        const { pageName, devise } = this.props
        const { data, commande, totalSansTva, popLoad, paiements, modal, action, montantPaiement, modalDelete, montantPaiementEdit, currentPaiementReliquat, montantPaye,
            modalQuantity, modalShip, currentQte, currentQteDetail, currentQteLivree, } = this.state
        return (
            <>
                <Head name={pageName} />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <ToastContainer />
                            <div className="col-12">
                                <div className="card">
                                    <div className="nx-card-header">
                                        <button className="btn btn-link" style={{ float: 'inline-start' }} onClick={() => this.props.history.goBack()}>
                                            <i className="fa fa-arrow-left"></i>
                                        </button>
                                        <h3 className="nx-card-title">Une nouvelle entrée dans le stock</h3>
                                        <div style={{ float: 'right' }}>
                                            <button className="btn btn-link" style={{ padding: 0 }} onClick={() => this.handlePrint()}>
                                                <i className="fas fa-print"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <p style={paragraphe}>Information sur le fournisseur et la date de la commande</p>
                                                <div className="row" style={{ ...row, marginRight: 8 }}>
                                                    <div className="col-sm-12">
                                                        <p><strong>Date de la commande : </strong>{formatDate(commande.date)}</p>
                                                        <p style={{ textAlign: 'center' }}><strong>FOURNISSEUR</strong></p>
                                                        <p style={{ marginBottom: 4 }}><strong>Nom : </strong>{commande.nom}</p>
                                                        <p style={{ marginBottom: 4 }}><strong>Société : </strong>{commande.societe}</p>
                                                        <p style={{ marginBottom: 4 }}><strong>Téléphone : </strong>{commande.telephone}</p>
                                                        <p style={{ marginBottom: 4 }}><strong>Email : </strong>{commande.email}</p>
                                                        <p style={{ marginBottom: 4 }}><strong>Adresse : </strong>{commande.adresse}</p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <p style={paragraphe}>Information sur le montant de la commande </p>
                                                        <div className="row" style={row}>
                                                            <div className="col-12">
                                                                <p style={{ marginBottom: 4 }}>Montant de la commande HT : <strong>{`${totalSansTva} ${devise}`}</strong></p>
                                                                <p style={{ marginBottom: 4 }}>{`Montant de la commande TVA ${commande.tva === '0' ? '' : '(' + commande.tva + '%)'} :`} <strong>{commande.tva === '0' ? 'pas de TVA appliquée' : `${(commande.montant - totalSansTva).toFixed(2)} ${devise}`}</strong></p>
                                                                <p style={{ marginBottom: 4 }}>Coût d'acquisition TTC : <strong>{`${commande.montant} ${devise}`}</strong></p>
                                                                <p style={{ marginBottom: 4 }}>Montant payé : <strong>{`${formatNumber(montantPaye)} ${devise}`}</strong></p>
                                                                <p style={{ marginBottom: 4 }}>Reliquat : <strong>{`${formatNumber(currentPaiementReliquat)} ${devise}`}</strong></p>

                                                                {commande.etat === 'EN COURS' &&
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => this.setState({ modalShip: true })}
                                                                        style={{ padding: '4px 8px', marginBottom: 8 }}
                                                                        className="btn btn-primary">Marquer comme livrée
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <p style={paragraphe}>Information sur les charges </p>
                                                        <div className="row" style={row}>
                                                            <div className="col-12">
                                                                <p style={{ marginBottom: 4 }}>Autre charge : <strong>{`${formatNumber(commande.charge)} ${devise}`}</strong></p>
                                                                <p style={{ marginBottom: 4 }}>Motif : <strong>{commande.motif}</strong></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-12'>
                                                <p className="card-text" style={paragraphe}>Les informations sur le paiement. </p>
                                                <div className="row" style={row}>
                                                    <div className="table-responsive">
                                                        {currentPaiementReliquat > 0 &&
                                                            <button className="btn btn-link" style={{ marginBottom: 12, float: 'right' }} onClick={() => this.setState({ modal: true, action: 'save' })}>
                                                                Nouveau paiement
                                                            </button>}
                                                        <table className="table" id="datatable">
                                                            <thead>
                                                                <tr>
                                                                    <th>ID</th>
                                                                    <th>Montant</th>
                                                                    <th>Date</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {paiements.length > 0 &&
                                                                    paiements.map((d, i) => {
                                                                        return (
                                                                            <tr key={i}>
                                                                                <td>{`PMNT_FRS_${d.id}`}</td>
                                                                                <td>{`${formatNumber(d.montant)} ${devise}`}</td>
                                                                                <td>{formatDateStrToDateComplete(d.date)}</td>
                                                                                <td>
                                                                                    <button className="btn btn-link" onClick={() => this.handlePaiementEditOpen(d.id)}>
                                                                                        <i className="fas fa-pencil-alt"></i>
                                                                                    </button>
                                                                                    <button className="btn btn-link" onClick={() => this.handlePaiementDeleteOpen(d.id)}>
                                                                                        <i className="fas fa-trash-alt"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <p style={paragraphe}>Information sur la commande et les produits </p>
                                        <div className="row" style={row}>
                                            <div className="col-12 table-responsive">
                                                <table id="example1" className="table table-bordered table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Référence</th>
                                                            <th>Désignation</th>
                                                            <th>Expire le</th>
                                                            <th>PU</th>
                                                            <th>Qté cmd</th>
                                                            <th>Qté livrée</th>
                                                            <th>Montant</th>
                                                            <th>PU vte</th>
                                                            <th>Magasin</th>
                                                            <th>Livraison</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.length > 0 &&
                                                            data.map((d, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{i + 1}</td>
                                                                        <td>{d.reference}</td>
                                                                        <td>{d.designation}</td>
                                                                        <td>{formatDate(d.expire)}</td>
                                                                        <td>{`${formatNumber(d.pu)} ${devise}`}</td>
                                                                        <td>{`${formatNumber(d.quantite)} ${d.unite}`}</td>
                                                                        <td>{`${formatNumber(d.quantite_livree)} ${d.unite}`}</td>
                                                                        <td>{`${formatNumber(d.pu * d.quantite)} ${devise}`}</td>
                                                                        <td>{`${formatNumber(d.pu_vente)} ${devise}`}</td>
                                                                        <td>{d.store}</td>
                                                                        {/* <td>{d.livraison}</td> */}
                                                                        <td>
                                                                            {d.quantite > d.quantite_livree &&
                                                                                <button className="btn btn-link" onClick={() => this.handleOpenShip(d.id)}>
                                                                                    <i className="fas fa-truck"></i>
                                                                                </button>}
                                                                                {d.quantite <= d.quantite_livree && 'LIVRÉE'}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                                    <h5>Traitement ...</h5>
                                    <Spinner />
                                    <span className="breadcrumb-item active" style={{ display: 'block', textAlign: 'center' }}>
                                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                                    </span>
                                </ReactModal>

                                <ReactModal isOpen={modal} ariaHideApp={false}>
                                    <h5>{action === 'save' ? "Nouveau paiement" : "Modification du paiement"}</h5>

                                    <form method="POST" onSubmit={action === 'save' ? this.handlePaiementSubmitSave : this.handlePaiementSubmitUpdate}>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>Montant *</label>
                                                    <input type="number" min={5} value={montantPaiement} onChange={(e) => this.handleChange(e, 'montantPaiement')} className="form-control" placeholder="Montant *" required />
                                                </div>
                                                {action === 'save' && <p>Reliquat: {currentPaiementReliquat} {devise}</p>}
                                                {action === 'update' && <p>Reliquat: {currentPaiementReliquat + montantPaiementEdit} {devise}</p>}
                                            </div>
                                        </div>

                                        <div style={{ textAlign: 'end' }}>
                                            <button onClick={() => this.setState({ ...initialPaiementState })} type="button" className="btn btn-secondary">Annuler</button>
                                            {action === 'save' && montantPaiement <= currentPaiementReliquat && <button type="submit" name="valider" style={{ marginLeft: 8 }} className="btn btn-primary">Enregistrer</button>}
                                            {action === 'update' && montantPaiement <= currentPaiementReliquat + montantPaiementEdit && <button type="submit" name="valider" style={{ marginLeft: 8 }} className="btn btn-primary">Modifier</button>}
                                        </div>
                                    </form>
                                </ReactModal>

                                <ReactModal isOpen={modalDelete} ariaHideApp={false}>
                                    <h5>Suppresion du paiement</h5>
                                    <span className="card-text">
                                        {`Etes-vous sûr de vouloir supprimer ce paiement, cette action est irreversible.`}
                                    </span>

                                    <div style={{ textAlign: 'end', marginTop: 16 }}>
                                        <button onClick={() => this.setState({ ...initialPaiementState })} type="button" className="btn btn-secondary">Annuler</button>
                                        <button onClick={() => this.handlePaiementSubmitDelete()} style={{ marginLeft: 8 }} className="btn btn-primary">Supprimer</button>
                                    </div>
                                </ReactModal>

                                <ReactModal isOpen={modalShip} ariaHideApp={false}>
                                    <h5>Livraison de la commande</h5>
                                    <span className="card-text">
                                        Etes-vous sûr de vouloir marquer cette commande comme livrée.<br /> Attention, cette action est irreversible.
                                    </span>

                                    <div style={{ textAlign: 'end', marginTop: 16 }}>
                                        <button onClick={() => this.setState({ ...initialPaiementState })} type="button" className="btn btn-secondary">Annuler</button>
                                        <button onClick={() => this.handleShip()} style={{ marginLeft: 8 }} className="btn btn-primary">Valider</button>
                                    </div>
                                </ReactModal>

                                <ReactModal isOpen={modalQuantity} ariaHideApp={false}>
                                    <h5>Livraison d'une partie de la commande</h5>

                                    <form method="POST" onSubmit={this.handleQuantityShip}>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>Quantité livrée *</label>
                                                    <input
                                                        type="number"
                                                        min={1}
                                                        max={currentQteDetail - currentQteLivree}
                                                        value={currentQte}
                                                        onChange={(e) => this.handleChange(e, 'currentQte')}
                                                        className="form-control" placeholder="Quantité livrée"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ textAlign: 'end' }}>
                                            <button onClick={() => this.setState({ ...initialPaiementState })} type="button" className="btn btn-secondary">Annuler</button>
                                            <button type="submit" name="valider" style={{ marginLeft: 8 }} className="btn btn-primary">Enregistrer</button>
                                        </div>
                                    </form>
                                </ReactModal>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}