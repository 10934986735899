import Stock from './stock'
import { MagasinsServices, StocksServices } from '../../services'
import NotFound from '../not-found'



const StockPage = (props) => {
    const { permission, session } = props
    const controller = new StocksServices(session)
    const storeController = new MagasinsServices(session)


    const columns = [
        { name: 'designation', header: 'Désignation' },
        { name: 'quantiteRestante', header: 'Qté restante', suffix: '' },
        { name: 'unite', header: 'Unité' },
        { name: 'pu', header: "Prix unitaire d'achat", suffix: session.devise ? ` ${session.devise}` : ' F CFA'  },
        { name: 'store', header: 'Magasin' },
    ]
    
    const columnsAll = [
        { name: 'designation', header: 'Désignation' },
        { name: 'reference', header: 'Référence' },
        { name: 'quantiteRestante', header: 'Qté restante', suffix: '' },
        { name: 'unite', header: 'Unité' },
        { name: 'pu', header: "Prix unitaire d'achat", suffix: session.devise ? ` ${session.devise}` : ' F CFA'  },
        { name: 'expire', header: "Expire le", date: 'date-fr' },
        { name: 'store', header: 'Magasin' },
    ]

    if (permission === '1')
        return <Stock
            {...props}
            controller={controller}
            storeController={storeController}
            columns={columns}
            columnsAll={columnsAll}
            devise={session.devise ? session.devise : 'F CFA'}
        />
    else return <NotFound />
}


export { StockPage }