import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import Session from '../../session'
import Head from '../../components/Head'
import Spinner from '../../components/Spinner'
import Tab from '../../components/Tab'

export default class Structure extends React.Component{
    constructor(props){
        super(props)
        this.state = { nom: '', email: '', logo: '', logoPath: '', fichier: '', telephone: '', adresse: '', popLoad: false, load: true }
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this)
    }

    handleChange(e, name){
        if(name === 'logo'){
            this.setState({ fichier: e.target.files[0] });
        }else{
            this.setState({ [name]: e.target.value });
        }
    }

    handleSubmitUpdate(e){
        e.preventDefault()
        const { nom, email, logoPath, telephone, adresse, fichier, logo } = this.state
        const structure = { nom, email, logoPath, telephone, adresse }
        this.setState({ load: true })

        this.props.controller.update(fichier, structure)
            .then(res => {
                const data = res.data
                if(!data.error && typeof(data.pror) === 'undefined'){
                    if(typeof(data.logoPath) !== 'undefined'){
                        this.setState({logoPath: data.logoPath})
                        setTimeout(()=>{
                            const logo = this.imgToBase64(data.logoPath)
                            this.setState({logo: logo.length > 20 ? logo : '', load: false, fichier: ''})
                            Session.structure({nom, email, telephone, adresse, logo: logo.length > 20 ? logo : '', logoPath: data.logoPath})
                        }, 1000)
                    }else{
                        Session.structure({nom, email, logoPath, telephone, adresse, logo})
                        this.setState({load: false, fichier: ''})
                    }
                } else{ toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite."); this.setState({ load: false }) }
            })
            .catch(()=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ load: false }) })

        Session.structure(structure)
    }
    

    componentDidMount(){
        const structure = Session.get('$sf_structure')
        console.log("structure", structure)
        if(structure){
            this.setState({ ...structure, load: false })
        }else{
            this.props.controller.fetch()
            .then(data => { 
                if(!data.error && typeof(data.pror) === 'undefined'){
                    const { nom, email, telephone, adresse } = data
                    this.setState({nom, email, telephone, adresse, logoPath: data.logo})
                    setTimeout(()=>{
                        const logo = this.imgToBase64(data.logo)
                        this.setState({logo: logo.length > 20 ? logo : '', load: false})
                        Session.structure({nom, email, telephone, adresse, logo: logo.length > 20 ? logo : '', logoPath: data.logo})
                    }, 1000)
                } else{ toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite."); this.setState({ load: false }) }
            })
            .catch((err)=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ load: false }); console.log("err", err)  })
        }
    }

    imgToBase64(url) {
        let img = document.createElement('IMG')
        img.setAttribute("src", url);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        img.crossOrigin = 'anonymous';
        ctx.drawImage(img, 0, 0);
        return canvas.toDataURL();
    }
      

    render(){
        const { pageName } = this.props
        const { nom, email, logoPath, /*logo,*/ telephone, /*popLoad,*/ adresse, load } = this.state
        return(
            <>
                <Head name={pageName}  />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12"><Tab name="structure" /></div>
                            <ToastContainer />
                            <div className="col-12">
                                <div className="card">
                                    <div className="nx-card-header">
                                        <h3 className="nx-card-title">Information de la structure</h3>
                                    </div>
                                    
                                    <div className="card-body">
                                        {load && <Spinner />}
                                        <img src={`${logoPath}`} alt="logo" style={{width: 90, height: 90, borderRadius: 90, border: '1px solid #ddd'}} />
                                        <img src={logoPath} alt="logo" style={{display: 'none'}} />
                                        <form method="POST" onSubmit={this.handleSubmitUpdate} style={{marginTop: 24}}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <p style={{marginBottom: 0, fontSize: 12}}>Nom de la société</p>
                                                    <div className="form-group">
                                                        <input onChange={(e)=>this.handleChange(e, 'nom')} value={nom} type="text" className="form-control form-control-sm" placeholder="Nom de la société" required />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <p style={{marginBottom: 0, fontSize: 12}}>Email de la société</p>
                                                    <div className="form-group">
                                                        <input onChange={(e)=>this.handleChange(e, 'email')} value={email} type="text" className="form-control form-control-sm" placeholder="Email de la société" required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <p style={{marginBottom: 0, fontSize: 12}}>Logo de la société</p>
                                                    <div className="form-group">
                                                        <input onChange={(e)=>this.handleChange(e, 'logo')} accept="image/png, image/jpeg, image/jpg" type="file" className="form-control form-control-sm" placeholder="Logo de la société" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <p style={{marginBottom: 0, fontSize: 12}}>Téléphone de la société</p>
                                                    <div className="form-group">
                                                        <input onChange={(e)=>this.handleChange(e, 'telephone')} value={telephone} type="text" className="form-control form-control-sm" placeholder="Téléphone de la société" required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <p style={{marginBottom: 0, fontSize: 12}}>Adresse de la société</p>
                                                    <div className="form-group">
                                                        <textarea onChange={(e)=>this.handleChange(e, 'adresse')} value={adresse} type="textarea" className="form-control form-control-sm" placeholder="Adresse de la société" required rows="3"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            {!load && <button type="submit" name="valider" className="btn btn-primary">Modifier</button>}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}