import { useState } from "react"

const Accordion = (props) => {
    const [expanded, setExpanded] = useState(false)
  
    return (
      <div className='question' style={{ border: '1px solid #ddd', borderRadius: 6, marginBottom: 16 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0px 16px', alignItems: 'center', }}>
          <h4 onClick={() => setExpanded(!expanded)} className='question-title' style={{margin: 0, fontSize: 12}}>
            {props.title}
          </h4>
          <button className='btn' onClick={() => setExpanded(!expanded)}>
            {expanded ? <i className="fas fa-angle-down"></i> : <i className="fas fa-angle-up"></i>}
          </button>
        </div>
        {expanded && <div className="table-responsive">{props.children}</div>}
      </div>
    )
  }
  
  export default Accordion