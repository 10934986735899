import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import Head from '../../components/Head'
import Tab from '../../components/Tab'
import ReactModal from 'react-modal'
import Spinner from '../../components/Spinner'
import Table from '../../components/Table'
import SearchInput from '../../components/Search'
import session from '../../session'

const initialState = {
    libelle: '',
    symbole: '',
    currentId: '',
    popLoad: false,
    modal: false,
    modalDelete: false
}

const columns = [
    { name: 'libelle', header: 'Libellé' },
    { name: 'symbole', header: 'Symbole' }
]

export default class Devise extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            tempData: [],
            search: '',
            load: true,
            ...initialState,
        }
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this)
    }

    handleChange(e, name) { this.setState({ [name]: e.target.value }) }
    handleEditOpen(id) {
        const model = this.state.data.find(d => d.id === id)
        if (model) this.setState({ ...model, modal: true, action: 'update', currentId: id, popLoad: false })
    }

    handleSubmitUpdate(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { currentId, libelle, symbole } = this.state
        const data = { id: currentId.toString(), libelle, symbole }
        if (currentId) {
            this.props.controller.save(data)
                .then(res => {
                    if (!res.error && typeof (res.pror) === 'undefined') {
                        this.setState({ ...initialState, load: true, popLoad: false })
                        session.put("$sf_devise", symbole)
                        toast.success(res.message)
                        this.fetch()
                    } else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
                })
                .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
        }
    }

    handleTableSearch = value => {
        const data = this.state.tempData.filter(td => td?.libelle.toLowerCase().includes(value.toLowerCase()))
        this.setState({ data, search: value })
    }

    componentDidMount = () => this.fetch()

    fetch = () => {
        this.props.controller.fetch()
            .then(data => {
                if (!data.error && typeof (data.pror) === 'undefined') {
                    this.setState({ data, tempData: data, load: false })
                } else { toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite."); this.setState({ load: false }) }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ load: false }) })
    }

    render() {
        const { pageName } = this.props
        const { data, modal, symbole, libelle, load, popLoad } = this.state

        return (
            <>
                <Head name={pageName} />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12"><Tab name="devises" /></div>
                            <ToastContainer />
                            <div className="col-12">
                                <div className="card">
                                    <div className="nx-card-header">
                                        <h3 className="nx-card-title">La liste des dévises</h3>
                                    </div>

                                    <div className="nx-card-body card-body">
                                        <SearchInput value={this.state.search} onChange={(val) => this.handleTableSearch(val)} />
                                        <Table
                                            columns={columns}
                                            data={data}
                                            load={load}
                                            printable={false}
                                            detaillable={false}
                                            deletable={false}
                                            onEdit={(id) => this.handleEditOpen(id)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <ReactModal isOpen={modal} ariaHideApp={false}>
                                    <h5>Modification de la dévise</h5>
                                    <form method="POST" onSubmit={this.handleSubmitUpdate}>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>Libellé *</label>
                                                    <input
                                                        name="libelle"
                                                        value={libelle}
                                                        onChange={(e) => this.handleChange(e, 'libelle')}
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        placeholder="Libellé"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>Symbole *</label>
                                                    <input
                                                        name="symbole"
                                                        value={symbole}
                                                        onChange={(e) => this.handleChange(e, 'symbole')}
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        placeholder="Symbole"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ textAlign: 'end' }}>
                                            <button onClick={() => this.setState({ ...initialState })} type="button" className="btn btn-secondary">Annuler</button>
                                            <button type="submit" style={{ marginLeft: 8 }} className="btn btn-primary">Modifier</button>
                                        </div>
                                    </form>
                                </ReactModal>

                                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                                    <h5>Traitement ...</h5>

                                    <Spinner />
                                    <span className="breadcrumb-item active" style={{ display: 'block', textAlign: 'center' }}>
                                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                                    </span>

                                </ReactModal>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}