import React from 'react'
import { Link } from 'react-router-dom'
import ReactModal from 'react-modal'
import { ToastContainer, toast } from 'react-toastify'
import Head from '../../components/Head'
import Spinner from '../../components/Spinner'
import Session from '../../session'
import { generatePdf } from '../print.controller'
import Table from '../../components/Table'
import { formatDate } from '../../lib/functions'
import SearchInput from '../../components/Search'

const initialState = {
    article_id: '',
    // for edit
    libelle: '',
    editArticleId: '',
    //===========
    reference: '',
    pachat: '',
    pvente: '',
    quantite: '',
    client: '',
    date: '',
    action: '',
    currentId: '',
    popLoad: false,
    modal: false,
    modalDelete: false
}

const columns = [
    { name: 'id', header: 'id', prefix: 'CMD_' },
    { name: 'montant', header: 'Montant', suffix: ' F cfa' },
    { name: 'tva', header: 'TVA', suffix: ' %' },
    { name: 'date', header: 'Date', date: 'date-fr' },
    { name: 'nom', header: 'Nom Clt.' },
    { name: 'paye', header: 'Montant payé', suffix: ' F cfa' },
    { name: 'reliquat', header: 'Reste à payer', suffix: ' F cfa' },
    { name: 'statut', header: 'Statut', payStat: true },
]

export default class Precommande extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            tempData: [],
            search: '',
            articleOptions: [],
            load: true,
            ...initialState,
        }
        this.handleSubmitSave = this.handleSubmitSave.bind(this)
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this)
    }

    handleChange(e, name) {
        if (name === 'article_id') {
            const article = this.state.articleOptions.find(a => a.id == e.target.value)
            if (typeof (article) !== 'undefined') {
                this.setState({ [name]: e.target.value, reference: article.reference, pachat: article.pachat, pvente: article.pvente })
            }
        } else {
            this.setState({ [name]: e.target.value })
        }
    }
    handleEditOpen(id) {
        this.setState({ popLoad: true })
        this.props.controller.findById(id)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ ...res[0], editArticleId: res[0].article_id, modal: true, action: 'update', currentId: id, popLoad: false })
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
    }
    handlePaiementOpen(id) {
        const current = this.state.data.find(d => d.id === id || d._id === id)
        if (current) {
            if (parseInt(current.reliquat) > 0) this.setState({ modalPaiement: true, currentId: current.id, currentPaiementReliquat: current.reliquat })
            else toast.info("Cette commande a déjà été réglé !")
        }
        else toast.error("Une erreur inattendue s'est produite, veuillez reesayer!")
    }

    handleDeleteOpen(id) {
        if (id) { this.setState({ modalDelete: true, currentId: id }) }
        else { toast.error("Une erreur inattendue s'est produite.") }
    }

    handleSubmitSave(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { article_id, quantite, client, date } = this.state
        const data = { article_id, quantite: parseInt(quantite), client, date, type: 'out' }

        this.props.controller.save(data)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ ...initialState })
                    this.componentDidMount()
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
    }

    handleSubmitUpdate(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { currentId, article_id, quantite, client, date } = this.state
        const data = { id: currentId.toString(), article_id, quantite: parseInt(quantite), client, date, type: 'out' }
        if (currentId) {
            this.props.controller.save(data)
                .then(res => {
                    if (!res.error && typeof (res.pror) === 'undefined') {
                        this.setState({ ...initialState, modal: false })
                        this.componentDidMount()
                    }
                    else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
                })
                .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
        }
    }

    handleDelete() {
        this.setState({ popLoad: true })
        const { currentId } = this.state
        if (currentId) {
            this.props.controller.remove(currentId)
                .then(res => {
                    if (!res.error && typeof (res.pror) === 'undefined') {
                        this.setState({ ...initialState })
                        toast.success(res.message)
                        this.componentDidMount()
                    }
                    else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState }) }
                })
                .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState }) })
        }
    }

    gen(data) {
        generatePdf(data, 'precommandes')
            .then(html => {
                var temp = document.createElement('div');
                temp.innerHTML = html;
                var pri = document.getElementById("ifmcontentstoprint").contentWindow;
                pri.document.open();
                pri.document.write(temp.innerHTML);
                pri.print();
                pri.document.close();
            }).catch(err => console.log(err))
    }

    handlePrintOne(id) {
        this.setState({ popLoad: true })
        const store = Session.get('$sf_structure')

        this.props.controller.detail(id)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ ...initialState })
                    this.gen({ ...res, store })
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState }) }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState }) })
    }

    handleFinish(id) {
        this.setState({ popLoad: true })
        const current = this.state.data.find(d => d._id === id || d.id === id)
        if (current) {
            if (parseInt(current.statut) !== 1) {
                this.props.controller.valider(id)
                    .then(res => {
                        if (!res.error && typeof (res.pror) === 'undefined') {
                            toast.success(res.message)
                            this.setState({ popLoad: false })
                            this.componentDidMount()
                        }
                        else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState }) }
                    })
                    .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState }) })
            } else { toast.info("Cette commande a déjà été validé."); this.setState({ popLoad: false }) }
        }
    }

    getStatus = (st) => st.toString === '1' ? 'valider' : 'en cours'
    handleTableSearch = value => {
        const data = this.state.tempData.filter(td => {
            return (
                td?.id.toString().toLowerCase().includes(value.toLowerCase()) ||
                td?.montant.toString().toLowerCase().includes(value.toLowerCase()) ||
                td?.tva.toLowerCase().includes(value.toLowerCase()) ||
                formatDate(td?.date).toLowerCase().includes(value.toLowerCase()) ||
                td?.nom.toLowerCase().includes(value.toLowerCase()) ||
                td?.paye.toString().toLowerCase().includes(value.toLowerCase()) ||
                td?.reliquat.toString().toLowerCase().includes(value.toLowerCase()) ||
                this.getStatus(td?.statut).includes(value.toLowerCase())
            )
        })
        this.setState({ data, search: value })
    }


    componentDidMount() {
        this.props.controller.fetch()
            .then(data => {
                if (!data.error && typeof (data.pror) === 'undefined') {
                    this.setState({ data, tempData: data, load: false })
                } else { toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite."); this.setState({ load: false }) }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ load: false }) })
    }

    render() {
        const { pageName, history } = this.props
        const { data, modalDelete, load, popLoad } = this.state
        return (
            <>
                <Head name={pageName} />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <ToastContainer />
                            <div className="col-12">
                                <div className="card">
                                    <div className="nx-card-header">
                                        <h3 className="nx-card-title">La liste des commandes</h3>
                                        <div className="btn-group btn-group-sm" role="group" aria-label="" style={{ float: 'right' }}>
                                            <Link type="button" className="btn btn-success" to="precommandes-new"
                                            // onClick={() => this.setState({ modal: true, action: 'save' })}
                                            >
                                                Ajouter
                                            </Link>
                                            {/* <button  type="button" className="btn btn-secondary" onClick={()=>this.handlePrintAll()}>Imprimer</button> */}
                                        </div>
                                    </div>

                                    <div className="nx-card-body card-body">
                                        <SearchInput value={this.state.search} onChange={(val) => this.handleTableSearch(val)} />
                                        <Table
                                            columns={columns}
                                            data={data}
                                            load={load}
                                            countable={false}
                                            editable={false}
                                            hasCustomItem={true}
                                            customItemLabel='Valider'
                                            customItemIcon='fas fa-check'
                                            onView={(id) => history.push(`precommandes/${id}`)}
                                            onPrint={(id) => this.handlePrintOne(id)}
                                            onCustomItemAction={(id) => this.handleFinish(id)}
                                            // onDelete={(id) => this.handleDeleteOpen(id)}
                                            onEdit={(id) => this.handleEditOpen(id)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">

                                <ReactModal isOpen={modalDelete} ariaHideApp={false}>
                                    <h5>Suppresion de l'article</h5>

                                    <span className="breadcrumb-item active">
                                        {`Etes-vous sûr de vouloir supprimer cette sortie, cette action est irreversible et entrainera sa suppression dans le stock et sortie.`}
                                    </span>

                                    <div style={{ textAlign: 'end', marginTop: 16 }}>
                                        <button onClick={() => this.setState({ ...initialState })} type="button" className="btn btn-secondary">Annuler</button>
                                        <button onClick={() => this.handleDelete()} style={{ marginLeft: 8 }} className="btn btn-primary">Supprimer</button>
                                    </div>
                                </ReactModal>

                                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                                    <h5>Traitement ...</h5>
                                    <Spinner />
                                    <span className="breadcrumb-item active" style={{ display: 'block', textAlign: 'center' }}>
                                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                                    </span>
                                </ReactModal>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
