import React from "react";
import { Link } from "react-router-dom"

const active = 'nav-link active'
const defaut = 'nav-link'

const Tab = ({ name }) =>{
    return (
        <ul className="nav nav-tabs nx-nav-tabs">
            <li className="nav-item">
                <Link className={name === 'utilisateurs' ? active : defaut }  to='parametres' > Utilisateurs </Link>
            </li>
            <li className="nav-item">
                <Link className={name === 'roles' ? active : defaut } to='roles'  > Rôles </Link>
            </li>
            <li className="nav-item">
                <Link className={name === 'clients' ? active : defaut } to='clients'  > Clients</Link>
            </li>
            <li className="nav-item">
                <Link className={name === 'fournisseurs' ? active : defaut } to='fournisseurs'  > Fournisseurs </Link>
            </li>
            <li className="nav-item">
                <Link className={name === 'categories' ? active : defaut } to='categories'  > Categories de produits </Link>
            </li>
            <li className="nav-item">
                <Link className={name === 'unites' ? active : defaut } to='unites'  > Unités de produits </Link>
            </li>
            <li className="nav-item">
                <Link className={name === 'devises' ? active : defaut } to='devises'  > Dévises </Link>
            </li>
            <li className="nav-item">
                <Link className={name === 'magasins' ? active : defaut } to='magasins'  > Magasins </Link>
            </li>
            <li className="nav-item">
                <Link className={name === 'structure' ? active : defaut }  to='structure'  > Structure</Link>
            </li>
        </ul>
    );
}

export default Tab
  