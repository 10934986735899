const formatEditDate = (strDate) =>{
   const date = new Date(strDate)
   return `${date.getFullYear()}-${("0" + (date.getMonth()+1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`
}
const formatDate = (strDate) =>{
   const date = new Date(strDate)
   return `${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth()+1)).slice(-2)}/${date.getFullYear()}`
}
const formatDateStrToDateComplete = (strDate) =>{
   const date = new Date(strDate)
   return `${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth()+1)).slice(-2)}/${date.getFullYear()} - ${date.getHours()}h${date.getMinutes()}min ${date.getSeconds()}s`
}
const formatDateComplete = (strDate) =>{
   const date = new Date(parseInt(strDate))
   return `${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth()+1)).slice(-2)}/${date.getFullYear()} - ${date.getHours()}h${date.getMinutes()}min ${date.getSeconds()}s`
}

const editable = (myArray,myObject) =>{
   let inter = myArray.filter(arr =>{ return arr.id !== myObject.id })
   return [...inter,myObject]
}
const deletable= (myArray,myId) =>{
   return myArray.filter(arr =>{ return arr.id !== myId })
}

const shortText = (str) =>{
   if(str.length >= 25){ return str.substring(0, 25)+"..." }
   else{ return str}
}

const formatNumber = (nbre)=>{
   // return nbre.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
   return nbre ? nbre.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0;
} 
const statutPaiement = (bol) => {
   return (bol === true )? "Payé" : "Non payé"
}
const calculReliquat = (montant, paye) => {
   return ( ( parseInt(montant) - parseInt(paye) ) >= 0 ) ? (parseInt(montant) - parseInt(paye)) : 0
}

const privilegeLabel = (privilege) =>{
   switch (parseInt(privilege)) {
      case 0:
         return {value: "0", label: "Aucun accés" }
      case 1:
         return {value: "1", label: "Lecture" }
      case 2:
         return {value: "2", label: "Ecriture" }
      default:
         break;
   }
}
const nameInitial = (nom,prenom) =>{
   return (`${nom.charAt(0).toUpperCase()}${prenom.charAt(0).toUpperCase()}`)
}
const addLabel = (entity,genre) =>{
   switch (genre) {
      case "f":
         return `Nouvelle ${entity}`
      case "v":
         return `Nouvel ${entity}`
      case "m":
         return `Nouveau ${entity}`
      default:
         return `Eregistrement`
   }
}
const editLabel = (entity) =>{
   return `Modification ${entity}`
}
export { 
   formatDate, formatDateComplete, formatDateStrToDateComplete, formatNumber,calculReliquat, privilegeLabel, 
   statutPaiement, formatEditDate,editable,deletable,shortText,
   nameInitial,addLabel,editLabel
}