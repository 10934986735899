import React from "react";
import { Link } from "react-router-dom";

const Head = ({ name }) =>{
    return(
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-md-6 col-sm-12">
                        <h1 className="m-0 text-dark" style={{fontSize: '1.4rem'}}>{name}</h1>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><Link to="home">Tableau de board</Link></li>
                            <li className="breadcrumb-item active">{name}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default  Head
  