import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import logo from '../../assets/img/logo.png'
import Session from '../../session'
import Spinner from '../../components/Spinner'
import './index.css'

const initialState = { dbname: '', key: '' }
export default class Subscribe extends React.Component {
    constructor(props) {
        super(props)
        this.state = { load: false, create: false, ...initialState }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleCreateStore = this.handleCreateStore.bind(this)
    }

    handleChange(e, name) { this.setState({ [name]: e.target.value }) }
    handleSubmit = e => {
        e.preventDefault()
        this.setState({ load: true })
        const { dbname, key } = this.state
        const data = { dbname }

        this.props.controller.save(data)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    if (res.id) {
                        Session.config(res)
                        window.location = '/'+res._id
                    } else {
                        toast.error("Une erreur inattendue s'est produit.")
                        this.setState({ load: false })
                    }
                } else {
                    this.setState({ load: false })
                    toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produit.")
                }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produit."); this.setState({ load: false }) })

    }
    handleCreateStore = e =>{
        e.preventDefault()
        // this.props.customerController.fetch()
        // .then(res => {
            
        // })
        // .catch(() => { toast.error("Une erreur inattendue s'est produit."); this.setState({ load: false }) })
    }
    // componentDidMount(){
    //     this.props.customerController.findByName('ram001')
    //     .then(res => {
            
    //     })
    //     .catch(() => { toast.error("Une erreur inattendue s'est produit."); this.setState({ load: false }) })
    // }
    render() {
        // console.log(this.props.customerController.fetch)
        const { load, create } = this.state
        return (
            <div className="" style={{ height: '100vh' }}>
                <div className="row" style={{ margin: 0, height: '100%' }}>
                    <ToastContainer />

                    <div className="col-ms-12 col-lg-5 ">
                        {!create &&
                        <div className='nx-login-form-container'>
                            <div className="nx-login-form-content card-body login-card-body">
                                <div className="login-logo">
                                    <img src={logo} alt="logo" width="150" />
                                </div>

                                <h1 className='nx-login-welcome'>BIENVENUE</h1>
                                <div className='nx-login-msg-container'>
                                    <div className='nx-mi-divier'></div>
                                    <span style={{ marginLeft: 8, marginRight: 8 }}>CONNEXION A VOTRE ESPACE</span>
                                    <div className='nx-mi-divier'></div>
                                </div>
                                <form onSubmit={this.handleSubmit} method="POST">
                                    <label className='nx-input-label'>Nom de l'espace de travail</label>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" placeholder="Nom de l'espace de travail" name="dbname" onChange={(e) => this.handleChange(e, 'dbname')} required />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-database"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            {load && <Spinner />}
                                            {!load && <button type="submit" className="btn btn-primary btn-block" style={{ background: '#b53da2' }}>Suivant</button>}
                                        </div>
                                    </div>
                                </form>

                                <div className='nx-login-divier'></div>
                                <p className='nx-fw-medium'>
                                    Vous n'avez pas d'espace de travail ? 
                                    <button className='nx-link' onClick={()=>this.setState({ create: !create })}>Créer un nouvel espace</button></p>
                            </div>

                        </div>}

                        {create &&
                        <div className='nx-login-form-container'>
                            <div className="nx-login-form-content card-body login-card-body">
                                <div className="login-logo">
                                    <img src={logo} alt="logo" width="150" />
                                </div>

                                <h1 className='nx-login-welcome'>BIENVENUE</h1>
                                <div className='nx-login-msg-container'>
                                    <div className='nx-mi-divier'></div>
                                    <span style={{ marginLeft: 8, marginRight: 8 }}>CRÉATION DE VOTRE ESPACE</span>
                                    <div className='nx-mi-divier'></div>
                                </div>
                                <form onSubmit={this.handleCreateStore} method="POST">
                                    <label className='nx-input-label'>Nom de l'espace de travail *</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Nom de l'espace de travail" name="dbname" onChange={(e) => this.handleChange(e, 'dbname')} required />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-database"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <span className='nx-input-error'>* Ce nom d'espace de travail existe déjà !</span>
                                    <label className='nx-input-label'>Nom de la boutique *</label>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" placeholder="Nom de la boutique" name="boutique" onChange={(e) => this.handleChange(e, 'boutique')} required />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-store"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <label className='nx-input-label'>Adresse de la boutique *</label>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" placeholder="Adresse de la boutique" name="adresse" onChange={(e) => this.handleChange(e, 'adresse')} required />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-map-marker-alt"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <label className='nx-input-label'>N° Tél. de la boutique</label>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" placeholder="N° Tél. de la boutique" name="telephone" onChange={(e) => this.handleChange(e, 'telephone')} required />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-mobile-alt"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <label className='nx-input-label'>Email de la boutique *</label>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" placeholder="Email de la boutique" name="email" onChange={(e) => this.handleChange(e, 'email')} required />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-at"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <label className='nx-input-label'>Identifiant de l'administrateur *</label>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" placeholder="Identifiant de l'administrateur" name="admin" onChange={(e) => this.handleChange(e, 'admin')} required />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-user-cog"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            {load && <Spinner />}
                                            {!load && <button type="submit" className="btn btn-primary btn-block" style={{ background: '#b53da2' }}>Suivant</button>}
                                        </div>
                                    </div>
                                </form>

                                <div className='nx-login-divier'></div>
                                <p>Vous avez déjà un espace de travail ? 
                                    <button className='nx-link' onClick={()=>this.setState({ create: !create })}>Se connecter</button></p>
                            </div>

                        </div>}
                    </div>
                    <div className="col-ms-12 col-lg-7 nx-login-description-content" >
                        <div className='nx-login-description-wraper'>
                            <button className='btn nx-login-policy-btn'>
                                <span className="fas fa-book" style={{marginRight: 8}}></span>Politique de confidentialité
                            </button>
                            <p>
                                Renseigner vos informations d'identification pour acceder à votre espace de travail.<br /><br />
                                Ces informations vous sont fournies par mail après la creation de votre compte
                            </p>
                            <a href='https://sanibara.codesign.ml' target='_blank' rel="noreferrer" className='btn nx-login-discover-btn'>Decouvrez SANI-BARA</a>
                        </div>
                        <div className='nx-login-description'></div>
                    </div>
                </div>
            </div>
        )
    }
}