import Achat from './achat'
import NewAchat from './new'
import AchatDetail from './achat-detail'
import { ArticlesServices, EntreesServices, FournisseursServices, MagasinsServices } from '../../services'
import NotFound from '../not-found'
import Commande from './commande'
import CommandeDetail from './commande-detail'


const AchatPage = (props) => {
    const { permission, session } = props
    const controller = new EntreesServices(session)

    const columns = [
        { name: 'id', header: 'id', prefix: 'CMD_' },
        { name: 'montant', header: 'Montant', suffix: session.devise ? ` ${session.devise}` : ' F CFA8'  },
        { name: 'tva', header: 'TVA', suffix: ' %' },
        { name: 'date', header: 'Date', date: 'date-fr' },
        { name: 'nom', header: 'Nom Frs.' },
        { name: 'paye', header: 'Montant payé', suffix: session.devise ? ` ${session.devise}` : ' F CFA'  },
        { name: 'reliquat', header: 'Reste à payer', suffix: session.devise ? ` ${session.devise}` : ' F CFA'  },
        { name: 'type', header: 'Acht/cmd' },
    ]

    if (permission === '1')
        return <Achat
            {...props}
            controller={controller}
            columns={columns}
            devise={session.devise ? session.devise : 'F CFA'}
        />
    else return <NotFound />
}

const NewAchatPage = (props) => {
    const { permission, session } = props
    const controller = new EntreesServices(session)
    const fournisseurController = new FournisseursServices(session)
    const articleController = new ArticlesServices(session)
    const magasinController = new MagasinsServices(session)

    if (permission === '1')
        return <NewAchat
            {...props}
            controller={controller}
            fournisseurController={fournisseurController}
            articleController={articleController}
            magasinController={magasinController}
            type="Achat"
            devise={session.devise ? session.devise : 'F CFA'}
        />
    else return <NotFound />
}

const AchatDetailPage = (props) => {
    const { permission, session } = props
    const controller = new EntreesServices(session)

    if (permission === '1')
        return <AchatDetail
            {...props}
            controller={controller}
            devise={session.devise ? session.devise : 'F CFA'}
        />
    else return <NotFound />
}
/*================================================================
                            COMMANDES
=================================================================*/
const NewCommandePage = (props) => {
    const { permission, session } = props
    const controller = new EntreesServices(session)
    const fournisseurController = new FournisseursServices(session)
    const articleController = new ArticlesServices(session)
    const magasinController = new MagasinsServices(session)

    if (permission === '1')
        return <NewAchat
            {...props}
            controller={controller}
            fournisseurController={fournisseurController}
            articleController={articleController}
            magasinController={magasinController}
            type="Commande"
            devise={session.devise ? session.devise : 'F CFA'}
        />
    else return <NotFound />
}

const CommandePage = (props) => {
    const { permission, session } = props
    const controller = new EntreesServices(session)

    const columns = [
        { name: 'id', header: 'id', prefix: 'ENT_' },
        { name: 'montant', header: 'Montant', suffix: session.devise ? ` ${session.devise}` : ' F CFA' },
        { name: 'tva', header: 'TVA', suffix: ' %' },
        { name: 'date', header: 'Date', date: 'date-fr' },
        { name: 'nom', header: 'Nom Frs.' },
        { name: 'paye', header: 'Montant payé', suffix: session.devise ? ` ${session.devise}` : ' F CFA' },
        { name: 'reliquat', header: 'Reste à payer', suffix: session.devise ? ` ${session.devise}` : ' F CFA' },
        { name: 'etat', header: 'Livraison' },
    ]

    if (permission === '1')
        return <Commande
            {...props}
            controller={controller}
            devise={session.devise ? session.devise : 'F CFA'}
            columns={columns}
        />
    else return <NotFound />
}

const CommandeDetailPage = (props) => {
    const { permission, session } = props
    const controller = new EntreesServices(session)

    if (permission === '1')
        return <CommandeDetail
            {...props}
            controller={controller}
            devise={session.devise ? session.devise : 'F CFA'}
        />
    else return <NotFound />
}

export { AchatPage, NewAchatPage, AchatDetailPage, CommandePage, NewCommandePage, CommandeDetailPage }