import React from 'react'
// import { ToastContainer } from 'react-toastify'
import Head from '../../components/Head'

export default class Profil extends React.Component{
    constructor(props){
        super(props)
        this.state = {}
    }


    render(){
        const { pageName } = this.props
        return(
            <>
                <Head name={pageName} />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            {/* <ToastContainer /> */}
                            <div className="col-12">
                                <div className="card">
                                    <div className="nx-card-header">
                                        <h4 className="nx-card-title">Alerte information</h4>
                                    </div>
                                    <div className="card-content collapse show">
                                        <div className="card-body">
                                            <div className="card-text" style={{display: 'flex', justifyContent: 'space-around' }}>
                                                <div>
                                                    <h5>Votre abonnement est expiré !</h5>
                                                    <p>Veuillez contacter Codesign pour renouveler votre abonnement.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}