import React from 'react'
import { Bar } from 'react-chartjs-2'
import { ToastContainer, toast } from 'react-toastify'
import Head from '../../components/Head'
import Spinner from '../../components/Spinner'
import { formatNumber }  from '../../lib/functions'

const anneeOptions = ['2021', '2022', '2023', '2024', '2025','2026', '2027', '2028', '2029', '2030', '2031']
const moisOptions = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Semptembre', 'Octobre', 'Novembre', 'Decembre']

export default class Bilan extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            data: [],
            filterData: [],
            isFilter: false, isYearFilter: false, isMonthFilter: false,
            gSpin: false, ySpin: false, mSpin: false,
            debut: '', fin: '', mois: '', annee: '', yearAnnee: '',
            month: {commandes: [], depenses: [], ventes: []},
            year: {commandes: [], depenses: [], ventes: []}
        }

        this.handleFilter = this.handleFilter.bind(this)
        this.handleYearFilter = this.handleYearFilter.bind(this)
        this.handleMonthFilter = this.handleMonthFilter.bind(this)
    }

    handleChange(e, name){ this.setState({ [name]: e.target.value }) }

    getMonth(date){
        this.props.statController.month({date})
        .then(res => {
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ month: res, mSpin: false }) 
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ mSpin: false }) }
        })
        .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({ mSpin: false }) })
    }

    getYear(date){
        this.props.statController.year({date})
        .then(res => {
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ year: res, ySpin: false }) 
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ySpin: false }) }
        })
        .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({ ySpin: false }) })
    }

    handleFilter(e){
        e.preventDefault();
        const { debut, fin } = this.state
        const data = { debut, fin }
        this.setState({gSpin: true})
        this.props.controller.save(data)
        .then(res=>{
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ filterData: res, isFilter: true, gSpin: false })
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ gSpin: false }) }
        })
        .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({ gSpin: false }) })
    }

    handleYearFilter(e){
        e.preventDefault();
        const { yearAnnee } = this.state
        this.setState({ySpin: true})
        if(yearAnnee !==''){ this.getYear(yearAnnee) }
        else{ toast.error("Le champ Année est obligatoire.") }
    }

    handleMonthFilter(e){
        e.preventDefault();
        const { mois, annee } = this.state
        this.setState({mSpin: true})
        if(mois !=='' && annee !==''){ this.getMonth({ month: mois, year: annee }) }
        else{ toast.error("Les deux champs (Mois & Année) sont obligatoires.") }
    }

    componentDidMount(){
        const date = new Date()

        this.props.controller.fetch()
        .then(data=>{
            if(!data.error && typeof(data.pror) === 'undefined'){
                this.setState({ data })
            }
            else{ toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite.") }
        }).catch(()=> toast.error("Une erreur inattendue s'est produite.") )

        this.getMonth({month: date.getMonth()+1, year: `${date.getFullYear()}` })
        this.getYear(`${date.getFullYear()}`)
    }

    render(){
        const { pageName } = this.props
        const { debut, fin, mois, annee, isFilter, data, filterData, month, year, gSpin, ySpin, mSpin } = this.state

        const barYear = {
            labels: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Semptembre', 'Octobre', 'Novembre', 'Decembre'],
            datasets: [
               { label: 'Ventes', backgroundColor: '#32CD32', borderColor: '#32CD32', borderWidth: 1, hoverBackgroundColor: '#32CD32', hoverBorderColor: '#32CD32',
                data: year.ventes,
              },
              {label: 'Commandes', backgroundColor: 'yellow', borderColor: 'yellow', borderWidth: 1, hoverBackgroundColor: 'yellow', hoverBorderColor: 'yellow',
               data: year.commandes,
             },
              {label: 'Charges fixes', backgroundColor: 'red', borderColor: 'red', borderWidth: 1, hoverBackgroundColor: 'red', hoverBorderColor: 'red',
                data: year.depenses,
              }
            ]
        }

        const barMonth = {
            labels: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
            datasets: [
               {
                label: 'Ventes', backgroundColor: '#32CD32', borderColor: '#32CD32', borderWidth: 1, hoverBackgroundColor: '#32CD32', hoverBorderColor: '#32CD32',
                data: month.ventes,
              },
              {
               label: 'Commandes', backgroundColor: 'yellow', borderColor: 'yellow', borderWidth: 1, hoverBackgroundColor: 'yellow', hoverBorderColor: 'yellow',
               data: month.commandes,
             },
              {
                label: 'Charges fixes', backgroundColor: 'red', borderColor: 'red', borderWidth: 1, hoverBackgroundColor: 'red', hoverBorderColor: 'red',
                data: month.depenses,
              }
            ]
        }

        return(
            <>
                <Head name={pageName}  />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <ToastContainer />
                            <div className="col-12">
                                <form method="POST" onSubmit={this.handleFilter}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-12 col-sm-12">
                                                <div className="form-group">
                                                <span className="breadcrumb-item active">Filtrer entre deux dates</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <div className="form-group">
                                                    <input onChange={(e) => this.handleChange(e, 'debut')} type="date" value={debut} className="form-control form-control-sm" placeholder="Mot de passe actuel" required />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6">
                                                <div className="form-group">
                                                    <input onChange={(e) => this.handleChange(e, 'fin')} type="date" value={fin} className="form-control form-control-sm" placeholder="Nouveau *" required />
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-4 col-sm-12">
                                                <div className="form-group">
                                                    {!gSpin && <button type="submit" name="valider" className="btn btn-primary">Filtrer</button>}
                                                    {gSpin && <Spinner />}
                                                    {isFilter &&
                                                    <button onClick={()=>this.setState({isFilter: false, debut: '', fin: ''})} style={{marginLeft: 8}} className="btn btn-secondary">Annuler</button>} 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12" style={{marginBottom: 24 }}>
                                        {isFilter && 
                                        <span className="breadcrumb-item active">
                                            {`Les totaux des opérations ( Ventes - Achats ) qui ce sont déroulées entre ${debut} et ${fin}.`}
                                        </span>}
                                        {!isFilter &&
                                        <span className="breadcrumb-item active">
                                            {`Les totaux des opérations ( Ventes - Achats ) qui ce sont déroulées depuis la mise en place de l'application.`}
                                        </span>}
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="card card-success card-outline">
                                            <div className="card-body box-profile">
                                                <p className="text-muted text-center">Chiffre d'affaire</p>
                                                {!isFilter && <h3 className="profile-username text-center">{formatNumber(`${data.vente}`)}</h3>}
                                                {isFilter && <h3 className="profile-username text-center">{formatNumber(`${filterData.vente}`)}</h3>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="card card-secondary card-outline">
                                            <div className="card-body box-profile">
                                                <p className="text-muted text-center">Total commandes</p>
                                                {!isFilter && <h3 className="profile-username text-center">{formatNumber(`${data.commande}`)}</h3>}
                                                {isFilter && <h3 className="profile-username text-center">{formatNumber(`${filterData.commande}`)}</h3>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="card card-danger card-outline">
                                            <div className="card-body box-profile">
                                                <p className="text-muted text-center">Total dépenses</p>
                                                {!isFilter && <h3 className="profile-username text-center">{formatNumber(`${data.depense}`)}</h3>}
                                                {isFilter && <h3 className="profile-username text-center">{formatNumber(`${filterData.depense}`)}</h3>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6">
                                        <div className="card card-primary card-outline">
                                            <div className="card-body box-profile">
                                                <p className="text-muted text-center">Bénéfice</p>
                                                {!isFilter && <h3 className="profile-username text-center">{formatNumber(`${parseInt(data.vente) - (parseInt(data.commande) + parseInt(data.depense))}`)}</h3>}
                                                {isFilter && <h3 className="profile-username text-center">{formatNumber(`${parseInt(filterData.vente) - (parseInt(filterData.commande) + parseInt(filterData.depense))}`)}</h3>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <form method="POST" onSubmit={this.handleMonthFilter}>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12" style={{display: 'flex', justifyContent: 'end', flexWrap: 'wrap', gap: 8, alignItems: 'center'}}>
                                                        <span className="breadcrumb-item active">Séléctionner un mois et une année pour filtrer les statistiques correspondantes</span>
                                                        <select className="form-control form-control-sm" onChange={(e) => this.handleChange(e, 'mois')}  style={{margin: 0, width: 200}} required>
                                                            <option value="">--Mois--</option>
                                                            {moisOptions.map((a, i) => <option value={i+1} key={i}>{a}</option>)}
                                                        </select>
                                                        <select className="form-control form-control-sm" onChange={(e) => this.handleChange(e, 'annee')}  style={{margin: 0, width: 200}} required>
                                                            <option value="">--Annee--</option>
                                                            {anneeOptions.map((a, i) => <option value={a} key={i}>{a}</option>)}
                                                        </select>
                                                        {!mSpin && <button type="submit" name="valider" className="btn btn-primary">Filtrer</button>}
                                                        {mSpin && <Spinner />}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card card-warning card-outline">
                                            <div className="card-body box-profile">
                                                <h3 className="profile-username text-center">Les statistiques journalieres d'un mois de l'année</h3>
                                                <Bar data={barMonth} height={window.innerWidth < 500 ? 400 : 130} options={{ responsive: true, maintainAspectRatio: true, }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* STATS BY YEAR */}
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <form method="POST" onSubmit={this.handleYearFilter}>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12" style={{display: 'flex', justifyContent: 'end', flexWrap: 'wrap', gap: 8, alignItems: 'center'}}>
                                                        <span className="breadcrumb-item active">Séléctionner une année pour filtrer les statistiques de celle-ci</span>
                                                        <select className="form-control form-control-sm" onChange={(e) => this.handleChange(e, 'yearAnnee')}  style={{margin: 0, width: 200}} required>
                                                            <option value="">--Annee--</option>
                                                            {anneeOptions.map((a, i) => <option value={a} key={i}>{a}</option>)}
                                                        </select>
                                                        {!ySpin && <button type="submit" name="valider" className="btn btn-primary">Filtrer</button>}
                                                        {ySpin && <Spinner />}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card card-warning card-outline">
                                            <div className="card-body box-profile">
                                                <h3 className="profile-username text-center">Les statistiques mensuelles d'une année</h3>
                                                {/* {!isFilter && <p className="text-muted text-center">{parseInt(data.vente) - parseInt(data.commande)}</p>}
                                                {isFilter && <p className="text-muted text-center">{parseInt(filterData.vente) - parseInt(filterData.commande)}</p>} */}
                                                <Bar data={barYear} options={{ responsive: true, maintainAspectRatio: true }} height={window.innerWidth < 500 ? 400 : 130} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* END STATS BY YEAR */}

                        </div>
                    </div>
                </div>
            </>
        )
    }
}