import { CentralEntityService } from './service';

export class CentralStoresServices extends CentralEntityService {
  constructor(session) {
    super('customers', session)
  }
  // findById = (id) => this._get(`${id}`)
  findByName = (name) => this._get(`check/${name}/stock`)
  // detail = (id) => this._get(`details/${id}`)
  // valider = (id) => this._get(`valider/${id}`)
  // savePaiement = (data) => this._post(`/paiements/create`, { data })
  // updatePaiement = (idpaie, data) => this._post(`/paiements/update/${idpaie}`, { data })
  // deletePaiement = (idpaie) => this._delete(`/paiements/delete/${idpaie}`)
}



