import React from 'react';
import PropTypes from 'prop-types';
import './index.css'
import { Link } from 'react-router-dom';
import Poper from '../Poper'
import { formatDateComplete, formatEditDate, formatDate, formatNumber } from '../../lib/functions';
import Spinner from '../Spinner';

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <nav>
        <ul className="pagination">
            <li className={`page-item ${page === 0 ? 'disabled' : ''}`}>
                <Link className="page-link" to="#" onClick={handleFirstPageButtonClick}>
                    <i className="fas fa-angle-double-left"></i>
                </Link>
            </li>
            <li className={`page-item ${page === 0 ? 'disabled' : ''}`}>
              <Link className="page-link" to="#" onClick={handleBackButtonClick}>
                <i className="fas fa-chevron-left"></i></Link>
            </li>
            <li className="page-item active">
              <Link className="page-link disabled" to="#">
                {page+1}
              </Link>
            </li>
            <li className={`page-item ${page >= Math.ceil(count / rowsPerPage) - 1 ? 'disabled' : ''}`}>
              <Link className="page-link" to="#" onClick={handleNextButtonClick}>
                <i className="fas fa-chevron-right"></i>
              </Link>
            </li>
            <li className={`page-item ${page >= Math.ceil(count / rowsPerPage) - 1 ? 'disabled' : ''}`}>
                <Link className="page-link" to="#" onClick={handleLastPageButtonClick}>
                    <i className="fas fa-angle-double-right"></i>
                </Link>
            </li>
        </ul>
    </nav>
  );

  
}

TablePaginationActions.propTypes = {
  //count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


const cellsRow = (data, size) => {
  let cells = []
  for (let y = 0; y < size; y++) {
    cells[y] = <td key={y}>{data[y]}</td>
  }
  return cells
}

const RowTable = (row, size, key) => {
  return (
    <tr key={key}>
      {cellsRow(row, size)}
    </tr>
  )
}

class Table extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 10,
      editStateFilter: []
    }

    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
  }

  handleEdit(id) { this.props.onEdit(id) }
  handleView(id){ this.props.onView(id) }
  handlePrint(id) { this.props.onPrint(id) }
  handleCustomItemAction(id) { this.props.onCustomItemAction(id) }
  handleDelete(id) { this.props.onDelete(id) }
  handleActif(id, event) { this.props.onActifOpen(event, id) }
  handleChangeStatus(index, id){ this.props.onChangeStatus(index, id) }
  handleLinkClicked(id){ this.props.onLinkClick(id) }

  handleChangePage(event, newPage) { this.setState({ page: newPage }) }
  handleChangeRowsPerPage(event) { this.setState({ rowsPerPage: parseInt(event.target.value, 10), age: 0 }) }

  render() {
    const { rowsPerPage, page } = this.state
    const { paginate, columns, data, 
      permission, crud, 
      deleteLabel,
      countable,
      editable, printable, detaillable, deletable, viewOnly, 
      load, stat, adminAtt, adminVal, userTable, roleTable,
      hasCustomItem, customItemLabel, customItemIcon
    } = this.props
   //const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const tRows = []
    // CONTENU DU TABLEAU
    if (data.length > 0) {
      data.map((mod, i) => {
        let temp = []
        if(countable) temp.push(i+1)
        for (let y = 0; y < columns.length; y++) {
          // cas ou le tableu doit contenir un object ou l'on doit afficher au moins un de ses attributs
          if (typeof mod[columns[y].name] === 'object') {
            const obj = mod[columns[y].name]
            if(obj.type !=="undefined"){
              //temp.push( jsx`${mod[columns[y].name]}`)
              //}else{
                let attributes = ''
                let content = obj
                for (let x = 0; x < columns[y].attributes.length; x++) {
                  for(let z = 0; z < columns[y].attributes[x].split('.').length; z++){
                    if(typeof(content) !== 'undefined'){
                      content = content[`${columns[y].attributes[x].split('.')[z]}`] 
                    }else{
                      content = ''
                    }
                  //content = `${content}['${columns[y].attributes[x].split('.')[z]}']`
                  // console.log("11111111", content)
                }

                // attributes = `${attributes} ${obj[columns[y].attributes[x]] }` 
                attributes = content
                // console.log(y+"3333333", content)
              }
              temp.push(attributes)
            }
          } else { 
            if(typeof (columns[y].payStat) !== 'undefined'){
              mod[columns[y].name] === 1 ? temp.push('Valider') : temp.push('En cours')
            }else{
              if(typeof (columns[y].check) !== 'undefined'){
                // temp.push(<Badge3 stat={mod[columns[y].name]} />)
              }else{
                if(typeof (columns[y].customStat) !== 'undefined'){
                //   temp.push(<Badge4 label={mod[columns[y].name]} onChangeStatus={(index)=>this.handleChangeStatus(index, mod.id)} />)
                }else{
                  if(typeof(columns[y].link) !== 'undefined'){
                    // temp.push(<Button onClick={()=>this.handleLinkClicked(mod[columns[y].id])} color="primary" style={{padding: 0, fontSize: 14}}> {mod[columns[y].name]} </Button>)
                  }else{
                    if(typeof (columns[y].admin) !== 'undefined'){
                      temp.push(mod[columns[y].name] ? 'Administrateur' : 'Utilisateur')
                    }else{
                      if(typeof (columns[y].date) !== 'undefined'){
                        if(columns[y].date === 'date-complete'){
                          temp.push(formatDateComplete(mod[columns[y].name]))
                        }else{
                          if(columns[y].date === 'date-fr'){
                            temp.push(formatDate(mod[columns[y].name]))
                          }else{
                            temp.push(formatEditDate(mod[columns[y].name]))
                          }
                        }
                      }else{
                        if(typeof (columns[y].suffix) !== 'undefined'){
                          temp.push(`${formatNumber(mod[columns[y].name])}${columns[y].suffix}`)
                        }else{
                          if(typeof (columns[y].prefix) !== 'undefined'){
                            temp.push(`${columns[y].prefix}${formatNumber(mod[columns[y].name])}`)
                          }else{
                            temp.push(mod[columns[y].name])
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        if(permission === '2' && crud){
          if(viewOnly){
            // temp.push(<i class="fas fa-eye" onClick={()=>this.handleView(null, mod.id)} ></i><VisibilityOutlined id={mod.id} onClick={()=>this.handleView(null, mod.id)}  /></Tooltip>)
              temp.push(<Link to="#" onClick={()=>this.handleView(mod._id || mod.id)} style={{color: '#001e37'}}><i className="far fa-eye" style={{fontSize: 12, color: '#869099'}}></i></Link>)
          }else{
            if(stat){
            temp.push(
              <Poper trigger={<i className="fas fa-ellipsis-v" style={{fontSize: 12, color: '#869099'}}></i>}>
                  {editable && <Link to="#" className="pop-item" onClick={()=>this.handleEdit(mod.id)}><i className="far fa-edit" style={{fontSize: 10, marginRight: 8}}></i>Modifier</Link>}
                  {deletable && <Link to="#" className="pop-item" onClick={()=>this.handleDelete(mod.id)}><i className="far fa-trash-alt" style={{fontSize: 10, marginRight: 8}}></i>{deleteLabel}</Link>}
                  <Link to="#" className="pop-item">Activer/Desactiver</Link>
              </Poper>)
            }else{
              if(userTable){
                temp.push(
                  <Poper trigger={<i className="fas fa-ellipsis-v" style={{fontSize: 12, color: '#869099'}}></i>}>
                      {editable && <span className="pop-item" onClick={()=>this.handleEdit(mod.id)}><i className="far fa-edit" style={{fontSize: 10, marginRight: 8}}></i>Modifier</span>}
                      {detaillable && <span className="pop-item" onClick={()=>this.handleView(mod._id || mod.id)}><i className="far fa-eye" style={{fontSize: 10, marginRight: 8}}></i>Details</span>}
                      {printable && <span className="pop-item" onClick={()=>this.handlePrint(mod._id || mod.id)}><i className="fas fa-print" style={{fontSize: 10, marginRight: 8}}></i>Imprimer</span>}
                      {hasCustomItem && <span className="pop-item" onClick={()=>this.handleCustomItemAction(mod._id || mod.id)}><i className={customItemIcon} style={{fontSize: 10, marginRight: 8}}></i>{customItemLabel}</span>}
                      {deletable && mod[adminAtt] !== adminVal &&  <span className="pop-item" onClick={()=>this.handleDelete(mod.id)}><i className="far fa-trash-alt" style={{fontSize: 10, marginRight: 8}}></i>{deleteLabel}</span>}
                  </Poper>)
              }else{
                temp.push(
                  <Poper trigger={<i className="fas fa-ellipsis-v" style={{fontSize: 12, color: '#869099'}}></i>}>
                      {editable && <span className="pop-item" onClick={()=>this.handleEdit(mod.id)}><i className="far fa-edit" style={{fontSize: 10, marginRight: 8}}></i>Modifier</span>}
                      {detaillable && <span className="pop-item" onClick={()=>this.handleView(mod._id || mod.id)}><i className="far fa-eye" style={{fontSize: 10, marginRight: 8}}></i>Details</span>}
                      {printable && <span className="pop-item" onClick={()=>this.handlePrint(mod._id || mod.id)}><i className="fas fa-print" style={{fontSize: 10, marginRight: 8}}></i>Imprimer</span>}
                      {hasCustomItem && <span className="pop-item" onClick={()=>this.handleCustomItemAction(mod._id || mod.id)}><i className={customItemIcon} style={{fontSize: 10, marginRight: 8}}></i>{customItemLabel}</span>}
                      {deletable && <span className="pop-item" onClick={()=>this.handleDelete(mod.id)}><i className="far fa-trash-alt" style={{fontSize: 10, marginRight: 8}}></i>{deleteLabel}</span>}
                  </Poper>)
              }
            }
          }
        }
        return tRows[i] = temp
      })
    }
    // console.log("TABLE",data.length)
    return (
      <div className="table-responsive nx-table-pagination">

        {load && <Spinner />  }
        {!load &&
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    {countable && <th>#</th>}
                    {
                      columns.map((col, key) => {
                        return <th key={key}>{col.header}</th>
                      })
                    }
                    {crud && permission === '2' && <th align="right"></th>}
                  </tr>
                </thead>
                <tbody>
                  {tRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, key) => {
                    return (permission === '2' && crud) ? countable ? RowTable(row, columns.length+2, key) : RowTable(row, columns.length+1, key) : RowTable(row, columns.length+1, key)
                  })
                  }

                  {typeof(data[0]) === 'undefined' &&(
                    <tr style={{ height: 48 }}>
                      <td colSpan={(permission === '2') ? columns.length+2 : columns.length+1} style={{ textAlign: 'center'}} >
                        Aucune information trouvée.
                      </td>
                    </tr>
                  )}
                </tbody>
                {paginate && 
                  <tfoot>
                    <tr>
                        <td colSpan={(permission === '2' && crud) ? columns.length+2 : columns.length+1} style={{textAlign: 'end'}} className="tfoot-pagination">
                            <span className="pagination-span">Lignes par page:</span>
                            <select className="custom-select pagination-select" onChange={this.handleChangeRowsPerPage}>
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={30}>30</option>
                              <option value={50}>50</option>
                            </select>
                              <span className="pagination-span pagin-from">
                                {data.length === 0 ? 0 : page * rowsPerPage + 1} -
                              </span>
                              <span className="pagination-span pagin-to">
                                {data.length  !== -1 ? Math.min(data.length , (page + 1) * rowsPerPage) : (page + 1) * rowsPerPage} sur
                              </span>
                              <span className="pagination-span pagin-count">
                                {data.length  === -1 ? -1 : data.length }
                              </span>
                            {/* <span>{page+1}</span> */}
                            <TablePaginationActions page={page} rowsPerPage={rowsPerPage} count={data.length} onChangePage={this.handleChangePage} />
                        </td>
                    </tr>
                  </tfoot>
                }
              </table>
        }
      </div>
    );
  }
}

export default Table
// export default withStyles(styles)(Table)
Table.defaultProps = { 
  paginate: true,
  crud: true,
  permission: '2',
  countable: true,
  editable: true,
  printable: true,
  detaillable: true,
  deletable: true,
  deleteLabel: 'Supprimer',
  viewOnly: false,
  userTable: false,
  hasCustomItem: false,
  customItemIcon: 'fas fa-user',
  load: true,
  openPoper: false,
  stat: false,
  filterLoad: false 
}