import React from 'react'
import { Line, defaults } from 'react-chartjs-2'
import { ToastContainer, toast } from 'react-toastify'
import Head from '../../components/Head'

defaults.global.defaultFontFamily = 'ubuntu'
defaults.global.defaultFontColor = '#41526e'

export default class Home extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            nbArticle: 0, nbCritique: 0, nbEpuise: 0, qteStock: 0,
            nbCategorie: 0, nbVente: 0, nbCommande: 0, nbDevis: 0,
            dataCritique: [],
            dataEpuise: [],
            week: {commandes: [], depenses: [], ventes: []}
        }
    }

    getCard(){
        this.props.controller.card()
        .then(res => {
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ ...res }) 
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
        })
        .catch(()=> toast.error("Une erreur inattendue s'est produite.") )
    }
    getEpuise(){
        this.props.controller.epuise()
        .then(res => {
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ dataEpuise: res }) 
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
        })
        .catch(()=> toast.error("Une erreur inattendue s'est produite.") )
    }

    getCritique(){
        this.props.controller.critique()
        .then(res => {
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ dataCritique: res }) 
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
        })
        .catch(()=> toast.error("Une erreur inattendue s'est produite.") )
    }

    getWeek(date){
        this.props.controller.week({date})
        .then(res => {
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ week: res }) 
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
        })
        .catch(()=> toast.error("Une erreur inattendue s'est produite.") )
    }

    componentDidMount(){
        const date = new Date()
        const str = `${date.getFullYear()}-${("0" + (date.getMonth()+1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`

        this.getCard()
        this.getCritique()
        this.getEpuise()
        this.getWeek(str)
    }

    render(){

        const { pageName } = this.props
        const { dataCritique, dataEpuise, nbArticle, nbCritique, nbEpuise, nbCategorie, nbVente, nbCommande, nbDevis, qteStock, week } = this.state

        const bar = {
            labels: ['Dimance', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
            datasets: [
               {
                label: 'Ventes',
                backgroundColor: 'transparent',
                borderColor: '#32CD32',
                borderWidth: 5,
                hoverBackgroundColor: '#32CD32',
                hoverBorderColor: '#32CD32',
                data: week.ventes,
              },
              {
               label: 'Commandes',
               backgroundColor: 'transparent',
               borderColor: 'yellow',
               borderWidth: 5,
               hoverBackgroundColor: 'yellow',
               hoverBorderColor: 'yellow',
               data: week.commandes,
             },
              {
                label: 'Charges fixes',
                backgroundColor: 'transparent',
                borderColor: 'red',
                borderWidth: 5,
                hoverBackgroundColor: 'red',
                hoverBorderColor: 'red',
                data: week.depenses,
              }
            ]
          }

        return(
            <>
                <Head name={pageName}  />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <ToastContainer />
                            <div className="col-lg-3 col-sm-6 col-md-4 col-ss-12">
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{nbArticle}</h3>
                                        <p>Nombre des articles</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fab fa-nutritionix"></i>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-3 col-sm-6 col-md-4 col-ss-12">
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{qteStock}</h3>
                                        <p>Quantité d'articles en stock</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-list-ol"></i>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-3 col-sm-6 col-md-4 col-ss-12">
                                <div className="small-box bg-warning">
                                    <div className="inner">
                                        <h3 style={{color: '#fff'}}>{nbCritique}</h3>
                                        <p style={{color: '#fff'}}>Etat critique</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-exclamation-triangle"></i>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-3 col-sm-6 col-md-4 col-ss-12">
                                <div className="small-box bg-danger">
                                    <div className="inner">
                                        <h3>{nbEpuise}</h3>
                                        <p>Rupture de stock</p>
                                    </div>
                                    <div className="icon">
                                        <i className="far fa-times-circle"></i>
                                    </div>
                                </div>
                            </div>

                            {/* second row */}
                            
                            <div className="col-lg-3 col-sm-6 col-md-4 col-ss-12">
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{nbCategorie}</h3>
                                        <p>Nombre des categorie</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-layer-group"></i>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-3 col-sm-6 col-md-4 col-ss-12">
                                <div className="small-box bg-warning">
                                    <div className="inner">
                                        <h3 style={{color: '#fff'}}>{nbVente}</h3>
                                        <p style={{color: '#fff'}}>Nombre des ventes</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-cart-arrow-down"></i>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-3 col-sm-6 col-md-4 col-ss-12">
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{nbCommande}</h3>
                                        <p>Nombre des commandes</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-shopping-basket"></i>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-3 col-sm-6 col-md-4 col-ss-12">
                                <div className="small-box bg-primary">
                                    <div className="inner">
                                        <h3>{nbDevis}</h3>
                                        <p>Nombre des dévis</p>
                                    </div>
                                    <div className="icon">
                                        <i className="far fa-file-alt"></i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card card-warning card-outline">
                                            <div className="card-body box-profile">
                                                <h3 className="profile-username text-center">Les statistiques de cette semaine</h3>
                                                {/* {!isFilter && <p className="text-muted text-center">{parseInt(data.vente) - parseInt(data.commande)}</p>}
                                                {isFilter && <p className="text-muted text-center">{parseInt(filterData.vente) - parseInt(filterData.commande)}</p>} */}
                                                <Line data={bar} options={{responsive: true, maintainAspectRatio: true }} height={window.innerWidth < 500 ? 400 : 130} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="card">
                                    <div className="nx-card-header">
                                        <h3 className="nx-card-title">La liste des articles en rupture de stock</h3>
                                    </div>
                                    
                                    <div className="card-body">
                                        <table id="example1" className="table table-bordered table-striped responsive">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Libellé</th>
                                                    <th>Référence</th>
                                                    <th>Prix d'achat</th>
                                                    <th>Prix de vente</th>
                                                    <th>Quantité</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataEpuise.length > 0 &&
                                                dataEpuise.map((d,i)=>{
                                                    return(
                                                        <tr key={i}>
                                                            <td>{i+1}</td>
                                                            <td>{d.designation}</td>
                                                            <td>{d.reference}</td>
                                                            <td>{d.pachat}</td>
                                                            <td>{d.pvente}</td>
                                                            <td>{d.quantite}</td>
                                                        </tr>
                                                    )
                                                }) }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="card">
                                    <div className="nx-card-header">
                                        <h3 className="nx-card-title">La liste des articles en stock en état critique</h3>
                                    </div>
                                    
                                    <div className="card-body">
                                        <table id="example1" className="table table-bordered table-striped responsive">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Libellé</th>
                                                    <th>Description</th>
                                                    <th>Prix d'achat</th>
                                                    <th>Prix de vente</th>
                                                    <th>Quantité</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataCritique.length > 0 &&
                                                dataCritique.map((d,i)=>{
                                                    return(
                                                        <tr key={i}>
                                                            <td>{i+1}</td>
                                                            <td>{d.designation}</td>
                                                            <td>{d.reference}</td>
                                                            <td>{d.pachat}</td>
                                                            <td>{d.pvente}</td>
                                                            <td>{d.quantite}</td>
                                                        </tr>
                                                    )
                                                }) }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                    </div>
                </div>
            </>
        )
    }
}