import React from 'react'
import ReactModal from 'react-modal'
import { ToastContainer, toast } from 'react-toastify'
import Session from '../../session'
import { generatePdf } from '../print.controller'
import Head from '../../components/Head'
import Spinner from '../../components/Spinner'
import { formatDate, formatDateStrToDateComplete, formatNumber } from '../../lib/functions'

const initialState = {
    popLoad: false,
}

const paragraphe = {marginLeft: 8, background: '#fff', zIndex: 1, position: 'relative', display: 'inline-block', padding: '0px 8px', fontSize: 11}
const row = {margin: 0, border: '1px solid #ddd',paddingTop: 24, borderRadius: 8, top: -24, position: 'relative'}
const initialPaiementState = { montantPaiement: 0, montantPaiementEdit: 0, currentPaiementId: null, modal: false, modalDelete: false, action: '' }

export default class Detail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            paiements: [],
            commande: {},
            totalSansTva: 0,
            devis: true,
            ...initialState,
            ...initialPaiementState,
            load: true,
            currentPaiementReliquat: 0,
            montantPaye: 0,
        }
        this.handlePaiementSubmitSave = this.handlePaiementSubmitSave.bind(this)
        this.handlePaiementSubmitUpdate = this.handlePaiementSubmitUpdate.bind(this)
    }

    calculMontant(data){
        let sum = 0
        for(let i=0; i<data.length; i++){ sum = sum + (data[i]['pu'] * data[i]['quantite']) }
        return sum
    }
    calculTotalPaye = (data)=>{
        let total = 0;
        for(let i=0; i<data.length; i++){ total+= data[i]['montant'] }
        return total
    }

    handlePaiementDeleteOpen(id){
        const current = this.state.paiements.find(p => p.id === id)
        if (current) this.setState({ modalDelete: true, currentPaiementId: id })
        else toast.error("Une erreur inattendue s'est produite, veuillez reesayer!")
    }
    handlePaiementEditOpen(id) {
        const current = this.state.paiements.find(p => p.id === id)
        if (current) this.setState({ montantPaiement: current.montant, montantPaiementEdit: current.montant, modal: true, action: 'update', currentPaiementId: id })
        else toast.error("Une erreur inattendue s'est produite, veuillez reesayer!")
    }

    gen(data, devis){
        generatePdf(data, `${devis ? 'devis' : 'sorties'}`)
        .then(html=>{
            var temp = document.createElement('div');
            temp.innerHTML = html;
            var pri = document.getElementById("ifmcontentstoprint").contentWindow;
            pri.document.open();
            pri.document.write(temp.innerHTML);
            pri.print();
            this.setState({popLoad: false})
            pri.document.close();
        }).catch(err=>console.log(err))
    }

    handlePrint(){
        this.setState({popLoad: true})
        const store = Session.get('$sf_structure')
        this.gen({commande: this.state.commande, details: this.state.data, store}, this.state.devis)
    }

    fetch = ()=> {
        const id = this.props.match.params.id
        this.props.controller.detail(id)
        .then(res => {
            if (!res.error && typeof (res.pror) === 'undefined') {
                const totalSansTva = this.calculMontant(res.details)
                const montantPaye = this.calculTotalPaye(res.paiements)
                const currentPaiementReliquat = res.commande?.montant - montantPaye
                this.setState({ commande: res.commande, paiements: res.paiements, data: res.details, totalSansTva, devis: res.commande.devis === 1 ? true : false, currentPaiementReliquat, montantPaye })
            }
            else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
        })
        .catch(() => toast.error("Une erreur inattendue s'est produite."))
    }
    componentDidMount = ()=> this.fetch()
    handleChange(e, name) { this.setState({ [name]: e.target.value }) }

    handlePaiementSubmitSave(e){
        e.preventDefault();
        this.setState({popLoad: true})
        const { commande, montantPaiement } = this.state
        const data = { montant: montantPaiement, vente_id: commande.id }

        this.props.controller.savePaiement(data)
        .then(res=>{
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ ...initialPaiementState, popLoad: false })
                toast.success(res.message)
                this.fetch()
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({popLoad: false}) }
        })
        .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({popLoad: false}) })
    }
    handlePaiementSubmitUpdate(e){
        e.preventDefault();
        this.setState({popLoad: true})
        // montantPaiement: 0, currentPaiementId: null,
        const { montantPaiement, currentPaiementId } = this.state
        const data = { montant: montantPaiement}
        if(currentPaiementId){
            this.props.controller.updatePaiement(currentPaiementId, data)
            .then(res=>{
                if(!res.error && typeof(res.pror) === 'undefined'){
                    this.setState({ ...initialPaiementState, popLoad: false })
                    toast.success(res.message)
                    this.fetch()
                }else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({popLoad: false}) }
            })
            .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({popLoad: false}) })
        }
    }

    handlePaiementSubmitDelete(){
        this.setState({popLoad: true})
        const { currentPaiementId } = this.state
        if(currentPaiementId){
            this.props.controller.deletePaiement(currentPaiementId)
            .then(res=>{
                if(!res.error && typeof(res.pror) === 'undefined'){
                    this.setState({ ...initialPaiementState, popLoad: false })
                    toast.success(res.message)
                    this.fetch()
                }else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({popLoad: false}) }
            })
            .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({popLoad: false}) })
        }
    }

    render() {
        const { pageName, devise } = this.props
        const { data, commande, totalSansTva, popLoad, devis, paiements, modal, action, montantPaiement, modalDelete, montantPaiementEdit, currentPaiementReliquat, montantPaye } = this.state
        return (
            <>
                <Head name={pageName} />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <ToastContainer />
                            <div className="col-12">
                                <div className="card">
                                    <div className="nx-card-header">
                                        <>
                                        <button className="btn btn-link" onClick={()=>this.props.history.goBack()}>
                                            <i className="fa fa-arrow-left"></i>
                                        </button>
                                        <h3 className="nx-card-title">{!devis? "Détail de la facture" : "Détail du dévis" }</h3>
                                        </>
                                        <div style={{float: 'right'}}>
                                            <button className="btn btn-link" style={{padding: 0}} onClick={()=>this.handlePrint()}>
                                                <i className="fas fa-print"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6 col-lg-6">
                                                <p style={paragraphe}>{!devis? "Information sur le client et la date de la facture" : "Information sur le client et la date du dévis" }</p>
                                                <div className="row" style={{...row, marginRight: 8}}>
                                                    <div className="col-sm-12">
                                                        <p><strong>Date de la commande : </strong>{formatDate(commande.date)}</p>
                                                        <p style={{textAlign: 'center'}}><strong>CLIENT</strong></p>
                                                        <p style={{marginBottom: 4}}><strong>Nom : </strong>{commande.nom}</p>
                                                        <p style={{marginBottom: 4}}><strong>Société : </strong>{commande.societe}</p>
                                                        <p style={{marginBottom: 4}}><strong>Téléphone : </strong>{commande.telephone}</p>
                                                        <p style={{marginBottom: 4}}><strong>Email : </strong>{commande.email}</p>
                                                        <p style={{marginBottom: 4}}><strong>Adresse : </strong>{commande.adresse}</p>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6">
                                                <p style={paragraphe}>{!devis? "Information sur le montant de la facture" : "Information sur le montant du dévis" } </p>
                                                <div className="row" style={row}>
                                                    <div className="col-12">
                                                        <p style={{marginBottom: 4}}>Montant de la facture HT : <strong>{`${totalSansTva} ${devise}`}</strong></p>
                                                        <p style={{marginBottom: 4}}>{`Montant de la TVA ${commande.tva === '0' ? '' : '('+ commande.tva +'%)'} :`} <strong>{commande.tva === '0' ? 'pas de TVA appliquée' : `${(commande.montant - totalSansTva).toFixed(2)} ${devise}`}</strong></p>
                                                        <p style={{marginBottom: 4}}>Montant de la facture TTC : <strong>{`${commande.montant} ${devise}`}</strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <p style={paragraphe}>{!devis? "Information sur la facture et les produits" : "Information sur le dévis et les produits" } </p>
                                        <div className="row" style={row}>
                                            <div className="col-12 table-responsive">
                                                <table id="example1" className="table table-bordered table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Référence</th>
                                                            <th>Désignation</th>
                                                            <th>Prix unitaire</th>
                                                            <th>Quantité</th>
                                                            <th>Montant</th>
                                                            <th>Magasin</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.length > 0 &&
                                                            data.map((d, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>{i + 1}</td>
                                                                        <td>{d.reference}</td>
                                                                        <td>{d.designation}</td>
                                                                        <td>{`${formatNumber(d.pu)} ${devise}`}</td>
                                                                        <td>{`${formatNumber(d.quantite)} ${d.unite}`}</td>
                                                                        <td>{`${formatNumber(d.pu * d.quantite)} ${devise}`}</td>
                                                                        <td>{d.store}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-12">
                                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                                    <h5>Traitement ...</h5>
                                    <Spinner />
                                    <span className="breadcrumb-item active" style={{ display: 'block', textAlign: 'center' }}>
                                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                                    </span>
                                </ReactModal>

                                <ReactModal isOpen={modal} ariaHideApp={false}>
                                    <h5>{action === 'save' ? "Nouveau paiement" : "Modification du paiement"}</h5>

                                    <form method="POST" onSubmit={action === 'save' ? this.handlePaiementSubmitSave : this.handlePaiementSubmitUpdate}>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>Montant *</label>
                                                    <input type="number" min={5} value={montantPaiement}  onChange={(e)=>this.handleChange(e, 'montantPaiement')} className="form-control" placeholder="Montant *" required />
                                                </div>
                                                {action === 'save' && <p>Reliquat: {currentPaiementReliquat} {devise}</p>}
                                                {action === 'update' && <p>Reliquat: {currentPaiementReliquat + montantPaiementEdit} {devise}</p>}
                                            </div>
                                        </div>

                                        <div style={{textAlign: 'end' }}>
                                            <button onClick={()=>this.setState({ ...initialPaiementState })} type="button" className="btn btn-secondary">Annuler</button>
                                            {action === 'save' && montantPaiement <= currentPaiementReliquat && <button type="submit" name="valider" style={{ marginLeft: 8 }} className="btn btn-primary">Enregistrer</button>}
                                            {action === 'update' && montantPaiement <= currentPaiementReliquat + montantPaiementEdit && <button type="submit" name="valider" style={{ marginLeft: 8 }} className="btn btn-primary">Modifier</button>}
                                        </div>
                                    </form>
                                </ReactModal>

                                <ReactModal isOpen={modalDelete} ariaHideApp={false}>
                                    <h5>Suppresion du paiement</h5>
                                    <span className="card-text">
                                        {`Etes-vous sûr de vouloir supprimer ce paiement, cette action est irreversible.`}
                                    </span>

                                    <div style={{ textAlign: 'end', marginTop: 16 }}>
                                        <button onClick={() => this.setState({ ...initialPaiementState })} type="button" className="btn btn-secondary">Annuler</button>
                                        <button onClick={() => this.handlePaiementSubmitDelete()} style={{ marginLeft: 8 }} className="btn btn-primary">Supprimer</button>
                                    </div>
                                </ReactModal>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}