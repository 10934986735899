import React from 'react'
// import { Link } from 'react-router-dom'
import ReactModal from 'react-modal'
import { ToastContainer, toast } from 'react-toastify'
import md5 from 'md5'
import Head from '../../components/Head'
import Tab from '../../components/Tab'
import Spinner from '../../components/Spinner'
import Table from '../../components/Table'
import SearchInput from '../../components/Search'

const initialState = {
    login: '',
    nom: '',
    role_id: '',
    password: '', 
    confirmation: '',
    action: '',
    currentId: '',
    currentStatut: '',
    // for edit
    editRoleLibelle: '',
    editRoleId: '',
    //===========
    popLoad: false,
    modal: false,
    modalDelete: false
}

const columns = [
    // { name: '_id', header: 'ID' },
    { name: 'nom', header: 'Nom complet' },
    { name: 'login', header: 'Identifiant' },
    { name: 'libelle', header: 'Rôle' }
]

export default class Utilisateurs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            tempData: [],
            search: '',
            roleOptions: [],
            load: true,
            ...initialState,
        }
        this.handleSubmitSave = this.handleSubmitSave.bind(this)
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this)
    }

    handleChange(e, name) { this.setState({ [name]: e.target.value }) }
    handleEditOpen(id) {
        const model = this.state.data.find(d=>d.id === id)
        if(model) this.setState({ ...model, editRoleId: model.role_id, editRoleLibelle: model.libelle,  modal: true, action: 'update', currentId: id })
    }
    handleDeleteOpen(id, statut){
        if(id){ this.setState({ modalDelete: true, currentId: id, currentStatut: statut }) }
        else{ toast.error("Une erreur inattendue s'est produite.") }
    }

    handleSubmitSave(e) {
        e.preventDefault();
        this.setState({popLoad: true})
        const { nom, login, role_id, password, confirmation } = this.state
        if(password !== '' && password === confirmation){
            const data = { nom, login, role_id, password: md5(password), role: 'user', statut: 1 }
            this.props.controller.save(data)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ ...initialState })
                    toast.success(res.message)
                    this.componentDidMount()
                }
                else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({popLoad: false}) }
            })
            .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({popLoad: false}) })
        }else{ toast.error("Mot de passe et Confirmation ne correspondent pas."); this.setState({popLoad: false}) }

    }

    handleSubmitUpdate(e) {
        e.preventDefault();
        this.setState({popLoad: true})
        const { currentId, nom, role_id, password, confirmation } = this.state
        const data = { id: currentId.toString(), nom, role_id, password: md5(password) }
        if (currentId) {
            if(password !== '' && password === confirmation){
                this.props.controller.save(data)
                .then(res => {
                    if (!res.error && typeof (res.pror) === 'undefined') {
                        this.setState({ ...initialState })
                        toast.success(res.message)
                        this.componentDidMount()
                    }
                    else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({popLoad: false}) }
                })
                .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({popLoad: false}) })
            }else{ toast.error("Mot de passe et Confirmation ne correspondent pas."); this.setState({popLoad: false}) }
        }
    }

    handleDelete(){
        this.setState({popLoad: true})
        const { currentId, currentStatut } = this.state
        if(currentId !== '' && currentStatut !== ''){
            this.props.controller.remove(`${currentId}`)
            .then(res=>{
                if(!res.error && typeof(res.pror) === 'undefined'){
                    this.setState({ ...initialState })
                    toast.success(res.message)
                    this.componentDidMount()
                }
                else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState }) }
            })
            .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState }) })
        }
    }

    getRoleList() {
        this.props.roleController.fetch()
        .then(res => {
            if(!res.error && typeof(res.pror) === 'undefined') this.setState({ roleOptions: res }) 
            else toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.")
        })
        .catch(()=> toast.error("Une erreur inattendue s'est produite.") )
    }

    handleTableSearch = value =>{
        const data = this.state.tempData.filter(td => {
            return (
                td?.nom.toLowerCase().includes(value.toLowerCase()) ||
                td?.login.toLowerCase().includes(value.toLowerCase()) ||
                td?.libelle.toLowerCase().includes(value.toLowerCase())
            )
        })
        this.setState({ data, search: value })
    }

    componentDidMount() {
        this.props.controller.fetch()
        .then(data => { 
            if(!data.error && typeof(data.pror) === 'undefined'){
                this.setState({ data, tempData: data, load: false }) 
            } else{ toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite."); this.setState({ load: false }) }
        })
        .catch(()=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ load: false }) })

        this.getRoleList()
    }

    render() {
        const { pageName } = this.props
        const { data, roleOptions, modal, modalDelete, nom, login, editRoleLibelle, editRoleId, currentStatut, action, load, popLoad } = this.state
        return (
            <>
                <Head name={pageName}  />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12"><Tab name="utilisateurs" /></div>
                            <ToastContainer />
                            <div className="col-12">
                                <div className="card">
                                    <div className="nx-card-header">
                                        <h3 className="nx-card-title">La liste des utilisateurs</h3>
                                        <div className="btn-group btn-group-sm" role="group" aria-label="" style={{ float: 'right' }}>
                                            <button type="button" className="btn btn-success" onClick={() => this.setState({ modal: true, action: 'save' })}>
                                                Ajouter
                                            </button>
                                        </div>
                                    </div>

                                    <div className="nx-card-body card-body">
                                        <SearchInput value={this.state.search} onChange={(val)=>this.handleTableSearch(val)} />
                                        <Table columns={columns} data={data} load={load} printable={false} detaillable={false}
                                            userTable={true} adminVal='Administrateur' adminAtt='libelle'
                                            // onView={(id) => this.handleView(id)}
                                            // onPrint={(id) => this.handlePrintOne(id)}
                                            // onDelete={(id) => this.handleDeleteOpen(id)}
                                            onEdit={(id) => this.handleEditOpen(id)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <ReactModal isOpen={modal} ariaHideApp={false}>
                                    <h5>{action === 'save' ? "Ajout d'un nouvel utilisateur" : "Modification de l'utilisateur"}</h5>
                                    <form method="POST" onSubmit={action === 'save' ? this.handleSubmitSave : this.handleSubmitUpdate}>
                                        <div className="row">
                                            
                                            <div className={action === 'save' ? "col-sm-12" : "col-md-6 col-sm-12"}>
                                                <div className="form-group">
                                                    <label>Nom complet *</label>
                                                    <input type="text" value={nom} onChange={(e) => this.handleChange(e, 'nom')} className="form-control form-control-sm" placeholder="Nom complet *" required />
                                                </div>
                                            </div>
                                            {action === 'save' &&
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label>Identifiant *</label>
                                                    <input type="text" value={login} onChange={(e) => this.handleChange(e, 'login')} className="form-control form-control-sm" placeholder="Identifiant *" required />
                                                </div>
                                            </div>}
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label>Rôle *</label>
                                                    <select className="form-control form-control-sm" onChange={(e) => this.handleChange(e, 'role_id')} required >
                                                        {action === 'save' && <option value="">--Role--</option>}
                                                        {action !== 'save' && <option value={editRoleId}>{editRoleLibelle}</option>}
                                                        {roleOptions.length > 0 &&
                                                            roleOptions.map((a, i) => <option value={a.id} key={i}>{a.libelle}</option>)
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label>Mot de pasee *</label>
                                                    <input className="form-control form-control-sm" type="password" onChange={(e) => this.handleChange(e, 'password')} placeholder="Mot de passe *" required />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label>Confirmation *</label>
                                                    <input className="form-control form-control-sm" type="password" onChange={(e) => this.handleChange(e, 'confirmation')} placeholder="Confirmation *" required />
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ textAlign: 'end' }}>
                                            <button onClick={() => this.setState({ ...initialState })} type="button" className="btn btn-secondary">Annuler</button>
                                            <button type="submit" style={{ marginLeft: 8 }} className="btn btn-primary">{action === 'save' ? 'Enregistrer' : 'Modifier'}</button>
                                        </div>
                                    </form>
                                </ReactModal>

                                <ReactModal isOpen={modalDelete} ariaHideApp={false}>
                                    <h5>Suppresion de l'utilisateur</h5>
                                   
                                    <span className="breadcrumb-item active">
                                        {`Etes-vous sûr de vouloir supprimer cet utilisateur, cette action est irreversible.`}
                                    </span>

                                    <div style={{textAlign: 'end', marginTop: 16 }}>
                                        <button onClick={()=>this.setState({ ...initialState })} type="button" className="btn btn-secondary">Annuler</button>
                                        <button onClick={()=>this.handleDelete()} style={{marginLeft: 8}} className="btn btn-primary">{parseInt(currentStatut) === 1 ? "Désactiver" : "Activer"}</button>
                                    </div>
                                </ReactModal>

                                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                                    <h5>Traitement ...</h5>
                                    <Spinner />
                                    <span className="breadcrumb-item active" style={{display: 'block', textAlign: 'center'}}>
                                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                                    </span>
                                </ReactModal>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
