import { DepensesServices } from "../../services"
import NotFound from "../not-found"
import ChargeFixe from "./home"

const ChargeFixePage = (props) => {
    const { permission, session } = props
    const controller = new DepensesServices(session)

    if (permission === '1')
        return <ChargeFixe
            {...props}
            controller={controller}
            devise={session.devise ? session.devise : 'F CFA'}
        />
    else return <NotFound />
}

export { ChargeFixePage }