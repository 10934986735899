import { EntityService } from './service';

export class ArticlesServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/articles', session)
  }
  findById = (id) => this._get(`${id}`)
  print = (id) => this._report(`print/${id}`)
  printAll = () => this._report(`prints/all`)
}

export class CategoriesServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/categories', session)
  }
  findById = (id) => this._get(`${id}`)
  findActive = () => this._get('/list-active')
}

export class UnitesServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/unites', session)
  }
  findById = (id) => this._get(`${id}`)
  findActive = () => this._get('/list-active')
}

export class ClientsServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/clients', session)
  }
}

export class DepensesServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/depenses', session)
  }
  ByYear = (year) => this._get(`by/${year}`)
}

export class DevisServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/devis', session)
  }
  detail = (id) => this._get(`details/${id}`)
  findByClient = (id) => this._get(`client/${id}`)
}

export class DevisesServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/devises', session)
  }
}

export class MagasinsServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/magasins', session)
  }
  fetchAll = () => this._get(`magasins/all`)
  fetchByStoreId = (storeId) => this._get(`stock/selectable/${storeId}`)
}

export class EntreesServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/entrees', session)
  }
  findById = (id) => this._get(`${id}`)
  findByFournisseur = (id) => this._get(`fournisseur/${id}`)
  detail = (id) => this._get(`details/${id}`)
  savePaiement = (data) => this._post(`/paiements/create`, { data })
  updatePaiement = (idpaie, data) => this._post(`/paiements/update/${idpaie}`, { data })
  deletePaiement = (idpaie) => this._delete(`/paiements/delete/${idpaie}`)
  commandes = () => this._get(`commandes/list`)
  ship = (data) => this._post(`commandes/ship`, { data })
  shipQuantity = (data) => this._post(`commandes/ship/quantity`, { data })
}

export class FournisseursServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/fournisseurs', session)
  }
}

export class SortiesServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/sorties', session)
  }
  findById = (id) => this._get(`${id}`)
  findByClient = (id) => this._get(`client/${id}`)
  detail = (id) => this._get(`details/${id}`)
  savePaiement = (data) => this._post(`/paiements/create`, { data })
  updatePaiement = (idpaie, data) => this._post(`/paiements/update/${idpaie}`, { data })
  deletePaiement = (idpaie) => this._delete(`/paiements/delete/${idpaie}`)
}

export class PrecommandesServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/commandes', session)
  }
  findById = (id) => this._get(`${id}`)
  findByClient = (id) => this._get(`client/${id}`)
  detail = (id) => this._get(`details/${id}`)
  valider = (id) => this._get(`valider/${id}`)
  savePaiement = (data) => this._post(`/paiements/create`, { data })
  updatePaiement = (idpaie, data) => this._post(`/paiements/update/${idpaie}`, { data })
  deletePaiement = (idpaie) => this._delete(`/paiements/delete/${idpaie}`)
}

export class UtilisateursServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/users', session)
  }
  findById = (id) => this._get(`${id}`)
  updatePassword = (id, data) => this._post(`/update-profil/${id}`, { data })
}

export class LoginServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/signin', session)
  }
}

export class ConnexionServices extends EntityService {
  constructor(session) {
    super('connexions', session)
  }
}

export class RolesServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/roles', session)
  }
  setPermissions = (id, data) => this._post(`/permissions/${id}`, { data })
}

export class StocksServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/stock', session)
  }
  printAll = () => this._report(`prints/all`)
  stock = (type) => this._get(`${type}`)
  // update = (id, data) => this._post(`/${id}`, { data })
}

export class TracesServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/trace', session)
  }
  printAll = () => this._report(`prints/all`)
}

export class BilansServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/bilans', session)
  }
}

export class StatsServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/stats', session)
  }
  card = () => this._get(`/card`)
  epuise = () => this._get(`/epuise`)
  critique = () => this._get(`/critique`)
  
  week = (data) => this._post(`/week`, {data})
  month = (data) => this._post(`/month`, {data})
  year = (data) => this._post(`/year`, {data})
}

export class StructuresServices extends EntityService {
  constructor(session) {
    super(session.baseUrl + '/structures', session)
  }
  update = (file, body) => this._import(`update`, file, body)
}