import React from 'react'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import Head from '../../components/Head'
import Tab from '../../components/Tab'
import ReactModal from 'react-modal'
import Spinner from '../../components/Spinner'
import SearchInput from '../../components/Search'

const initialState = {
    libelle: '',
    action: '',
    currentId: '',
    currentRole: '',
    currentPermissions: {},
    popLoad: false,
    showPermissions: false,
    modal: false,
    modalDelete: false
}

const initialPermissions = {dashboard: '0', produits: '0', precommandes: '0', commandes: '0', ventes: '0', devis: '0', stock: '0', depenses: '0', bilan: '0', traces: '0', parametres: '0'}

export default class Roles extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            data: [],
            tempData: [],
            search: '',
            load: true,
            ...initialState,
        }
        this.handleSubmitSave = this.handleSubmitSave.bind(this)
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this)
        this.handleSetPermissions = this.handleSetPermissions.bind(this)
    }

    handleChange(e, name){ this.setState({ [name]: e.target.value }) }
    handlePermissionChange(e, name){ 
        const perms = this.state.currentPermissions
        perms[name] = e.target.value
        this.setState({ currentPermissions: perms }) 
    }
    handleEditOpen(id){
        const model = this.state.data.find(d=>d.id === id)
        if(model) this.setState({ ...model, modal: true, action: 'update', currentId: id, popLoad: false })
    }
    handleDeleteOpen(id){
        if(id){ this.setState({ modalDelete: true, currentId: id }) }
        else{ toast.error("Une erreur inattendue s'est produite.") }
    }

    handleSubmitSave(e){
        e.preventDefault();
        this.setState({popLoad: true})
        const { libelle } = this.state
        const data = { libelle, permissions: JSON.stringify(initialPermissions) }

        this.props.controller.save(data)
        .then(res=>{
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ ...initialState, load: true })
                toast.success(res.message)
                this.componentDidMount()
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({popLoad: false}) }
        })
        .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({popLoad: false}) })
    }

    handleSubmitUpdate(e){
        e.preventDefault();
        this.setState({popLoad: true})
        const { currentId, libelle } = this.state
        const data = {  id: currentId.toString(), libelle }
        if(currentId){
            this.props.controller.save(data)
            .then(res=>{
                if(!res.error && typeof(res.pror) === 'undefined'){
                    this.setState({ ...initialState, load: true, popLoad: false })
                    toast.success(res.message)
                    this.componentDidMount()
                }else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({popLoad: false}) }
            })
            .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({popLoad: false}) })
        }
    }
    
    handleDelete(){
        this.setState({popLoad: true})
        const { currentId } = this.state
        if(currentId){
            this.props.controller.remove(currentId)
            .then(res=>{
                if(!res.error && typeof(res.pror) === 'undefined'){
                    this.setState({ ...initialState, load: true, popLoad: false })
                    toast.success(res.message)
                    this.componentDidMount()
                }
                else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState, popLoad: false  }) }
            })
            .catch(()=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState, popLoad: false  }) })
        }
    }

    handleSetPermissions(e){
        e.preventDefault()
        this.setState({popLoad: true})
        const { currentId, currentPermissions } = this.state
        this.props.controller.setPermissions(currentId, {permissions: JSON.stringify(currentPermissions)})
            .then(res=>{
                if(!res.error && typeof(res.pror) === 'undefined'){
                    this.setState({ ...initialState, load: true, popLoad: false })
                    toast.success(res.message)
                    this.componentDidMount()
                }else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({popLoad: false}) }
            })
            .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({popLoad: false}) })
    }

    handleShowPermission(id){
        const model = this.state.data.find(d=>d.id === id)
        if(model) this.setState({ currentPermissions: JSON.parse(model.permissions), currentId: id, currentRole: model.libelle, showPermissions: true })
    }

    componentDidMount(){
        this.props.controller.fetch()
        .then(data=>{ 
            if(!data.error && typeof(data.pror) === 'undefined'){
                this.setState({ data, tempData: data, load: false }) 
            } else{ toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite."); this.setState({ load: false }) }
        })
        .catch(()=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ load: false }) })
    }

    handleTableSearch = value =>{
        const data = this.state.tempData.filter(td => td?.libelle.toLowerCase().includes(value.toLowerCase()) )
        this.setState({ data, search: value })
    }

    render(){
        const { pageName } = this.props
        const { data, modal, modalDelete, libelle, action, load, popLoad, showPermissions, currentPermissions, currentRole } = this.state

        return(
            <>
                <Head name={pageName}  />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12"><Tab name="roles" /></div>
                            <ToastContainer />
                            <div className={showPermissions ? "col-md-8 col-lg-8 col-sm-12" : "col-12"}>
                                <div className="card">
                                    <div className="nx-card-header">
                                        <h3 className="nx-card-title">La liste des rôles</h3>
                                        <div className="btn-group btn-group-sm" role="group" aria-label="" style={{float: 'right'}}>
                                            <button type="button" className="btn btn-success" onClick={()=>this.setState({ modal: true, action: 'save'  })}>
                                                Ajouter
                                            </button>
                                        </div>
                                    </div>
                                    
                                    <div className="table-responsive card-body">
                                        <SearchInput value={this.state.search} onChange={(val)=>this.handleTableSearch(val)} />
                                        {load && <Spinner />}
                                        <table style={{border: '1px solid #dee2e6' }}className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Libellé</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.length > 0 &&
                                                data.map((d,i)=>{
                                                    return(
                                                        <tr key={i}>
                                                            <td>{i+1}</td>
                                                            {d.libelle !== 'Administrateur' && <td><Link to="#" onClick={()=>this.handleShowPermission(d.id)}>{d.libelle}</Link></td>}
                                                            {d.libelle === 'Administrateur' && <td>{d.libelle}</td>}
                                                            <td>
                                                                {d.libelle !== 'Administrateur' &&
                                                                <button className="btn btn-link" onClick={()=>this.handleEditOpen(d.id)}>
                                                                    <i className="fas fa-pencil-alt"></i>
                                                                </button>}
                                                                {/* {d.libelle !== 'Administrateur' &&
                                                                <button className="btn btn-link" onClick={()=>this.handleDeleteOpen(d.id)}>
                                                                    <i className="fa fa-trash"></i>
                                                                </button>} */}
                                                            </td>
                                                        </tr>
                                                    )
                                                })

                                                }
                                            </tbody>
                                        </table>
                                        {typeof(data.length) === 'undefined' && !load &&
                                        <span className="breadcrumb-item active" style={{display: 'block', textAlign: 'center', marginTop: 8}}>
                                            {`Aucune information trouvée !`}
                                        </span>}
                                    </div>
                                </div>
                            </div>

                            {showPermissions &&
                            <div className="col-md-4 col-lg-4 col-sm-12">
                                <div className="card">
                                    <div className="nx-card-header">
                                        <h5 className="nx-card-title" style={{fontSize: 14}}>{`La liste des permissions du rôle "${currentRole}"`}</h5>
                                    </div>
                                    
                                    <div className="card-body">
                                        <form onSubmit={this.handleSetPermissions}>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <p>Tableau de bord</p>
                                                </div>
                                                <div className="col-sm-6">
                                                    <select className="form-control form-control-sm" onChange={(e) => this.handlePermissionChange(e, 'dashboard')} >
                                                        <option value={currentPermissions.dashboard}>{currentPermissions.dashboard === '0' ? 'Aucun accès' : 'Accès' }</option>
                                                        {currentPermissions.dashboard === '1' && <option value="0">Aucun accès</option>}
                                                        {currentPermissions.dashboard === '0' && <option value="1">Accès</option>}
                                                    </select>
                                                </div>

                                                <div className="col-sm-6">
                                                    <p>Charges fixes</p>
                                                </div>
                                                <div className="col-sm-6">
                                                    <select className="form-control form-control-sm" onChange={(e) => this.handlePermissionChange(e, 'depenses')} >
                                                       <option value={currentPermissions.depenses}>{currentPermissions.depenses === '0' ? 'Aucun accès' : 'Accès' }</option>
                                                        {currentPermissions.depenses === '1' && <option value="0">Aucun accès</option>}
                                                        {currentPermissions.depenses === '0' && <option value="1">Accès</option>}
                                                    </select>
                                                </div>

                                                <div className="col-sm-6">
                                                    <p>Articles</p>
                                                </div>
                                                <div className="col-sm-6">
                                                    <select className="form-control form-control-sm" onChange={(e) => this.handlePermissionChange(e, 'produits')} >
                                                       <option value={currentPermissions.produits}>{currentPermissions.produits === '0' ? 'Aucun accès' : 'Accès' }</option>
                                                        {currentPermissions.produits === '1' && <option value="0">Aucun accès</option>}
                                                        {currentPermissions.produits === '0' && <option value="1">Accès</option>}
                                                    </select>
                                                </div>

                                                <div className="col-sm-6">
                                                    <p>Commandes</p>
                                                </div>
                                                <div className="col-sm-6">
                                                    <select className="form-control form-control-sm" onChange={(e) => this.handlePermissionChange(e, 'precommandes')} >
                                                       <option value={currentPermissions.precommandes}>{currentPermissions.precommandes === '0' ? 'Aucun accès' : 'Accès' }</option>
                                                        {currentPermissions.precommandes === '1' && <option value="0">Aucun accès</option>}
                                                        {currentPermissions.precommandes === '0' && <option value="1">Accès</option>}
                                                    </select>
                                                </div>

                                                <div className="col-sm-6">
                                                    <p>Entrées en stock</p>
                                                </div>
                                                <div className="col-sm-6">
                                                    <select className="form-control form-control-sm" onChange={(e) => this.handlePermissionChange(e, 'commandes')} >
                                                       <option value={currentPermissions.commandes}>{currentPermissions.commandes === '0' ? 'Aucun accès' : 'Accès' }</option>
                                                        {currentPermissions.commandes === '1' && <option value="0">Aucun accès</option>}
                                                        {currentPermissions.commandes === '0' && <option value="1">Accès</option>}
                                                    </select>
                                                </div>

                                                <div className="col-sm-6">
                                                    <p>Etat du stock</p>
                                                </div>
                                                <div className="col-sm-6">
                                                    <select className="form-control form-control-sm" onChange={(e) => this.handlePermissionChange(e, 'stock')} >
                                                       <option value={currentPermissions.stock}>{currentPermissions.stock === '0' ? 'Aucun accès' : 'Accès' }</option>
                                                        {currentPermissions.stock === '1' && <option value="0">Aucun accès</option>}
                                                        {currentPermissions.stock === '0' && <option value="1">Accès</option>}
                                                    </select>
                                                </div>

                                                <div className="col-sm-6">
                                                    <p>Ventes</p>
                                                </div>
                                                <div className="col-sm-6">
                                                    <select className="form-control form-control-sm" onChange={(e) => this.handlePermissionChange(e, 'ventes')} >
                                                       <option value={currentPermissions.ventes}>{currentPermissions.ventes === '0' ? 'Aucun accès' : 'Accès' }</option>
                                                        {currentPermissions.ventes === '1' && <option value="0">Aucun accès</option>}
                                                        {currentPermissions.ventes === '0' && <option value="1">Accès</option>}
                                                    </select>
                                                </div>

                                                <div className="col-sm-6">
                                                    <p>Dévis/Facture proforma</p>
                                                </div>
                                                <div className="col-sm-6">
                                                    <select className="form-control form-control-sm" onChange={(e) => this.handlePermissionChange(e, 'devis')} >
                                                       <option value={currentPermissions.devis}>{currentPermissions.devis === '0' ? 'Aucun accès' : 'Accès' }</option>
                                                        {currentPermissions.devis === '1' && <option value="0">Aucun accès</option>}
                                                        {currentPermissions.devis === '0' && <option value="1">Accès</option>}
                                                    </select>
                                                </div>

                                                <div className="col-sm-6">
                                                    <p>Résultats</p>
                                                </div>
                                                <div className="col-sm-6">
                                                    <select className="form-control form-control-sm" onChange={(e) => this.handlePermissionChange(e, 'bilan')} >
                                                       <option value={currentPermissions.bilan}>{currentPermissions.bilan === '0' ? 'Aucun accès' : 'Accès' }</option>
                                                        {currentPermissions.bilan === '1' && <option value="0">Aucun accès</option>}
                                                        {currentPermissions.bilan === '0' && <option value="1">Accès</option>}
                                                    </select>
                                                </div>

                                                <div className="col-sm-6">
                                                    <p>Traces</p>
                                                </div>
                                                <div className="col-sm-6">
                                                    <select className="form-control form-control-sm" onChange={(e) => this.handlePermissionChange(e, 'traces')} >
                                                       <option value={currentPermissions.traces}>{currentPermissions.traces === '0' ? 'Aucun accès' : 'Accès' }</option>
                                                        {currentPermissions.traces === '1' && <option value="0">Aucun accès</option>}
                                                        {currentPermissions.traces === '0' && <option value="1">Accès</option>}
                                                    </select>
                                                </div>

                                                <div className="col-sm-6">
                                                    <p>Paramètres</p>
                                                </div>
                                                <div className="col-sm-6">
                                                    <select className="form-control form-control-sm" onChange={(e) => this.handlePermissionChange(e, 'parametres')} >
                                                       <option value={currentPermissions.parametres}>{currentPermissions.parametres === '0' ? 'Aucun accès' : 'Accès' }</option>
                                                        {currentPermissions.parametres === '1' && <option value="0">Aucun accès</option>}
                                                        {currentPermissions.parametres === '0' && <option value="1">Accès</option>}
                                                    </select>
                                                </div>

                                            </div>
                                            <div style={{textAlign: 'end', marginTop: 16 }}>
                                                <button onClick={()=>this.setState({ ...initialState })} type="button" className="btn btn-default">Fermer</button>
                                                <button type="submit" style={{marginLeft: 8}} className="btn btn-primary">Enregistrer</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>}

                            <div className="col-12">
                                <ReactModal isOpen={modal} ariaHideApp={false}>
                                    <h5>{action === 'save' ? "Ajout d'un nouveau rôle" : "Modification du rôle"}</h5>
                                    <form method="POST" onSubmit={action === 'save' ? this.handleSubmitSave : this.handleSubmitUpdate }>
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12">
                                                <div className="form-group">
                                                    <label>Libellé *</label>
                                                    <input name="libelle" value={libelle}  onChange={(e)=>this.handleChange(e, 'libelle')} type="text" className="form-control form-control-sm" placeholder="Libellé" required />
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{textAlign: 'end' }}>
                                            <button onClick={()=>this.setState({ ...initialState })} type="button" className="btn btn-secondary">Annuler</button>
                                            <button type="submit" style={{marginLeft: 8}} className="btn btn-primary">{action === 'save' ? 'Enregistrer' : 'Modifier'}</button>
                                        </div>
                                    </form>
                                </ReactModal>

                                <ReactModal isOpen={modalDelete} ariaHideApp={false}>
                                    <h5>Suppresion du rôle</h5>
                                   
                                    <span className="breadcrumb-item active">
                                        {`Etes-vous sûr de vouloir supprimer cet article, cette action est irreversible et entrainera la suppression de l'article dans le stock, entrée et sortie.`}
                                    </span>

                                    <div style={{textAlign: 'end', marginTop: 16 }}>
                                        <button onClick={()=>this.setState({ ...initialState })} type="button" className="btn btn-secondary">Annuler</button>
                                        <button onClick={()=>this.handleDelete()} style={{marginLeft: 8}} className="btn btn-primary">Supprimer</button>
                                    </div>
                                </ReactModal>

                                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                                    <h5>Traitement ...</h5>
                                   
                                    <Spinner />
                                    <span className="breadcrumb-item active" style={{display: 'block', textAlign: 'center'}}>
                                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                                    </span>

                                </ReactModal>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
