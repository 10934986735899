import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import Head from '../../components/Head'
import ReactModal from 'react-modal'
import Spinner from '../../components/Spinner'
import Session from '../../session'
import { generatePdf } from '../print.controller'
import Table from '../../components/Table'
import SearchInput from '../../components/Search'

const initialState = {
    designation: '',
    unite_id: '',
    categorie_id: '',
    action: '',
    // for edit
    editCategorieLibelle: '',
    editCategorieId: '',
    categorieLibelle: '',

    editUniteLibelle: '',
    editUniteId: '',
    uniteLibelle: '',
    currentId: '',
    //===========
    popLoad: false,
    modal: false,
    modalDelete: false,
    modalCategorie: false,
    modalUnite: false
}

export default class Articles extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            tempData: [],
            search: '',
            categorieOptions: [],
            uniteOptions: [],
            load: true,
            categorieLibelle: '',
            uniteLibelle: '',
            ...initialState,
        }
        this.handleSubmitSave = this.handleSubmitSave.bind(this)
        this.handleSubmitSaveCategorie = this.handleSubmitSaveCategorie.bind(this)
        this.handleSubmitSaveUnite = this.handleSubmitSaveUnite.bind(this)
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this)
    }

    handleChange(e, name) { this.setState({ [name]: e.target.value }) }
    handleEditOpen(id) {
        const model = this.state.data.find(d => d.id === id)
        if (model) this.setState({ ...model, modal: true, action: 'update', currentId: id })
    }
    handleDeleteOpen(id) {
        if (id) { this.setState({ modalDelete: true, currentId: id }) }
        else { toast.error("Une erreur inattendue s'est produite.") }
    }

    handleSubmitSaveCategorie(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { categorieLibelle } = this.state
        const data = { libelle: categorieLibelle }

        this.props.categorieController.save(data)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    data.id = res.id
                    this.setState({ categorieLibelle: '', modalCategorie: false, categorieOptions: [data, ...this.state.categorieOptions], popLoad: false })
                    toast.success(res.message)
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
    }

    handleSubmitSaveUnite(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { uniteLibelle } = this.state
        const data = { libelle: uniteLibelle }

        this.props.uniteController.save(data)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    data.id = res.id
                    this.setState({ uniteLibelle: '', modalUnite: false, uniteOptions: [data, ...this.state.uniteOptions], popLoad: false })
                    toast.success(res.message)
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
    }

    handleSubmitSave(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { designation, reference, categorie_id, unite_id, pachat, pvente } = this.state
        const data = { designation, reference, unite_id, categorie_id, pachat: parseInt(pachat), pvente: parseInt(pvente) }

        this.props.controller.save(data)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ ...initialState, load: true })
                    toast.success(res.message)
                    this.fetch()
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
    }

    handleSubmitUpdate(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { currentId, designation, unite_id, categorie_id } = this.state
        const data = { id: currentId.toString(), designation, unite_id, categorie_id }
        if (currentId) {
            this.props.controller.save(data)
                .then(res => {
                    if (!res.error && typeof (res.pror) === 'undefined') {
                        this.setState({ ...initialState, load: true, popLoad: false })
                        toast.success(res.message)
                        this.fetch()
                    } else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
                })
                .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
        }
    }

    handleDelete() {
        this.setState({ popLoad: true })
        const { currentId } = this.state
        if (currentId) {
            this.props.controller.remove(currentId)
                .then(res => {
                    if (!res.error && typeof (res.pror) === 'undefined') {
                        this.setState({ ...initialState, load: true, popLoad: false })
                        toast.success(res.message)
                        this.fetch()
                    }
                    else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState, popLoad: false }) }
                })
                .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState, popLoad: false }) })
        }
    }

    handlePrintOne(id) {
        this.setState({ popLoad: true })
        this.props.controller.print(id)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ ...initialState })
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', res.filename)
                    document.body.appendChild(link);
                    link.click();
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite.") })
    }

    handlePrintAll() {
        const store = Session.get('$sf_structure')
        generatePdf({ articles: this.state.data, store }, 'articles')
            .then(res => {
                var temp = document.createElement('div');
                temp.innerHTML = res;
                var pri = document.getElementById("ifmcontentstoprint").contentWindow;
                pri.document.open();
                pri.document.write(temp.innerHTML);
                // pri.focus();
                pri.print();
                setTimeout(() => {
                    pri.document.close();
                }, 2000);
            }).catch(err => console.log(err))
        // var content = document.getElementById("holdbody");
        // this.setState({popLoad: true})
        // this.props.controller.printAll()
        // .then(res=>{
        //     if(!res.error && typeof(res.pror) === 'undefined'){
        //         this.setState({ ...initialState })
        //         const url = window.URL.createObjectURL(new Blob([res.data]));
        //         const link = document.createElement('a');
        //         link.href = url;
        //         link.setAttribute('download', res.filename)
        //         document.body.appendChild(link);
        //         link.click();
        //     }
        //     else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState }) }
        // })
        // .catch(()=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState }) })
    }

    handleTableSearch = value => {
        const data = this.state.tempData.filter(td => {
            return (
                td?.designation.toLowerCase().includes(value.toLowerCase()) ||
                td?.reference.toLowerCase().includes(value.toLowerCase()) ||
                td?.unite.toLowerCase().includes(value.toLowerCase()) ||
                td?.libelle.toLowerCase().includes(value.toLowerCase()) ||
                td?.pachat.toString().toLowerCase().includes(value.toLowerCase()) ||
                td?.pvente.toString().toLowerCase().includes(value.toLowerCase())
            )
        })
        this.setState({ data, search: value })
    }

    getCategorieList() {
        this.props.categorieController.findActive()
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') this.setState({ categorieOptions: res })
                else toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.")
            })
            .catch(() => toast.error("Une erreur inattendue s'est produite."))
    }
    getUniteList() {
        this.props.uniteController.findActive()
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') this.setState({ uniteOptions: res })
                else toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.")
            })
            .catch(() => toast.error("Une erreur inattendue s'est produite."))
    }

    componentDidMount() {
        this.fetch()
        this.getCategorieList()
        this.getUniteList()
    }

    fetch = () => {
        this.props.controller.fetch()
            .then(data => {
                if (!data.error && typeof (data.pror) === 'undefined') {
                    this.setState({ data, tempData: data, load: false })
                } else { toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite."); this.setState({ load: false }) }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ load: false }) })
    }

    render() {
        const { pageName, columns } = this.props
        const { data, modal, modalDelete, designation, action, load, popLoad,
            categorieOptions, categorieLibelle, modalCategorie, categorie_id,
            uniteOptions, uniteLibelle, modalUnite, unite_id } = this.state

        return (
            <>
                <Head name={pageName} />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <ToastContainer />
                            <div className="col-12">
                                <div className="card">
                                    <div className="nx-card-header">
                                        <h3 className="nx-card-title">La liste des articles</h3>
                                        <div className="btn-group btn-group-sm" role="group" aria-label="" style={{ float: 'right' }}>
                                            <button type="button" className="btn btn-success" onClick={() => this.setState({ modal: true, action: 'save' })}>
                                                Nouvel
                                            </button>
                                            <button type="button" className="btn btn-secondary" onClick={() => this.handlePrintAll()}>Imprimer</button>
                                        </div>
                                    </div>

                                    <div className="nx-card-body card-body">
                                        <SearchInput value={this.state.search} onChange={(val) => this.handleTableSearch(val)} />
                                        <Table
                                            columns={columns}
                                            data={data}
                                            load={load}
                                            printable={false}
                                            detaillable={false}
                                            // deletable={false}
                                            countable={false}
                                            onEdit={(id) => this.handleEditOpen(id)}
                                            onDelete={(id) => this.handleDeleteOpen(id)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <ReactModal isOpen={modal} ariaHideApp={false}>
                                    <h5>{action === 'save' ? "Ajout d'un nouvel article" : "Modification de l'article"}</h5>
                                    <form method="POST" onSubmit={action === 'save' ? this.handleSubmitSave : this.handleSubmitUpdate}>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>Désignation *</label>
                                                    <input name="designation" value={designation} onChange={(e) => this.handleChange(e, 'designation')} type="text" className="form-control form-control-sm" placeholder="Désignation" required />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-lg-6">
                                                <label>Unité de l'article *</label>
                                                <div className="form-group" style={{ display: 'flex' }}>
                                                    <select className="form-control form-control-sm" onChange={(e) => this.handleChange(e, 'unite_id')} value={unite_id} required>
                                                        <option value="">--Unité--</option>
                                                        {uniteOptions.length > 0 &&
                                                            uniteOptions.map((a, i) => <option value={a.id} key={i}>{a.libelle}</option>)
                                                        }
                                                    </select>
                                                    <button onClick={() => this.setState({ modalUnite: true })} style={{ marginLeft: 4 }} type="button" className="btn btn-primary">
                                                        <i className="fa fa-plus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-lg-6">
                                                <label>Categorie de l'article</label>
                                                <div className="form-group" style={{ display: 'flex' }}>
                                                    <select className="form-control form-control-sm" onChange={(e) => this.handleChange(e, 'categorie_id')} value={categorie_id}>
                                                        <option value="0">--Categorie--</option>
                                                        {categorieOptions.length > 0 &&
                                                            categorieOptions.map((a, i) => <option value={a.id} key={i}>{a.libelle}</option>)
                                                        }
                                                    </select>
                                                    <button onClick={() => this.setState({ modalCategorie: true })} style={{ marginLeft: 4 }} type="button" className="btn btn-primary">
                                                        <i className="fa fa-plus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ textAlign: 'end' }}>
                                            <button onClick={() => this.setState({ ...initialState })} type="button" className="btn btn-secondary">Annuler</button>
                                            <button type="submit" style={{ marginLeft: 8 }} className="btn btn-primary">{action === 'save' ? 'Enregistrer' : 'Modifier'}</button>
                                        </div>
                                    </form>
                                </ReactModal>

                                <ReactModal isOpen={modalCategorie} ariaHideApp={false}>
                                    <h5>Ajout d'une nouvelle categorie</h5>
                                    <form method="POST" onSubmit={this.handleSubmitSaveCategorie}>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>Libellé *</label>
                                                    <input value={categorieLibelle} onChange={(e) => this.handleChange(e, 'categorieLibelle')} type="text" className="form-control form-control-sm" placeholder="Libellé" required />
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ textAlign: 'end' }}>
                                            <button onClick={() => this.setState({ modalCategorie: false, categorieLibelle: '' })} type="button" className="btn btn-secondary">Annuler</button>
                                            <button type="submit" style={{ marginLeft: 8 }} className="btn btn-primary">Enregistrer</button>
                                        </div>
                                    </form>
                                </ReactModal>

                                <ReactModal isOpen={modalUnite} ariaHideApp={false}>
                                    <h5>Ajout d'une nouvelle unite</h5>
                                    <form method="POST" onSubmit={this.handleSubmitSaveUnite}>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>Libellé *</label>
                                                    <input value={uniteLibelle} onChange={(e) => this.handleChange(e, 'uniteLibelle')} type="text" className="form-control form-control-sm" placeholder="Libellé" required />
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ textAlign: 'end' }}>
                                            <button onClick={() => this.setState({ modalUnite: false, uniteLibelle: '' })} type="button" className="btn btn-secondary">Annuler</button>
                                            <button type="submit" style={{ marginLeft: 8 }} className="btn btn-primary">Enregistrer</button>
                                        </div>
                                    </form>
                                </ReactModal>

                                <ReactModal isOpen={modalDelete} ariaHideApp={false}>
                                    <h5>Suppresion de l'article</h5>

                                    <span className="breadcrumb-item active">
                                        {`Etes-vous sûr de vouloir supprimer cet article, cette action est irreversible et entrainera la suppression de l'article dans le stock, entrée et sortie.`}
                                    </span>

                                    <div style={{ textAlign: 'end', marginTop: 16 }}>
                                        <button onClick={() => this.setState({ ...initialState })} type="button" className="btn btn-secondary">Annuler</button>
                                        <button onClick={() => this.handleDelete()} style={{ marginLeft: 8 }} className="btn btn-primary">Supprimer</button>
                                    </div>
                                </ReactModal>

                                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                                    <h5>Traitement ...</h5>

                                    <Spinner />
                                    <span className="breadcrumb-item active" style={{ display: 'block', textAlign: 'center' }}>
                                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                                    </span>

                                </ReactModal>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}