import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import Head from '../../components/Head'
import ReactModal from 'react-modal'
import Spinner from '../../components/Spinner'
import Accordion from '../../components/Accordion'
import { formatDate, formatNumber } from '../../lib/functions'

const initialState = {
    montant: '',
    date: '',
    motif: '',
    action: '',
    currentId: '',
    popLoad: false,
    modal: false,
    modalDelete: false
}

export default class ChargeFixe extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            data: {},
            dataTemp: [],
            load: true,
            btnLoad: false,
            currentYear: '',
            ...initialState,
        }
        this.handleSubmitSave = this.handleSubmitSave.bind(this)
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this)
    }

    handleChange(e, name){ this.setState({ [name]: e.target.value }) }
    handleEditOpen(id){
        console.log("this.state.data", this.state.dataTemp)
        const model = this.state.dataTemp.find(d=>d.id === id)
        if(model) this.setState({ ...model, modal: true, action: 'update', currentId: id, popLoad: false })
    }
    handleDeleteOpen(id){
        if(id){ this.setState({ modalDelete: true, currentId: id }) }
        else{ toast.error("Une erreur inattendue s'est produite.") }
    }

    handleSubmitSave(e){
        e.preventDefault();
        this.setState({popLoad: true})
        const { montant, date, motif } = this.state
        const data = { montant, date, motif }

        this.props.controller.save(data)
        .then(res=>{
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ ...initialState, load: true })
                toast.success(res.message)
                this.componentDidMount()
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({popLoad: false}) }
        })
        .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({popLoad: false}) })
    }

    handleSubmitUpdate(e){
        e.preventDefault();
        this.setState({popLoad: true})
        const { currentId, montant, date, motif } = this.state
        const data = {  id: currentId.toString(), montant, date, motif }
        if(currentId){
            this.props.controller.save(data)
            .then(res=>{
                if(!res.error && typeof(res.pror) === 'undefined'){
                    this.setState({ ...initialState, load: true, popLoad: false })
                    toast.success(res.message)
                    this.componentDidMount()
                }else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({popLoad: false}) }
            })
            .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({popLoad: false}) })
        }
    }
    
    handleDelete(){
        this.setState({popLoad: true})
        const { currentId } = this.state
        if(currentId){
            this.props.controller.remove(currentId)
            .then(res=>{
                if(!res.error && typeof(res.pror) === 'undefined'){
                    this.setState({ ...initialState, load: true, popLoad: false })
                    toast.success(res.message)
                    this.componentDidMount()
                }
                else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState, popLoad: false  }) }
            })
            .catch(()=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState, popLoad: false  }) })
        }
    }

    handlePrintAll(){
        this.setState({popLoad: true})
        this.props.controller.printAll()
        .then(res=>{
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ ...initialState })
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', res.filename)
                document.body.appendChild(link);
                link.click();
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState }) }
        })
        .catch(()=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState }) })
    }

    componentDidMount(){ 
        const currentYear = new Date().getFullYear()
        this.fetch(currentYear)
    }
    fetch =(year)=>{
        this.setState({ btnLoad: true, currentYear: year })
        this.props.controller.ByYear(`${year}`)
        .then(data=>{ 
            const grp = this.groupBy(data, 'month')
            if(!data.error && typeof(data.pror) === 'undefined'){
                this.setState({ data: grp, dataTemp: data, load: false, btnLoad: false }) 
            } else{ toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite."); this.setState({ load: false, btnLoad: false }) }
        })
        .catch(()=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ load: false, btnLoad: false }) })
    }

    groupBy = (array, key)=> {
        return array.reduce((rv, x)=> {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
    }

    fomateName = (str) =>{
        const st = str.split('-')
        const months = ['-', 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
        const month = months[st[0]]
        return month ? `${month}  ${st[1]}` : `-  ${st[1]}`
    }

    render(){
        const { pageName, devise } = this.props
        const { data, modal, modalDelete, montant, date, motif, action, load, popLoad, btnLoad, currentYear } = this.state
        
        return(
            <>
                <Head name={pageName}  />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <ToastContainer />
                            <div className="col-12">
                                <div className="card">
                                    <div className="nx-card-header">
                                        <h3 className="nx-card-title">La liste des charges fixes</h3>
                                        <div className="btn-group btn-group-sm" role="group" aria-label="" style={{float: 'right'}}>
                                            <button type="button" className="btn btn-success" onClick={()=>this.setState({ modal: true, action: 'save'  })}>
                                                Ajouter
                                            </button>
                                        </div>
                                    </div>
                                    <div className='nx-collapse-actions-container'>
                                        <div className='nx-collapse-input-container'>
                                            <label>Filter par année</label>
                                            <select className='form-control form-control-sm' value={currentYear} onChange={(e)=>this.setState({ currentYear: e.target.value })}>
                                                <option value='2021'>2021</option>
                                                <option value='2022'>2022</option>
                                                <option value='2023'>2023</option>
                                                <option value='2024'>2024</option>
                                            </select>
                                        </div>
                                        <button ctype="button" disabled={btnLoad} onClick={()=>this.fetch(currentYear)} className='btn btn-success nx-collapse-actions-btn'>
                                            {!btnLoad && 'Filter'}
                                            {btnLoad && <Spinner color='#fff' size={16} weight='1px' />}
                                        </button>
                                    </div>

                                    <div className="card-body">
                                        {load && <Spinner />}
                                        {Object.keys(data).map((month,index)=>{
                                            return(
                                                <Accordion title={this.fomateName(month)} key={index}>
                                                    <table style={{marginBottom: 0}} className="table table-bordered table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>ID</th>
                                                                <th>Montant</th>
                                                                <th>Motif</th>
                                                                <th>Date</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data[month].length > 0 &&
                                                            data[month].map((d,i)=>{
                                                                return(
                                                                    <tr key={i}>
                                                                        <td>{`CF_${d.id}`}</td>
                                                                        <td>{`${formatNumber(d.montant)} ${devise}`}</td>
                                                                        <td>{d.motif}</td>
                                                                        <td>{formatDate(d.date)}</td>
                                                                        <td>
                                                                            <button className="btn btn-link" onClick={()=>this.handleEditOpen(d.id)}>
                                                                                <i className="fas fa-pencil-alt"></i>
                                                                            </button>
                                                                            <button className="btn btn-link" onClick={()=>this.handleDeleteOpen(d.id)}>
                                                                                <i className="fa fa-trash"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })

                                                            }
                                                        </tbody>
                                                    </table>
                                                    {typeof(data[month].length) === 'undefined' && !load &&
                                                    <span className="breadcrumb-item active" style={{display: 'block', textAlign: 'center', marginTop: 8}}>
                                                        {`Aucune information trouvée !`}
                                                    </span>}
                                                </Accordion>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <ReactModal isOpen={modal} ariaHideApp={false}>
                                    <h5>{action === 'save' ? "Ajout d'une nouvelle charge fixe" : "Modification de la charge fixe"}</h5>
                                    <form method="POST" onSubmit={action === 'save' ? this.handleSubmitSave : this.handleSubmitUpdate }>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Montant *</label>
                                                    <input name="montant" value={montant}  onChange={(e)=>this.handleChange(e, 'montant')} type="number" className="form-control form-control-sm" placeholder="Montant *" required />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Date *</label>
                                                    <input name="date" value={date}  onChange={(e)=>this.handleChange(e, 'date')} type="date" className="form-control form-control-sm" placeholder="Date *" required />
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>Motif *</label>
                                                    <input name="motif" value={motif}  onChange={(e)=>this.handleChange(e, 'motif')} type="text" className="form-control form-control-sm" placeholder="Motif *" required />
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{textAlign: 'end' }}>
                                            <button onClick={()=>this.setState({ ...initialState })} type="button" className="btn btn-secondary">Annuler</button>
                                            <button type="submit" style={{marginLeft: 8}} className="btn btn-primary">{action === 'save' ? 'Enregistrer' : 'Modifier'}</button>
                                        </div>
                                    </form>
                                </ReactModal>

                                <ReactModal isOpen={modalDelete} ariaHideApp={false}>
                                    <h5>Suppresion de la charge fixe</h5>
                                   
                                    <span className="breadcrumb-item active">
                                        {`Etes-vous sûr de vouloir supprimer cette charge fixe, cette action est irreversible et entrainera la suppression de la charge fixe.`}
                                    </span>

                                    <div style={{textAlign: 'end', marginTop: 16 }}>
                                        <button onClick={()=>this.setState({ ...initialState })} type="button" className="btn btn-secondary">Annuler</button>
                                        <button onClick={()=>this.handleDelete()} style={{marginLeft: 8}} className="btn btn-primary">Supprimer</button>
                                    </div>
                                </ReactModal>

                                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                                    <h5>Traitement ...</h5>
                                   
                                    <Spinner />
                                    <span className="breadcrumb-item active" style={{display: 'block', textAlign: 'center'}}>
                                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                                    </span>

                                </ReactModal>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
