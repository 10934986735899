import React from 'react'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import Head from '../../components/Head'
import Tab from '../../components/Tab'
import ReactModal from 'react-modal'
import Spinner from '../../components/Spinner'
import Table from '../../components/Table'
import SearchInput from '../../components/Search'

const initialState = {
    nom: '',
    telephone: '',
    email: '',
    adresse: '',
    societe: '',
    action: '',
    currentId: '',
    popLoad: false,
    modal: false,
    modalDelete: false
}

const columns = [
    // { name: '_id', header: 'ID' },
    { name: 'nom', header: 'Nom Complet' },
    { name: 'telephone', header: 'Téléphone' },
    { name: 'email', header: 'Email' },
    { name: 'societe', header: 'Société' },
    { name: 'adresse', header: 'Adresse' }
]

export default class Clients extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            data: [],
            tempData: [],
            search: '',
            load: true,
            ...initialState,
        }
        this.handleSubmitSave = this.handleSubmitSave.bind(this)
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this)
    }

    handleChange(e, name){ this.setState({ [name]: e.target.value }) }
    handleEditOpen(id){
        const model = this.state.data.find(d=>d.id === id)
        if(model) this.setState({ ...model, modal: true, action: 'update', currentId: id, popLoad: false })
    }
    handleDeleteOpen(id){
        if(id){ this.setState({ modalDelete: true, currentId: id }) }
        else{ toast.error("Une erreur inattendue s'est produite.") }
    }

    handleSubmitSave(e){
        e.preventDefault();
        this.setState({popLoad: true})
        const { nom, telephone, email, adresse, societe  } = this.state
        const data = { nom, telephone, email, adresse, societe }

        this.props.controller.save(data)
        .then(res=>{
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ ...initialState, load: true })
                toast.success(res.message)
                this.componentDidMount()
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({popLoad: false}) }
        })
        .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({popLoad: false}) })
    }

    handleSubmitUpdate(e){
        e.preventDefault();
        this.setState({popLoad: true})
        const { currentId, nom, telephone, email, adresse, societe  } = this.state
        const data = {  id: currentId.toString(), nom, telephone, email, adresse, societe }
        if(currentId){
            this.props.controller.save(data)
            .then(res=>{
                if(!res.error && typeof(res.pror) === 'undefined'){
                    this.setState({ ...initialState, load: true, popLoad: false })
                    toast.success(res.message)
                    this.componentDidMount()
                }else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({popLoad: false}) }
            })
            .catch(()=>{ toast.error("Une erreur inattendue s'est produite."); this.setState({popLoad: false}) })
        }
    }
    
    handleDelete(){
        this.setState({popLoad: true})
        const { currentId } = this.state
        if(currentId){
            this.props.controller.remove(currentId)
            .then(res=>{
                if(!res.error && typeof(res.pror) === 'undefined'){
                    this.setState({ ...initialState, load: true, popLoad: false })
                    toast.success(res.message)
                    this.componentDidMount()
                }
                else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState, popLoad: false  }) }
            })
            .catch(()=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState, popLoad: false  }) })
        }
    }

    handlePrintAll(){
        this.setState({popLoad: true})
        this.props.controller.printAll()
        .then(res=>{
            if(!res.error && typeof(res.pror) === 'undefined'){
                this.setState({ ...initialState })
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', res.filename)
                document.body.appendChild(link);
                link.click();
            }
            else{ toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState }) }
        })
        .catch(()=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState }) })
    }

    handleTableSearch = value =>{
        const data = this.state.tempData.filter(td => {
            return (
                td?.nom.toLowerCase().includes(value.toLowerCase()) ||
                td?.telephone.toLowerCase().includes(value.toLowerCase()) ||
                td?.email.toLowerCase().includes(value.toLowerCase()) ||
                td?.societe.toLowerCase().includes(value.toLowerCase()) ||
                td?.adresse.toLowerCase().includes(value.toLowerCase())
            )
        })
        this.setState({ data, search: value })
    }

    componentDidMount(){
        this.props.controller.fetch()
        .then(data=>{ 
            if(!data.error && typeof(data.pror) === 'undefined'){
                this.setState({ data, tempData: data, load: false }) 
            } else{ toast.error(data.message ? data.message : data.pror ? data.pror : "Une erreur inattendue s'est produite."); this.setState({ load: false }) }
        })
        .catch(()=> { toast.error("Une erreur inattendue s'est produite."); this.setState({ load: false }) })
    }

    render(){
        const { pageName, history } = this.props
        const { data, modal, modalDelete, nom, telephone, email, adresse, societe, action, load, popLoad } = this.state

        console.log("ssss", this.props)
        
        return(
            <>
                <Head name={pageName}  />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12"><Tab name="clients" /></div>
                            <ToastContainer />
                            <div className="col-12">
                                <div className="card">
                                    <div className="nx-card-header">
                                        <h3 className="nx-card-title">La liste des clients</h3>
                                        <div className="btn-group btn-group-sm" role="group" aria-label="" style={{float: 'right'}}>
                                            <button type="button" className="btn btn-success" onClick={()=>this.setState({ modal: true, action: 'save'  })}>
                                                Ajouter
                                            </button>
                                        </div>
                                    </div>

                                    <div className="nx-card-body card-body">
                                        <SearchInput value={this.state.search} onChange={(val)=>this.handleTableSearch(val)} />
                                        <Table columns={columns} data={data} load={load} printable={false}
                                            onView={(id) => history.push(`clients/${id}`)}
                                            // onPrint={(id) => this.handlePrintOne(id)}
                                            onDelete={(id) => this.handleDeleteOpen(id)}
                                            onEdit={(id) => this.handleEditOpen(id)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <ReactModal isOpen={modal} ariaHideApp={false}>
                                    <h5>{action === 'save' ? "Ajout d'un nouveau client" : "Modification du client"}</h5>
                                    <form method="POST" onSubmit={action === 'save' ? this.handleSubmitSave : this.handleSubmitUpdate }>
                                        <div className="row">
                                            <div className="col-sm-12 col-lg-6">
                                                <div className="form-group">
                                                    <label>Nom Complet *</label>
                                                    <input name="nom" value={nom}  onChange={(e)=>this.handleChange(e, 'nom')} type="text" className="form-control form-control-sm" placeholder="Nom complet *" required />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-lg-6">
                                                <div className="form-group">
                                                    <label>Société</label>
                                                    <input name="societe" value={societe}  onChange={(e)=>this.handleChange(e, 'societe')} type="text" className="form-control form-control-sm" placeholder="Société" />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-lg-6">
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <input name="email" value={email}  onChange={(e)=>this.handleChange(e, 'email')} type="email" className="form-control form-control-sm" placeholder="Email" />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-lg-6">
                                                <div className="form-group">
                                                    <label>Téléphone</label>
                                                    <input name="telephone" value={telephone}  onChange={(e)=>this.handleChange(e, 'telephone')} type="text" className="form-control form-control-sm" placeholder="Téléphone" />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label>Adresse</label>
                                                    <input name="adresse" value={adresse}  onChange={(e)=>this.handleChange(e, 'adresse')} type="text" className="form-control form-control-sm" placeholder="Adresse" />
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{textAlign: 'end' }}>
                                            <button onClick={()=>this.setState({ ...initialState })} type="button" className="btn btn-secondary">Annuler</button>
                                            <button type="submit" style={{marginLeft: 8}} className="btn btn-primary">{action === 'save' ? 'Enregistrer' : 'Modifier'}</button>
                                        </div>
                                    </form>
                                </ReactModal>

                                <ReactModal isOpen={modalDelete} ariaHideApp={false}>
                                    <h5>Suppresion du client</h5>
                                   
                                    <span className="breadcrumb-item active">
                                        {`Etes-vous sûr de vouloir supprimer ce client, cette action est irreversible.`}
                                    </span>

                                    <div style={{textAlign: 'end', marginTop: 16 }}>
                                        <button onClick={()=>this.setState({ ...initialState })} type="button" className="btn btn-secondary">Annuler</button>
                                        <button onClick={()=>this.handleDelete()} style={{marginLeft: 8}} className="btn btn-primary">Supprimer</button>
                                    </div>
                                </ReactModal>

                                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                                    <h5>Traitement ...</h5>
                                   
                                    <Spinner />
                                    <span className="breadcrumb-item active" style={{display: 'block', textAlign: 'center'}}>
                                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                                    </span>

                                </ReactModal>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
