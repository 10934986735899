import React from 'react'
import ReactModal from 'react-modal'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import Head from '../../components/Head'
import Spinner from '../../components/Spinner'
import { formatDate, formatNumber } from '../../lib/functions'

const initialState = {
    article_id: '',
    // for edit
    editArticleLibelle: '',
    editArticleId: '',
    editFournisseurLibelle: '',
    editFournisseurId: '',
    //===========
    reference: '',
    designation: '',
    pachat: '',
    pvente: '',
    expire: '',
    magasinId: '',
    magasinName: '',
    quantite: '',
    action: '',
    currentId: '',
    popLoad: false,
    modal: false,
    modalDelete: false,
}

const initialStateFournisseur = {
    nomFournisseur: '',
    telephoneFournisseur: '',
    emailFournisseur: '',
    adresseFournisseur: '',
    societeFournisseur: '',
    modalFournisseur: false,
}

const paragraphe = { marginLeft: 8, background: '#fff', zIndex: 1, position: 'relative', display: 'inline-block', padding: '0px 8px', fontSize: 11 }
const row = { margin: 0, border: '1px solid #ddd', paddingTop: 24, borderRadius: 8, top: -24, position: 'relative' }

export default class NewAchat extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            articleOptions: [],
            fournisseurOptions: [],
            magasinOptions: [],
            fournisseurSelected: {},
            fournisseur_id: '0',
            date: '',
            charge: 0,
            avance: 0,
            motif: '',
            total: 0,
            tempTotal: 0,
            ntva: 18,
            tva: false,
            editTva: false,
            frs: false,
            load: true,
            ...initialState,
            ...initialStateFournisseur
        }
        this.handleSubmitSave = this.handleSubmitSave.bind(this)
        this.handleSubmitSaveFournisseur = this.handleSubmitSaveFournisseur.bind(this)
        this.handleSubmitAddElement = this.handleSubmitAddElement.bind(this)
    }

    calculMontant(data) {
        let sum = 0
        for (let i = 0; i < data.length; i++) { sum = sum + (data[i]['pachat'] * data[i]['quantite']) }
        return sum
    }

    handleChange(e, name) {
        switch (name) {
            case 'article_id':
                const article = this.state.articleOptions.find(a => a.id == e.target.value)
                if (typeof (article) !== 'undefined')
                    this.setState({ [name]: e.target.value, unite: article.unite, designation: article.designation })
                break;
            case 'fournisseur_id':
                const fournisseur = this.state.fournisseurOptions.find(a => a.id == e.target.value)
                if (typeof (fournisseur) !== 'undefined') { this.setState({ [name]: e.target.value, fournisseurSelected: fournisseur, frs: true }) }
                break;
            case 'charge':
                const total = this.state.tva ? this.state.tempTotal + (this.state.tempTotal * (this.state.ntva / 100)) : this.state.tempTotal
                const totaux =
                    parseFloat(total) +
                    parseFloat(e.target.value === '' ? '0' : e.target.value)

                this.setState({ [name]: e.target.value, total: totaux })
                break;
            case 'magasinId':
                const magasin = this.state.magasinOptions.find(a => a.id == e.target.value)
                if (typeof (magasin) !== 'undefined')
                    this.setState({ magasinId: e.target.value, magasinName: magasin.nom })
                break;
            default:
                this.setState({ [name]: e.target.value })
                break;
        }
        // if (name === 'article_id') {
        //     const article = this.state.articleOptions.find(a => a.id == e.target.value)
        //     if (typeof (article) !== 'undefined') {
        //         this.setState({ [name]: e.target.value, unite: article.unite, designation: article.designation })
        //     }
        // } else {
        //     if (name === 'fournisseur_id') {
        //         const fournisseur = this.state.fournisseurOptions.find(a => a.id == e.target.value)
        //         if (typeof (fournisseur) !== 'undefined') { this.setState({ [name]: e.target.value, fournisseurSelected: fournisseur, frs: true }) }
        //     } else {
        //         if (name === 'charge') {
        //             const total = this.state.tva ? this.state.tempTotal + (this.state.tempTotal * (this.state.ntva / 100)) : this.state.tempTotal
        //             const totaux =
        //                 parseFloat(total) +
        //                 parseFloat(e.target.value === '' ? '0' : e.target.value)

        //             this.setState({ [name]: e.target.value, total: totaux })
        //         } else {
        //             this.setState({ [name]: e.target.value })
        //         }
        //     }
        // }
    }

    changeTva() {
        const { tempTotal, ntva, tva } = this.state
        if (tva) {
            // const t = total + (total*0.18)
            const t = tempTotal + (tempTotal * (ntva / 100))
            const totaux = t + parseFloat(this.state.charge === '' ? '0' : this.state.charge)
            this.setState({ total: totaux, tva, editTva: false })
        } else {
            const totaux = tempTotal + parseFloat(this.state.charge === '' ? '0' : this.state.charge)
            this.setState({ total: totaux, tva, editTva: false })
        }
    }

    handleSetTva(tva) {
        const { tempTotal, ntva } = this.state
        if (tva) {
            console.log(tempTotal, ntva)
            // const t = total + (total*0.18)
            const t = tempTotal + (tempTotal * (ntva / 100))
            const totaux = t + parseFloat(this.state.charge === '' ? '0' : this.state.charge)
            this.setState({ total: totaux, tva })
        } else {
            const totaux = tempTotal + parseFloat(this.state.charge === '' ? '0' : this.state.charge)
            this.setState({ total: totaux, tva })
        }
    }

    handleSubmitAddElement(e) {
        e.preventDefault();
        const { type } = this.props
        const { article_id, reference, designation, unite, pachat, pvente, quantite, data, tva, ntva, magasinId, magasinName, expire } = this.state
        const article = { id: data.length + 1, article_id, reference, unite, designation, pachat, pvente, quantite, expire, magasin_id: magasinId, magasinName, type }
        const datas = [...data, article]
        const t = this.calculMontant(datas)
        const total = tva ? t + (t * (ntva / 100)) : t

        const totaux = total + parseFloat(this.state.charge === '' ? '0' : this.state.charge)
        this.setState({ data: datas, total: totaux, tempTotal: t, ...initialState })
    }

    handleSubmitSaveFournisseur(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { nomFournisseur, telephoneFournisseur, emailFournisseur, adresseFournisseur, societeFournisseur } = this.state
        const data = { nom: nomFournisseur, telephone: telephoneFournisseur, email: emailFournisseur, adresse: adresseFournisseur, societe: societeFournisseur }

        this.props.fournisseurController.save(data)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    data.id = res.id
                    this.setState({ ...initialStateFournisseur, fournisseurOptions: [data, ...this.state.fournisseurOptions], popLoad: false })
                    toast.success(res.message)
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
    }

    handleSubmitSave(e) {
        e.preventDefault();
        this.setState({ popLoad: true })
        const { type } = this.props // Achat ou Commande
        const { fournisseur_id, total, date, data, tva, ntva, charge, motif, avance } = this.state
        if (data.length > 0) {
            if (Number(total) >= Number(avance)) {
                const datas = {
                    commande: {
                        fournisseur_id,
                        montant: total,
                        date,
                        devis: 0,
                        type,
                        tva: tva ? ntva : '0',
                        charge,
                        motif: motif ? motif : 'Autre charge',
                        etat: type === 'Achat' ? 'LIVREE' : 'EN COURS'
                    },
                    details: data, montantPaye: avance
                }

                this.props.controller.save(datas)
                    .then(res => {
                        if (!res.error && typeof (res.pror) === 'undefined') {
                            this.setState({
                                ...initialState,
                                fournisseur_id: '',
                                data: [],
                                total: 0,
                                tempTotal: 0,
                                charge: 0,
                                avance: 0,
                                motif: '',
                                date: '',
                                fournisseurSelected: {},
                                frs: false,
                                tva: false
                            })
                            toast.success(res.message)
                        }
                        else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) }
                    })
                    .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ popLoad: false }) })
            } else { toast.error("Le montant avancé n'est pas être superieur a celui du côut d'acquisition."); this.setState({ popLoad: false }) }
        } else { toast.error("La liste de produits à ne doit pas être vide."); this.setState({ popLoad: false }) }
    }

    handlePrintOne(id) {
        this.setState({ popLoad: true })
        this.props.controller.print(id)
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ ...initialState })
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', res.filename)
                    document.body.appendChild(link);
                    link.click();
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState }) }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState }) })
    }

    handlePrintAll() {
        this.setState({ popLoad: true })
        this.props.controller.printAll()
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ ...initialState })
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', res.filename)
                    document.body.appendChild(link);
                    link.click();
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite."); this.setState({ ...initialState }) }
            })
            .catch(() => { toast.error("Une erreur inattendue s'est produite."); this.setState({ ...initialState }) })
    }

    getArticleList() {
        this.props.articleController.fetch()
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ articleOptions: res })
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
            })
            .catch(() => toast.error("Une erreur inattendue s'est produite."))
    }

    getFournisseurList() {
        this.props.fournisseurController.fetch()
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ fournisseurOptions: res })
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
            })
            .catch(() => toast.error("Une erreur inattendue s'est produite."))
    }

    fetchStoresList() {
        this.props.magasinController.fetch()
            .then(res => {
                if (!res.error && typeof (res.pror) === 'undefined') {
                    this.setState({ magasinOptions: res })
                }
                else { toast.error(res.message ? res.message : res.pror ? res.pror : "Une erreur inattendue s'est produite.") }
            })
            .catch(() => toast.error("Une erreur inattendue s'est produite."))
    }

    componentDidMount() {
        this.getArticleList()
        this.getFournisseurList()
        this.fetchStoresList()
    }

    render() {
        const { pageName, type, devise } = this.props
        const { data, articleOptions, fournisseurOptions, fournisseurSelected, frs, modal, action,
            reference, unite, pachat, pvente, quantite, total, tva, editTva, ntva, charge, motif, avance,
            nomFournisseur, telephoneFournisseur, emailFournisseur, adresseFournisseur, societeFournisseur, modalFournisseur,
            magasinOptions, expire, load, popLoad } = this.state
        return (
            <>
                <Head name={pageName} />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <ToastContainer />
                            <div className="col-12">
                                <div className="card nx-detail-card">
                                    <div className="nx-card-header">
                                        <button className="btn btn-link back-btn" onClick={() => this.props.history.goBack()}>
                                            <i className="fa fa-arrow-left"></i>
                                        </button>
                                        <h3 className="nx-card-title">{type === 'Achat' ?' Une nouvelle entrée dans le stock' : 'Une nouvelle commande'}</h3>
                                    </div>
                                    <div className="card-body">
                                        <form method="POST" onSubmit={this.handleSubmitSave}>

                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <p style={paragraphe}>Information sur le fournisseur et la date de la commande</p>
                                                    <div className="row" style={row}>
                                                        <div className="col-sm-12 col-md-6" style={{ display: 'flex' }}>
                                                            <div style={{ flex: 1 }}>
                                                                <label className='nx-input-label'>Séléctionner le fournisseur </label>
                                                                <select className="form-control form-control-sm" onChange={(e) => this.handleChange(e, 'fournisseur_id')}  >
                                                                    <option value="0">--Fournisseur--</option>
                                                                    {fournisseurOptions.length > 0 &&
                                                                        fournisseurOptions.map((a, i) => <option value={a.id} key={i}>{`${a.nom} ; Tel: ${a.telephone}`}</option>)
                                                                    }
                                                                </select>
                                                            </div>
                                                            <div>
                                                                <label>    &nbsp; </label>
                                                                <button onClick={() => this.setState({ modalFournisseur: true })} style={{ display: 'block', marginLeft: 4, height: 31, padding: '0 6px' }} type="button" className="btn btn-primary">
                                                                    <i className="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-md-6">
                                                            <div className="form-group">
                                                                <label className='nx-input-label'>Date d'entrée en stock *</label>
                                                                <input onChange={(e) => this.handleChange(e, 'date')} type="date" className="form-control form-control-sm" placeholder="Date" required />
                                                            </div>
                                                        </div>
                                                        {frs && <>
                                                            <div className="col-sm-3">
                                                                <h6 style={{ fontSize: 12, marginBottom: 0 }}>Société</h6>
                                                                <p style={{ fontSize: 10 }}>{fournisseurSelected.societe}</p>
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <h6 style={{ fontSize: 12, marginBottom: 0 }}>Téléphone</h6>
                                                                <p style={{ fontSize: 10 }}>{fournisseurSelected.telephone}</p>
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <h6 style={{ fontSize: 12, marginBottom: 0 }}>Email</h6>
                                                                <p style={{ fontSize: 10 }}>{fournisseurSelected.email}</p>
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <h6 style={{ fontSize: 12, marginBottom: 0 }}>Adresse</h6>
                                                                <p style={{ fontSize: 10 }}>{fournisseurSelected.adresse}</p>
                                                            </div>
                                                        </>}
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <p style={paragraphe}>Information sur le montant de la commande </p>
                                                    <div className="row" style={{ ...row, marginRight: 8 }}>
                                                        <div className="col-12">
                                                            <label className='nx-input-label'>Coût d'acquisition : <strong>{`${total} ${devise}`}</strong></label>
                                                        </div>
                                                        <div className="col-12">
                                                            <label className='nx-input-label'>Reste à payer : <strong>{`${total - avance} ${devise}`}</strong></label>
                                                        </div>
                                                        <div className="col-12" style={{ marginBottom: 16 }}>
                                                            {!editTva &&
                                                                <>
                                                                    <label className="form-check-label nx-input-label" style={{ marginRight: 8 }} >{`Appliquer la TVA (${ntva}%) `}</label>
                                                                    <button onClick={() => this.setState({ editTva: true })} style={{ marginRight: 38, padding: '0 4px', fontSize: 10 }} type="button" className="btn btn-primary">
                                                                        <i className="fa fa-pen"></i>
                                                                    </button>
                                                                    <input className="form-check-input" type="checkbox" onChange={(e) => this.handleSetTva(e.target.checked)} checked={tva} />
                                                                </>}
                                                            {editTva &&
                                                                <div className="form-group" style={{ display: 'flex' }}>
                                                                    <input onChange={(e) => this.handleChange(e, 'ntva')} min="0" value={ntva} type="number" className="form-control form-control-sm" placeholder="TVA" />
                                                                    <button onClick={() => this.changeTva()} style={{ height: 31, marginLeft: 4 }} type="button" className="btn btn-primary">Valider</button>
                                                                </div>}
                                                        </div>
                                                        <div className="col-sm-12" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div className="form-group">
                                                                <label className='nx-input-label' style={{ marginRight: 12 }}>Saisir montant avancé (Avance)</label>
                                                                <input onChange={(e) => this.handleChange(e, 'avance')} value={avance} type="number" className="form-control form-control-sm" placeholder="Avance" required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-6">
                                                    <p style={paragraphe}>Information sur les charges </p>
                                                    <div className="row" style={row}>
                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <label className='nx-input-label'>Autre charge</label>
                                                                <input onChange={(e) => this.handleChange(e, 'charge')} value={charge === 0 ? '' : charge} type="number" className="form-control form-control-sm" placeholder="Autre charge" />
                                                            </div>
                                                        </div>
                                                        <div className="col-12" style={{ marginBottom: 16 }}>
                                                            <div className="form-group">
                                                                <label className='nx-input-label'>Motif Autre charge</label>
                                                                <input onChange={(e) => this.handleChange(e, 'motif')} value={motif} type="text" className="form-control form-control-sm" placeholder="Motif autre charge" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <p style={{ ...paragraphe, marginLeft: 8 }}>Information sur la commande et les produits </p>
                                            <div className="row" style={{ ...row, marginLeft: 0, marginRight: 0 }}>
                                                <div className="col-12 table-responsive">
                                                    <table id="example1" className="table table-bordered table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Référence</th>
                                                                <th>Désignation</th>
                                                                <th>Expire le</th>
                                                                <th>P.U</th>
                                                                <th>Quantité</th>
                                                                <th>Montant</th>
                                                                <th>P.U vte</th>
                                                                <th>Magasin</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.length > 0 &&
                                                                data.map((d, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td>{i + 1}</td>
                                                                            <td>{d.reference}</td>
                                                                            <td>{d.designation}</td>
                                                                            <td>{formatDate(d.expire)}</td>
                                                                            <td>{`${formatNumber(d.pachat)} ${devise}`}</td>
                                                                            <td>{`${d.quantite} (${d.unite})`}</td>
                                                                            <td>{`${formatNumber(d.pachat * d.quantite)} ${devise}`}</td>
                                                                            <td>{`${formatNumber(d.pvente)} ${devise}`}</td>
                                                                            <td>{d.magasinName}</td>
                                                                            <td>
                                                                                <button className="btn btn-link" onClick={() => {
                                                                                    const datas = data.filter(f => f.id !== d.id)
                                                                                    const t = this.calculMontant(datas)
                                                                                    // const total = tva ? t+(t*0.18) : t
                                                                                    const total = tva ? t + (t * (ntva / 100)) : t
                                                                                    const totaux = total + parseFloat(this.state.charge === '' ? '0' : this.state.charge)

                                                                                    this.setState({ data: datas, total: totaux, tempTotal: t, ...initialState })
                                                                                }}>
                                                                                    <i className="fa fa-trash"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <button type="button" style={{ marginBottom: 12 }} className="btn btn-default" onClick={() => this.setState({ modal: true, action: 'save' })} >
                                                        Ajouter
                                                    </button>
                                                </div>
                                            </div>

                                            <div className='nx-page-actions'>
                                                <Link to="entrees" type="button" className="btn btn-secondary">Annuler</Link>
                                                <button type="submit" style={{ marginLeft: 8 }} className="btn btn-primary">Enregistrer</button>
                                                {/* <button type="submit" style={{ marginLeft: 8 }} className="btn btn-primary">Enregistrer & Imprimer</button> */}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <ReactModal isOpen={modal} ariaHideApp={false}>
                                    <h5>Une nouvelle entrée dans le stock</h5>
                                    <form method="POST" onSubmit={this.handleSubmitAddElement}>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-9">
                                                <div className="form-group">
                                                    <label className='nx-input-label'>Séléctionner un article *</label>
                                                    <select className="form-control form-control-sm" onChange={(e) => this.handleChange(e, 'article_id')} required >
                                                        <option value="">--Choisir--</option>
                                                        {articleOptions.length > 0 &&
                                                            articleOptions.map((a, i) => <option value={a.id} key={i}>{a.designation}</option>)
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-3">
                                                <div className="form-group">
                                                    <label className='nx-input-label'>Unité</label>
                                                    <p>{unite}</p>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group">
                                                    <label className='nx-input-label'>Référence *</label>
                                                    <input
                                                        value={reference}
                                                        onChange={(e) => this.handleChange(e, 'reference')}
                                                        className="form-control form-control-sm"
                                                        type="text"
                                                        placeholder="Référence"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group">
                                                    <label className='nx-input-label'>Quantité *</label>
                                                    <input
                                                        value={quantite}
                                                        onChange={(e) => this.handleChange(e, 'quantite')}
                                                        type="number"
                                                        min="1"
                                                        className="form-control form-control-sm"
                                                        placeholder="Quantité"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group">
                                                    <label className='nx-input-label'>Prix unitaire d'achat *</label>
                                                    <input
                                                        value={pachat}
                                                        onChange={(e) => this.handleChange(e, 'pachat')}
                                                        className="form-control form-control-sm"
                                                        placeholder="Prix unitaire d'achat"
                                                        type="number"
                                                        min="5"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group">
                                                    <label className='nx-input-label'>Prix unitaire previsionnel de vente</label>
                                                    <input
                                                        value={pvente}
                                                        onChange={(e) => this.handleChange(e, 'pvente')}
                                                        className="form-control form-control-sm"
                                                        placeholder="Prix unitaire de vente"
                                                        type="number"
                                                        min="0"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group">
                                                    <label className='nx-input-label'>Date de péremption *</label>
                                                    <input
                                                        value={expire}
                                                        onChange={(e) => this.handleChange(e, 'expire')}
                                                        className="form-control form-control-sm"
                                                        placeholder="Date de péremption"
                                                        type='date'
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="form-group">
                                                    <label className='nx-input-label'>Magasin de stockage</label>
                                                    <select className="form-control form-control-sm" onChange={(e) => this.handleChange(e, 'magasinId')} required >
                                                        <option value="0">--Choisir--</option>
                                                        {magasinOptions.length > 0 &&
                                                            magasinOptions.map((a, i) => <option value={a.id} key={i}>{a.nom}</option>)
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ textAlign: 'end' }}>
                                            <button onClick={() => this.setState({ ...initialState })} type="button" className="btn btn-secondary">Annuler</button>
                                            <button type="submit" style={{ marginLeft: 8 }} className="btn btn-primary">Ajouter</button>
                                        </div>
                                    </form>
                                </ReactModal>

                                <ReactModal isOpen={modalFournisseur} ariaHideApp={false}>
                                    <h5>Ajout d'un nouveau fournisseur</h5>
                                    <form method="POST" onSubmit={this.handleSubmitSaveFournisseur}>
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <input value={nomFournisseur} onChange={(e) => this.handleChange(e, 'nomFournisseur')} type="text" className="form-control form-control-sm" placeholder="Nom complet *" required />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <input value={societeFournisseur} onChange={(e) => this.handleChange(e, 'societeFournisseur')} type="text" className="form-control form-control-sm" placeholder="Société" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <input value={emailFournisseur} onChange={(e) => this.handleChange(e, 'emailFournisseur')} type="email" className="form-control form-control-sm" placeholder="Email" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <input value={telephoneFournisseur} onChange={(e) => this.handleChange(e, 'telephoneFournisseur')} type="text" className="form-control form-control-sm" placeholder="Téléphone" />
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <input value={adresseFournisseur} onChange={(e) => this.handleChange(e, 'adresseFournisseur')} type="text" className="form-control form-control-sm" placeholder="Adresse" />
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ textAlign: 'end' }}>
                                            <button onClick={() => this.setState({ ...initialStateFournisseur })} type="button" className="btn btn-secondary">Annuler</button>
                                            <button type="submit" style={{ marginLeft: 8 }} className="btn btn-primary">Enregistrer</button>
                                        </div>
                                    </form>
                                </ReactModal>

                                <ReactModal isOpen={popLoad} ariaHideApp={false}>
                                    <h5>Traitement ...</h5>
                                    <Spinner />
                                    <span className="breadcrumb-item active" style={{ display: 'block', textAlign: 'center' }}>
                                        {`Veuillez patienter, votre rêquete est en cours de traitement ...`}
                                    </span>
                                </ReactModal>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}